import React, { Component } from 'react';
import config from '../../config';

class CallbackIntuitPage extends Component {
	quickbookAuthUrl = () => {
		return `https://appcenter.intuit.com/app/connect/oauth2?response_type=code&client_id=${config.quickbookAuth.clientId}&redirect_uri=${encodeURIComponent(config.quickbookAuth.redirectUri)}&scope=${encodeURIComponent(config.quickbookAuth.scopes)}&state=123&prompt=login`;
	};
	componentDidMount() {
		window.location.href = this.quickbookAuthUrl();
	}
	render() {
		return (
			<div>Redirecting...</div>
		);
	}
}

// Use redux's compose to use multiple
// HOC(Higher Order Component) wrappers
export default CallbackIntuitPage;
