import { connect } from 'react-redux';
import { getActiveSubscription, cancelSubscription, getInvoices, resetMeta, resetPage } from '../../actions/BillingPage';
import { proceedCheckout } from '../../actions/PricingPage';
import BillingPage from './BillingPage';

const mapsStateToProps = state => ({
	billingPageProps: state.billingPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getActiveSubscription() {
		dispatch(getActiveSubscription());
	},
	cancelSubscription(options) {
		dispatch(cancelSubscription(options));
	},
	getInvoices(options) {
		dispatch(getInvoices(options));
	},
	proceedCheckout(options) {
		dispatch(proceedCheckout(options));
	},
	resetMeta() {
		dispatch(resetMeta());
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(BillingPage);
