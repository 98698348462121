export default {
	api: 'http://localhost:3000',
	appPath: '/',
	loginTimeout: 18000000,
	googleAuth: {
		clientId: '720220931198-53ifal8jg57nn9onql1ier3crgdo1igq.apps.googleusercontent.com'
	},
	xeroAuth: {
		clientId: 'DDBA0796238A492C8F32F78FDA211B0B',
		redirectUri: 'http://localhost:3020/callback/xero',
		scopes: 'openid profile email'
	},
	quickbookAuth: {
		clientId: 'ABto8CsGDKEY2YSWr2hlgGJiu5AJtuLoF7BMeGQSEN7c7Gwqz9',
		redirectUri: 'http://localhost:3020/callback/intuit',
		scopes: 'com.intuit.quickbooks.accounting openid profile email phone address',
		connectToQuickBooksRedirectUri: 'http://localhost:3020/callback/connect-to-quickbooks',
		disconnectFromQuickBooksRedirectUri: 'http://localhost:3020/callback/disconnect-from-quickbooks'
	}
};
