import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	loading: false,
	loadingSubmit: false,
	user: null
};

export default function resetPasswordPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_USER_BY_RESET_PASSWORD_TOKEN_REQUEST:
		case ActionTypes.GET_USER_BY_RESET_PASSWORD_TOKEN_ERROR:
		case ActionTypes.GET_USER_BY_RESET_PASSWORD_TOKEN_SUCCESS:
		case ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST:
		case ActionTypes.SUBMIT_RESET_PASSWORD_ERROR:
		case ActionTypes.SUBMIT_RESET_PASSWORD_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_RESET_PASSWORD_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_RESET_PASSWORD_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
