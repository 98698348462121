/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Typography, Button, Image, Card } from 'antd';
import UrlParse from 'url-parse';
import successPng from '../../images/success.png';

import Loading from '../../components/Loading';
import PixelHelper from '../../helpers/pixel';

const { Text } = Typography;

const EmailConfirmationPage = (props) => {

	// Props
	const {
		history,
		location,
		verifyEmail,
		emailConfirmPageProps,
		resetPage,
		resetMeta
	} = props;

	// Other Variables
	const { user, loadingVerify, meta } = emailConfirmPageProps;
	const { query } = UrlParse(location.search, true);
	const token = query.t;
	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		verifyEmail(token);

		return () => resetPage();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(meta){
			meta.code !== 200 && history.push(`/error?message=${meta.message}`);
			resetMeta();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);

	if(!loadingVerify && user){
		return (
			<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
				<Card
					style={{
						width: '773px',
						height: '464px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '50px',
						boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
				 }}
				 bordered
				>
					<div
						style={{
							height: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<div style={{ marginBottom: 20 }}>
							<Image
								width={200}
								src={successPng}
								preview={false}
							/>
						</div>
						<div>
							<Text style={{ fontWeight: 510, fontSize: '30px' }}>Email Confirmed!</Text>
						</div>
						<div>
							<Text style={{ fontWeight: 274, fontSize: '19px' }}>Your email </Text>
							<Text style={{ fontWeight: 274, fontSize: '19px', color: '#1E8C55' }}>{user?.email}</Text>
							<Text style={{ fontWeight: 274, fontSize: '19px' }}> was successfully verified</Text>
						</div>
						<Button
							type="primary"
							key="console"
							onClick={() => props.history.push('/')}
							style={{
								width: '100%',
								textAlign: 'center',
						 marginTop: 20,
						 alignContent: 'center',
						 height: 50
							}}
						>
						Continue
						</Button>
					</div>
				</Card>
			</div>
		);
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<Loading />
		</div>
	);
};

export default EmailConfirmationPage;
