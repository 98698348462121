import { connect } from 'react-redux';
import { createWhatsappAccountLink, resetPage, resetMeta } from '../../actions/ConnectWhatsappPage';
import ConnectWhatsappPage from './ConnectWhatsappPage';

const mapsStateToProps = state => ({
	connectWhatsappPageProps: state.connectWhatsappPageReducer
});

const mapsDispatchToProps = dispatch => ({
	createWhatsappAccountLink(options) {
		dispatch(createWhatsappAccountLink(options));
	},
	resetPage() {
		dispatch(resetPage());
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(ConnectWhatsappPage);
