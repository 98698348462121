import request from './request';

export default {
	async getExpenses(query) {
		const response = await request.get('/app/expenses', query);
		return response;
	},
	async getExpenseById(id) {
		const response = await request.get(`/app/expenses/${id}`);
		return response;
	},
	async createExpense(body) {
		const response = await request.post('/app/expenses', body);
		return response;
	},
	async updateExpenseById(id, body) {
		const response = await request.patch(`/app/expenses/${id}`, body);
		return response;
	},
	async exportExpenses(query) {
		const response = await request.get('/app/expenses/export/receipt', query);
		return response;
	},
	async getPaymentOptions() {
		const response = await request.get('/app/users/payments/options');
		return response;
	},
	async deleteExpense(id) {
		const response = await request.delete(`/app/expenses/${id}`);
		return response;
	}
};
