import React, { useState, useEffect, useRef } from 'react';
import _, { isEmpty } from 'lodash';
import { Row, Col, Typography, Input, Button, ConfigProvider, Checkbox, Divider, Space, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import UrlParse from 'url-parse';

import config from '../../config';

// Helpers
import AuthHelper from '../../helpers/auth';
import GeneralHelper from '../../helpers/general';
import LocalStorageHelper from '../../helpers/local_storage';

// Components
import Loading from '../../components/Loading';

// Assets
import nekoLogo from '../../images/logo.svg';
import nekoWhiteLogo from '../../images/logo-white.svg';
import googleLogo from '../../images/google-logo.svg';
import PixelHelper from '../../helpers/pixel';
import XeroButton from '../../components/XeroButton';
import QuickbookButton from '../../components/QuickbookButton';

const { Title, Text } = Typography;

const LoginPage = (props) => {
	// Refs
	const googleBtnRef = useRef(null);

	// States
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [resetPassClicked, setResetPassClicked] = useState(false);
	const [signUpClicked, setSignUpClicked] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [emailStatus, setEmailStatus] = useState('');
	const [disabled, setDisabled] = useState(true);
	const [checked, setCheked] = useState(false);
	const [modal, setModal] = useState(false);
	const [modalEmail, setModalEmail] = useState(null);
	const [modalIsValidEmail, setModalIsValidEmail] = useState(false);
	const [disablePasswordReset, setDisablePasswordReset] = useState(true);

	// Props
	const { login, loginWithGoogle, requestResetPasswordLink, location, history, resetPage, loginPageProps, resetMeta } = props;
	const { loading, loadingSubmit, loadingResetPassword, meta } = loginPageProps;
	const { code = 200, message } = meta || {};
	const { query } = UrlParse(location.search, true);

	// Functions
	const handleLogin = () => {
		login({ email, password, query });
		if (checked) {
			LocalStorageHelper.setEmail(email);
		}
	};

	const handleGoogleAuthCallback = response => {
		loginWithGoogle({ credential: response.credential, query });
	};

	const handleResetPasswordRequest = () => {
		requestResetPasswordLink(modalEmail);
	};

	const handleForgetPasswordClick = () => {
		setModal(true);
		setResetPassClicked(true);
		setTimeout(() => {
			setResetPassClicked(false);
		}, 100);
	};

	const handleSignUpClick = () => {
		setSignUpClicked(true);
		setTimeout(() => {
			setSignUpClicked(false);
		}, 100);
		history.push('/create');
	};

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	const handleEmailChange = e => {
		const email = e.target.value;
		setEmail(email);

		// Check if email is valid
		if (!GeneralHelper.isEmailValid(email)) {
			setEmailStatus('error');
			return;
		}

		setEmailStatus('');
	};

	const handleIsValidModalEmail = e => {
		const email = e.target.value;
		setModalEmail(email);

		// Check if email is valid
		if (!GeneralHelper.isEmailValid(email)) {
			setModalIsValidEmail(false);
			return;
		}

		setModalIsValidEmail(true);
	};

	const handleCheckboxChange = (e) => {
		setCheked(e.target.checked);
	};

	const handleGoogleAuth = () => {
		googleBtnRef.current.click();
	};

	const renderResetPassModal = () => {
		return (
			<Modal
				open={modal}
				centered
				footer={null}
				closable={false}
				onCancel={() => {
					setModal(false);
					resetMeta();
				}}
				width={564}
				bodyStyle={{ padding: '36px 30px' }}
			>
				<CloseOutlined
					onClick={() => {
						setModal(false);
						setModalEmail(null);
						setModalIsValidEmail('');
						resetMeta();
					}}
					style={{ position: 'absolute', top: 29, right: 54 }}
				/>
				<Text style={{ color: '#344E41', fontSize: 30, fontWeight: 590 }}>Reset your password</Text><br />
				<p style={{ fontSize: 16, marginBottom: 42 }}>
					Tell us the email address associated with your NekoExpense account,
					and we’ll send you an email with a link to reset your password.
				</p>
				<div style={{ marginBottom: 32 }}>
					<Space
						block
						direction="vertical"
						style={{ width: '100%' }}
					>
						<Text strong>Email Address</Text>
						<Input
							value={modalEmail}
							placeholder="Enter Email"
							status={modalIsValidEmail}
							disabled={loadingResetPassword}
							onChange={handleIsValidModalEmail}
							style={{ height: 48 }}
						/>
					</Space>
					{
						!modalIsValidEmail && !disablePasswordReset ?
							<Text
								type="danger"
								style={{ fontSize: 16 }}
							>
								Not a valid email
							</Text> :
							''
					}
					{
						code !== 200 && modal ?
							<Text
								type="danger"
								style={{ fontSize: 16 }}
							>
								{message !== 'Email or password is incorrect.' ? message : ''}
							</Text> :
							''
					}
				</div>
				<Button
					block
					type="primary"
					size="large"
					disabled={disablePasswordReset || loadingResetPassword || !modalIsValidEmail}
					onClick={handleResetPasswordRequest}
					style={{ height: 56 }}
				>
					{
						loadingResetPassword ?
							<Row justify="center">
								<Loading />
							</Row> :
							'Send'
					}
				</Button>
			</Modal>
		);
	};

	// Use Effects
	useEffect(() => {
		if (AuthHelper.isAuthenticated()) {
			history.push('/expenses');
		}

		PixelHelper.pageView(window.location.pathname);
		// Set email
		const savedEmail = LocalStorageHelper.getEmail();
		if (!_.isEmpty(savedEmail)) {
			setEmail(savedEmail);
		}

		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/platform.js';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			// Google Authentication
			if (window.google) {
				window.google.accounts.id.initialize({
					client_id: config.googleAuth.clientId,
					callback: handleGoogleAuthCallback
				});

				window.google.accounts.id.renderButton(googleBtnRef.current, {
					theme: 'outline',
					size: 'large',
					text: 'continue_with',
					logo_alignment: 'center'
				});
			}

			googleBtnRef.current = document.querySelector('#google-btn div[role=button]');
		};

		return () => {
			window.removeEventListener('resize', handleResize);
			resetPage();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (_.isEmpty(email) || isEmpty(password) || !GeneralHelper.isEmailValid(email)) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, password]);

	useEffect(() => {
		if (_.isEmpty(modalEmail)) {
			setDisablePasswordReset(true);
		} else {
			setDisablePasswordReset(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalEmail]);

	useEffect(() => {
		if (meta?.code === 200) {
			setModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);

	if (loading) {
		return (
			<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Loading />
			</div>
		);
	}

	return (
		<ConfigProvider
			theme={{
				token: {
					fontSize: 19,
					fontSizeHeading1: 30,
					colorTextHeading: '#344E41'
				},
				components: {
					Form: {
						labelFontSize: 19
					},
					Modal: {
						borderRadiusLG: 50
					}
				}
			}}
		>
			<Row>
				<Col
					span={windowWidth < 1340 ? 24 : 9}
					style={{ minHeight: '100vh' }}
				>
					<Row justify="center">
						<div
							style={{
								width: windowWidth < 510 ? '100%' : 456,
								padding: windowWidth < 510 ? '30px 20px 0px' : '78px 0px'
							}}
						>
							<div>
								<a
									href="https://nekoexpense.com"
									target="_self"
								>
									<img
										src={nekoLogo}
										alt="nekoexpense-logo"
										style={{ width: windowWidth < 370 ? '100%' : 341, marginBottom: windowWidth < 510 ? 30 : 30 }}
									/>
								</a>
								<Title
									level={1}
									style={{ margin: 0, marginBottom: 3 }}
								>
									Login
								</Title>
								<Text style={{ fontSize: 16 }}>
								By logging in, I agree to the NekoExpense
									<a
										href={process.env.NODE_ENV === 'production' ? 'https://www.nekoexpense.com/terms' : 'http://localhost:8500/terms'}
										target="_blank"
										rel="noopener noreferrer"
									>&nbsp; Subscriber Agreement  &nbsp;</a>
								and the NekoExpense &nbsp;
									<a
										href={process.env.NODE_ENV === 'production' ? 'https://www.nekoexpense.com/privacy' : 'http://localhost:8500/privacy'}
										target="_blank"
										rel="noopener noreferrer"
									>Privacy Policy</a>
								</Text>
							</div>
							<div style={{ marginTop: 20 }}>
								<div
									ref={googleBtnRef}
									id="google-btn"
								></div>
								<Button
									block
									size="large"
									onClick={handleGoogleAuth}
									style={{ height: 56 }}
								>
									<img
										src={googleLogo}
										alt="nekoexpense-logo"
										style={{ width: 30, marginRight: 20 }}
									/>
									{windowWidth < 325 ? '' : 'Continue with Google'}
								</Button>
							</div>

							<div style={{ marginTop: 20 }}>
								<XeroButton />
							</div>

							<div style={{ marginTop: 20 }}>
								<QuickbookButton />
							</div>

							<Divider style={{ margin: '25px 0px', fontSize: 16, borderColor: '#d9d9d9'  }}>or</Divider>
							<div>
								<Space
									block
									direction="vertical"
									style={{ width: '100%' }}
								>
									<Text strong>Email Address</Text>
									<Input
										value={email}
										placeholder="Enter Email"
										status={emailStatus}
										disabled={loadingSubmit}
										onChange={handleEmailChange}
										style={{ height: 48 }}
									/>
								</Space>
								{
									emailStatus === 'error' ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											Not a valid email
										</Text> :
										''
								}
								{
									code !== 200 && !modal ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											{message}
										</Text> :
										''
								}
							</div>
							<div style={{ marginTop: 23 }}>
								<Space
									block
									direction="vertical"
									style={{ width: '100%' }}
								>
									<Row justify="space-between">
										<Text strong>Password</Text>
										<Text
											onClick={handleForgetPasswordClick}
											style={{
												fontSize: 18,
												color: resetPassClicked ? '#FFCC90' : '#FF8A00',
												cursor: 'pointer'
											}}
										>
											Forgot your password?
										</Text>
									</Row>
									<Input.Password
										placeholder="Enter Password"
										disabled={loadingSubmit}
										onChange={e => setPassword(e.target.value)}
										onPressEnter={handleLogin}
										style={{ height: 48 }}
									/>
								</Space>
								{
									code !== 200 && !modal ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											{message}
										</Text> :
										''
								}
							</div>
							<Checkbox
								onChange={handleCheckboxChange}
								style={{ color: '#8B8B8B', marginTop: 23, marginBottom: 25 }}
							>
								Remember me?
							</Checkbox>
							<Button
								block
								type="primary"
								size="large"
								disabled={disabled || loadingSubmit}
								onClick={handleLogin}
								style={{ height: 56 }}
							>
								{
									loadingSubmit ?
										<Row justify="center">
											<Loading />
										</Row> :
										'Login'
								}
							</Button>
							<div style={{ marginTop: 25 }}>
								<Text style={{ fontSize: 16, marginRight: 10 }}>
									Don’t have an account?
								</Text>
								<Text
									onClick={handleSignUpClick}
									style={{
										fontSize: 16,
										color: signUpClicked ? '#FFCC90' : '#FF8A00',
										cursor: 'pointer'
									}}
								>
									Sign up
								</Text>
							</div>
						</div>
					</Row>
				</Col>
				{
					windowWidth < 1340 ?
						<></> :
						<Col span={15}>
							<div className="banner">
								<img
									src={nekoWhiteLogo}
									alt="nekoexpense-logo-white"
									style={{ width: 454 }}
								/>
								<Text style={{ color: '#FFFFFF', fontSize: 30 }}>Receipt tracking and expense management for all.</Text>
							</div>
						</Col>
				}
			</Row>
			{renderResetPassModal()}
		</ConfigProvider>
	);
};

export default LoginPage;
