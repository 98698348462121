import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '2818730561609655';

const PixelHelper = {
	init() {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			ReactPixel.init(PIXEL_ID, null, { debug: true, autoConfig: false });
		} catch (e) {
		}
	},
	pageView(event, data) {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			ReactPixel.pageView(event, data);
		} catch (e) {
		}
	},
	track(event, data) {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			ReactPixel.track(event, data);
		} catch (e) {
		}
	},
	trackSingle(event, data) {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			ReactPixel.trackSingle(PIXEL_ID, event, data);
		} catch (e) {
		}
	},
	trackCustom(event, data) {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			ReactPixel.trackCustom(event, data);
		} catch (e) {
		}
	},
	trackSingleCustom(event, data) {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			ReactPixel.trackSingleCustom(PIXEL_ID, event, data);
		} catch (e) {
		}
	}
};

export default PixelHelper;

