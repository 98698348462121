import { connect } from 'react-redux';
import { verifyEmail, resetMeta, resetPage } from '../../actions/EmailConfirmationPage';
import EmailConfirmationPage from './EmailConfirmationPage';

const mapsStateToProps = state => ({
	emailConfirmPageProps: state.emailConfirmPageReducer
});

const mapsDispatchToProps = dispatch => ({
	verifyEmail(token) {
		dispatch(verifyEmail(token));
	},
	resetMeta() {
		dispatch(resetMeta());
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(EmailConfirmationPage);
