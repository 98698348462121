import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	total: 0,
	page: 1,
	limit: 10,
	count: 0,
	intuitConnections: [],
	loading: false
};

export default function intuitConnectionPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_INTUIT_CONNECTIONS_REQUEST:
		case ActionTypes.GET_INTUIT_CONNECTIONS_ERROR:
		case ActionTypes.GET_INTUIT_CONNECTIONS_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_INTUIT_CONNECTIONS_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_INTUIT_CONNECTIONS_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
