import { connect } from 'react-redux';
import { getActiveSubscription } from '../../actions/BillingPage';
import PaymentSuccessPage from './PaymentSuccessPage';

const mapsStateToProps = state => ({
	billingPageProps: state.billingPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getActiveSubscription(options) {
		dispatch(getActiveSubscription(options));
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(PaymentSuccessPage);
