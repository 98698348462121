/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Table, Form, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import dayjs from 'dayjs';

import Card from '../../components/Card';

// Helpers
import useNotification from '../../helpers/hooks/useNotification';

import masterCardLogo from '../../images/mastercard-logo.png';
import visaLogo from '../../images/visa-logo.png';
import dinersLogo from '../../images/diners-logo.png';
import discoverLogo from '../../images/discover-logo.png';
import eftposAuLogo from '../../images/eftpos-au-logo.png';
import jcbLogo from '../../images/jcb-logo.png';
import unionPayLogo from '../../images/unionpay-logo.png';
import amexLogo from '../../images/amex-logo.png';
import PixelHelper from '../../helpers/pixel';



const { Item } = Form;
const { Text } = Typography;

const SubscriptionReceiptPage = (props) => {
	// States
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	// Props
	const {
		getInvoices,
		subscriptionReceiptPageProps,
		resetPage,
		resetMeta
	} = props;
	const { total, page, limit, invoices, loadingReceipts, meta } = subscriptionReceiptPageProps;

	// Other Variables
	const paymentLogo = {
		amex: amexLogo,
		diners: dinersLogo,
		discover: discoverLogo,
		eftpos_au: eftposAuLogo,
		jcb: jcbLogo,
		mastercard: masterCardLogo,
		unionpay: unionPayLogo,
		visa: visaLogo
	};

	useNotification(meta, resetMeta);

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		getInvoices();
		return () => resetPage();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ---------------------------------- Start of Functions ---------------------------------- //
	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys, _) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		hideSelectAll: false
	};

	const handleDownload = (fileUrls) => {
		var interval = setInterval(download, 300, fileUrls);

		function download(urls) {
			var url = urls.pop();

			var a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', '');
			a.setAttribute('target', '_blank');
			a.click();

			if (urls.length === 0) {
				clearInterval(interval);
			}
		}
	};

	// ---------------------------------- End of Functions ---------------------------------- //

	const columns = [
		{
			title: 'Date',
			dataIndex: 'purchase_date',
			key: 'purchase_date',
			render: (text) => dayjs(text).format('MM/DD/YYYY')
		},
		{
			title: 'Service Period',
			dataIndex: 'service_period',
			key: 'service_period',
			render: (_, record) => {
				const { start_at, end_at } = record;
				return `${dayjs(start_at).format('MM/DD/YYYY')} - ${dayjs(end_at).format('MM/DD/YYYY')}`;
			}
		},
		{
			title: 'Payment Method',
			dataIndex: 'payment_method',
			key: 'payment_method',
			width: '40%',
			render: (_, record) => {
				const { subscription } = record;
				const { payment_method } = subscription || {};
				const { brand, last_four_digit } = payment_method || {};

				return (
					<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 20 }}>
						<img
							style={{ border: '1px solid #D9D9D9', padding: 5, height: 26, fontWeight: 'bold' }}
							src={paymentLogo[brand]}
							alt="payment-logo"
						/>
						<Text>
							•••• •••• •••• {last_four_digit}
						</Text>
					</div>
				);
			}
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: (_, record) => {
				const { amount, currency } = record;
				return `${amount} ${currency.toUpperCase()}`;
			}
		},
		{
			title: 'Action',
			dataIndex: 'action',
			render: (_, record) => {
				const { invoice_pdf } = record;
				return (
					<DownloadOutlined
						onClick={e => {
							e.preventDefault();
							handleDownload([invoice_pdf]);
						}}
						style={{ color: '#FF8A00', cursor: 'pointer',fontSize: 20 }}
					/>
				);
			}
		}
	];


	return(
		<>
			<Row>
				<Col span={24}>
					<Card
						title="Subscription Receipts"
						loading={loadingReceipts}
						style={{ width: '100%' }}
					>
						<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
							<Row style={{ marginBottom: 20 }}>
								<Item style={{ margin: 4 }}>
									<Button
										type="primary"
										icon={<DownloadOutlined />}
										disabled={selectedRowKeys.length < 2}
										onClick={() => {
											const fileUrls = selectedRowKeys.map(rowKey => {
												const invoice = invoices.find(invoice => invoice._id === rowKey);
												return invoice.invoice_pdf;
											});

											handleDownload(fileUrls);
										}}
									>
										Export
									</Button>
								</Item>
							</Row>
						</div>
						<Row>
							<Col flex="auto">
								<Table
									scroll={ { x: true } }
									columns={columns}
									dataSource={invoices.map(invoice => ({
										...invoice,
										key: invoice._id
									}))}
									pagination={{
										current: page,
										position: ['bottomCenter'],
										onChange: (current, pageSize) => {
											getInvoices({ page: current, limit: pageSize });
										},
										total,
										pageSize: limit
									}}
									rowSelection={rowSelection}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default SubscriptionReceiptPage;
