import * as ActionTypes from '../../constants/ActionTypes';

const initialState = {
	accountLink: null,
	loading: false,
	loadingSubmit: false,
	loadingResendEmail: false,
	loading_status: true
};

export default function accountLinkBasicPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ACCOUNT_LINK_BASIC_REQUEST:
		case ActionTypes.GET_ACCOUNT_LINK_BASIC_ERROR:
		case ActionTypes.GET_ACCOUNT_LINK_BASIC_SUCCESS:
		case ActionTypes.SUBMIT_ACCOUNT_LINK_REQUEST:
		case ActionTypes.SUBMIT_ACCOUNT_LINK_ERROR:
		case ActionTypes.SUBMIT_ACCOUNT_LINK_SUCCESS:
		case ActionTypes.RESEND_VERIFICATION_REQUEST:
		case ActionTypes.RESEND_VERIFICATION_ERROR:
		case ActionTypes.RESEND_VERIFICATION_SUCCESS:
		case ActionTypes.DELETE_ACCOUNT_LINK_REQUEST:
		case ActionTypes.DELETE_ACCOUNT_LINK_ERROR:
		case ActionTypes.DELETE_ACCOUNT_LINK_SUCCESS:
		case ActionTypes.GET_ACCOUNT_STATUS_BASIC_REQUEST:
		case ActionTypes.GET_ACCOUNT_STATUS_BASIC_ERROR:
		case ActionTypes.GET_ACCOUNT_STATUS_BASIC_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_ACCOUNT_LINK_BASIC_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_ACCOUNT_LINK_BASIC_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
