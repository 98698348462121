import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

export function getActiveSubscription() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_ACTIVE_SUBSCRIPTION_REQUEST,
			payload: { loadingSubscription: true }
		});

		const response = await API.subscription.getActiveSubscription();

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR,
				payload: {
					meta: response.meta,
					loadingSubscription: false
				}
			});
			return;
		}

		const { subscription } = response.data;

		dispatch({
			type: ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS,
			payload: {
				subscription,
				loadingSubscription: false
			}
		});
	};
}