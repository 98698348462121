import React, { Component } from 'react';
import { Typography, Image, Card } from 'antd';
import errorPng from '../../images/error.png';
import PixelHelper from '../../helpers/pixel';

const { Text } = Typography;

class ErrorPage extends Component {
	componentDidMount() {
		PixelHelper.pageView(window.location.pathname);
	}

	render() {
		const searchParams = new URLSearchParams(window.location.search);
		const message = searchParams.get('message');
		return (
			<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
				<Card
					style={{
						width: '773px',
						height: '464px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '50px',
						boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
				 }}
				 bordered
				>
					<div
						style={{
							height: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<div style={{ marginBottom: 20 }}>
							<Image
								width={200}
								src={errorPng}
								preview={false}
							/>
						</div>
						<div>
							<Text style={{ fontWeight: 510, fontSize: '30px' }}>Oops, Sorry!</Text>
						</div>
						<div>
							<p style={{ fontWeight: 274, fontSize: '19px', textAlign: 'center' }}>{message}</p>
						</div>
					</div>
				</Card>
			</div>
		);
	}
}

export default ErrorPage;
