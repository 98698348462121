import { push } from 'connected-react-router';

import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';


export function login({ email, password, query }) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.LOGIN_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const response = await API.auth.login({ email, password });

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.LOGIN_ERROR,
				payload: {
					loadingSubmit: false,
					meta: response.meta
				}
			});
			return;
		}

		const { auth, user } = response.data;

		dispatch({
			type: ActionTypes.LOGIN_SUCCESS
		});

		// store response
		LocalStorageHelper.setUser(user);
		LocalStorageHelper.setToken(auth.token);

		if (!user.subscription) {
			// Navigate to link page
			dispatch(push('/how-it-works'));
			return;
		}

		// Navigate to specified page
		if (query.path && query.path !== '/' && query.path !== '') {
			dispatch(push(query.path));
			return;
		}

		// Navigate to next page
		dispatch(push('/expenses'));
	};
}

export function loginWithGoogle({ credential, query }) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.LOGIN_WITH_GOOGLE_REQUEST,
			payload: {
				loading: true
			}
		});

		const response = await API.auth.loginWithGoogle({ credential });

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.LOGIN_WITH_GOOGLE_ERROR,
				payload: {
					loading: false,
					meta: response.meta
				}
			});
			return;
		}

		const { auth, user } = response.data;

		dispatch({
			type: ActionTypes.LOGIN_WITH_GOOGLE_SUCCESS
		});

		// store response
		LocalStorageHelper.setUser(user);
		LocalStorageHelper.setToken(auth.token);

		if (!user.subscription) {
			// Navigate to link page
			dispatch(push('/how-it-works'));
			return;
		}

		// Navigate to specified page
		if (query.path && query.path !== '/' && query.path !== '') {
			dispatch(push(query.path));
			return;
		}

		// Navigate to next page
		dispatch(push('/expenses'));
	};
}

export function requestResetPasswordLink(email) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_REQUEST,
			payload: { loadingResetPassword: true }
		});

		const body = {
			email
		};

		const response = await API.user.createResetPasswordLink(body);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_ERROR,
				payload: {
					meta: response.meta,
					loadingResetPassword: false
				}
			});

			setTimeout(() => {
				dispatch({
					type: ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_ERROR,
					payload: { meta: null }
				});
			}, 5000);
			return;
		}


		dispatch({
			type: ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_SUCCESS,
			payload: {
				loadingResetPassword: false,
				meta: response.meta
			}
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_LOGIN_PAGE
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_LOGIN_PAGE_META
		});
	};
}
