module.exports = [
	{ value: '+93', label: '+93', country: 'Afghanistan' },
	{ value: '+355', label: '+355', country: 'Albania' },
	{ value: '+213', label: '+213', country: 'Algeria' },
	{ value: '+1-684', label: '+1-684', country: 'American Samoa' },
	{ value: '+376', label: '+376', country: 'Andorra' },
	{ value: '+244', label: '+244', country: 'Angola' },
	{ value: '+1-264', label: '+1-264', country: 'Anguilla' },
	{ value: '+672', label: '+672', country: 'Antarctica' },
	{ value: '+1-268', label: '+1-268', country: 'Antigua and Barbuda' },
	{ value: '+54', label: '+54', country: 'Argentina' },
	{ value: '+374', label: '+374', country: 'Armenia' },
	{ value: '+297', label: '+297', country: 'Aruba' },
	{ value: '+61', label: '+61', country: 'Australia' },
	{ value: '+43', label: '+43', country: 'Austria' },
	{ value: '+994', label: '+994', country: 'Azerbaijan' },
	{ value: '+1-242', label: '+1-242', country: 'Bahamas' },
	{ value: '+973', label: '+973', country: 'Bahrain' },
	{ value: '+880', label: '+880', country: 'Bangladesh' },
	{ value: '+1-246', label: '+1-246', country: 'Barbados' },
	{ value: '+375', label: '+375', country: 'Belarus' },
	{ value: '+32', label: '+32', country: 'Belgium' },
	{ value: '+501', label: '+501', country: 'Belize' },
	{ value: '+229', label: '+229', country: 'Benin' },
	{ value: '+1-441', label: '+1-441', country: 'Bermuda' },
	{ value: '+975', label: '+975', country: 'Bhutan' },
	{ value: '+591', label: '+591', country: 'Bolivia' },
	{ value: '+387', label: '+387', country: 'Bosnia and Herzegovina' },
	{ value: '+267', label: '+267', country: 'Botswana' },
	{ value: '+55', label: '+55', country: 'Brazil' },
	{ value: '+246', label: '+246', country: 'British Indian Ocean Territory' },
	{ value: '+1-284', label: '+1-284', country: 'British Virgin Islands' },
	{ value: '+673', label: '+673', country: 'Brunei' },
	{ value: '+359', label: '+359', country: 'Bulgaria' },
	{ value: '+226', label: '+226', country: 'Burkina Faso' },
	{ value: '+95', label: '+95', country: 'Myanmar' },
	{ value: '+257', label: '+257', country: 'Burundi' },
	{ value: '+855', label: '+855', country: 'Cambodia' },
	{ value: '+237', label: '+237', country: 'Cameroon' },
	{ value: '+1', label: '+1', country: 'Canada' },
	{ value: '+238', label: '+238', country: 'Cape Verde' },
	{ value: '+1-345', label: '+1-345', country: 'Cayman Islands' },
	{ value: '+236', label: '+236', country: 'Central African Republic' },
	{ value: '+235', label: '+235', country: 'Chad' },
	{ value: '+56', label: '+56', country: 'Chile' },
	{ value: '+86', label: '+86', country: 'China' },
	{ value: '+61', label: '+61', country: 'Christmas Island' },
	{ value: '+61', label: '+61', country: 'Cocos Islands' },
	{ value: '+57', label: '+57', country: 'Colombia' },
	{ value: '+269', label: '+269', country: 'Comoros' },
	{ value: '+242', label: '+242', country: 'Republic of the Congo' },
	{ value: '+243', label: '+243', country: 'Democratic Republic of the Congo' },
	{ value: '+682', label: '+682', country: 'Cook Islands' },
	{ value: '+506', label: '+506', country: 'Costa Rica' },
	{ value: '+385', label: '+385', country: 'Croatia' },
	{ value: '+53', label: '+53', country: 'Cuba' },
	{ value: '+599', label: '+599', country: 'Curacao' },
	{ value: '+357', label: '+357', country: 'Cyprus' },
	{ value: '+420', label: '+420', country: 'Czech Republic' },
	{ value: '+45', label: '+45', country: 'Denmark' },
	{ value: '+253', label: '+253', country: 'Djibouti' },
	{ value: '+1-767', label: '+1-767', country: 'Dominica' },
	{ value: '+1-809', label: '+1-809', country: 'Dominican Republic' },
	{ value: '+1-829', label: '+1-829', country: 'Dominican Republic' },
	{ value: '+1-849', label: '+1-849', country: 'Dominican Republic' },
	{ value: '+670', label: '+670', country: 'East Timor' },
	{ value: '+593', label: '+593', country: 'Ecuador' },
	{ value: '+20', label: '+20', country: 'Egypt' },
	{ value: '+503', label: '+503', country: 'El Salvador' },
	{ value: '+240', label: '+240', country: 'Equatorial Guinea' },
	{ value: '+291', label: '+291', country: 'Eritrea' },
	{ value: '+372', label: '+372', country: 'Estonia' },
	{ value: '+251', label: '+251', country: 'Ethiopia' },
	{ value: '+500', label: '+500', country: 'Falkland Islands' },
	{ value: '+298', label: '+298', country: 'Faroe Islands' },
	{ value: '+679', label: '+679', country: 'Fiji' },
	{ value: '+358', label: '+358', country: 'Finland' },
	{ value: '+33', label: '+33', country: 'France' },
	{ value: '+689', label: '+689', country: 'French Polynesia' },
	{ value: '+241', label: '+241', country: 'Gabon' },
	{ value: '+220', label: '+220', country: 'Gambia' },
	{ value: '+995', label: '+995', country: 'Georgia' },
	{ value: '+49', label: '+49', country: 'Germany' },
	{ value: '+233', label: '+233', country: 'Ghana' },
	{ value: '+350', label: '+350', country: 'Gibraltar' },
	{ value: '+30', label: '+30', country: 'Greece' },
	{ value: '+299', label: '+299', country: 'Greenland' },
	{ value: '+1-473', label: '+1-473', country: 'Grenada' },
	{ value: '+1-671', label: '+1-671', country: 'Guam' },
	{ value: '+502', label: '+502', country: 'Guatemala' },
	{ value: '+44-1481', label: '+44-1481', country: 'Guernsey' },
	{ value: '+224', label: '+224', country: 'Guinea' },
	{ value: '+245', label: '+245', country: 'Guinea-Bissau' },
	{ value: '+592', label: '+592', country: 'Guyana' },
	{ value: '+509', label: '+509', country: 'Haiti' },
	{ value: '+504', label: '+504', country: 'Honduras' },
	{ value: '+852', label: '+852', country: 'Hong Kong' },
	{ value: '+36', label: '+36', country: 'Hungary' },
	{ value: '+354', label: '+354', country: 'Iceland' },
	{ value: '+91', label: '+91', country: 'India' },
	{ value: '+62', label: '+62', country: 'Indonesia' },
	{ value: '+98', label: '+98', country: 'Iran' },
	{ value: '+964', label: '+964', country: 'Iraq' },
	{ value: '+353', label: '+353', country: 'Ireland' },
	{ value: '+44-1624', label: '+44-1624', country: 'Isle of Man' },
	{ value: '+972', label: '+972', country: 'Israel' },
	{ value: '+39', label: '+39', country: 'Italy' },
	{ value: '+225', label: '+225', country: 'Ivory Coast' },
	{ value: '+1-876', label: '+1-876', country: 'Jamaica' },
	{ value: '+81', label: '+81', country: 'Japan' },
	{ value: '+44-1534', label: '+44-1534', country: 'Jersey' },
	{ value: '+962', label: '+962', country: 'Jordan' },
	{ value: '+7', label: '+7', country: 'Kazakhstan' },
	{ value: '+254', label: '+254', country: 'Kenya' },
	{ value: '+686', label: '+686', country: 'Kiribati' },
	{ value: '+383', label: '+383', country: 'Kosovo' },
	{ value: '+965', label: '+965', country: 'Kuwait' },
	{ value: '+996', label: '+996', country: 'Kyrgyzstan' },
	{ value: '+856', label: '+856', country: 'Laos' },
	{ value: '+371', label: '+371', country: 'Latvia' },
	{ value: '+961', label: '+961', country: 'Lebanon' },
	{ value: '+266', label: '+266', country: 'Lesotho' },
	{ value: '+231', label: '+231', country: 'Liberia' },
	{ value: '+218', label: '+218', country: 'Libya' },
	{ value: '+423', label: '+423', country: 'Liechtenstein' },
	{ value: '+370', label: '+370', country: 'Lithuania' },
	{ value: '+352', label: '+352', country: 'Luxembourg' },
	{ value: '+853', label: '+853', country: 'Macao' },
	{ value: '+389', label: '+389', country: 'Macedonia' },
	{ value: '+261', label: '+261', country: 'Madagascar' },
	{ value: '+265', label: '+265', country: 'Malawi' },
	{ value: '+60', label: '+60', country: 'Malaysia' },
	{ value: '+960', label: '+960', country: 'Maldives' },
	{ value: '+223', label: '+223', country: 'Mali' },
	{ value: '+356', label: '+356', country: 'Malta' },
	{ value: '+692', label: '+692', country: 'Marshall Islands' },
	{ value: '+222', label: '+222', country: 'Mauritania' },
	{ value: '+230', label: '+230', country: 'Mauritius' },
	{ value: '+262', label: '+262', country: 'Mayotte' },
	{ value: '+52', label: '+52', country: 'Mexico' },
	{ value: '+691', label: '+691', country: 'Micronesia' },
	{ value: '+373', label: '+373', country: 'Moldova' },
	{ value: '+377', label: '+377', country: 'Monaco' },
	{ value: '+976', label: '+976', country: 'Mongolia' },
	{ value: '+382', label: '+382', country: 'Montenegro' },
	{ value: '+1-664', label: '+1-664', country: 'Montserrat' },
	{ value: '+212', label: '+212', country: 'Morocco' },
	{ value: '+258', label: '+258', country: 'Mozambique' },
	{ value: '+95', label: '+95', country: 'Myanmar' },
	{ value: '+264', label: '+264', country: 'Namibia' },
	{ value: '+674', label: '+674', country: 'Nauru' },
	{ value: '+977', label: '+977', country: 'Nepal' },
	{ value: '+31', label: '+31', country: 'Netherlands' },
	{ value: '+599', label: '+599', country: 'Netherlands Antilles' },
	{ value: '+687', label: '+687', country: 'New Caledonia' },
	{ value: '+64', label: '+64', country: 'New Zealand' },
	{ value: '+505', label: '+505', country: 'Nicaragua' },
	{ value: '+227', label: '+227', country: 'Niger' },
	{ value: '+234', label: '+234', country: 'Nigeria' },
	{ value: '+683', label: '+683', country: 'Niue' },
	{ value: '+850', label: '+850', country: 'North Korea' },
	{ value: '+1-670', label: '+1-670', country: 'Northern Mariana Islands' },
	{ value: '+47', label: '+47', country: 'Norway' },
	{ value: '+968', label: '+968', country: 'Oman' },
	{ value: '+92', label: '+92', country: 'Pakistan' },
	{ value: '+680', label: '+680', country: 'Palau' },
	{ value: '+970', label: '+970', country: 'Palestine' },
	{ value: '+507', label: '+507', country: 'Panama' },
	{ value: '+675', label: '+675', country: 'Papua New Guinea' },
	{ value: '+595', label: '+595', country: 'Paraguay' },
	{ value: '+51', label: '+51', country: 'Peru' },
	{ value: '+63', label: '+63', country: 'Philippines' },
	{ value: '+870', label: '+870', country: 'Pitcairn Islands' },
	{ value: '+48', label: '+48', country: 'Poland' },
	{ value: '+351', label: '+351', country: 'Portugal' },
	{ value: '+1-787', label: '+1-787', country: 'Puerto Rico' },
	{ value: '+1-939', label: '+1-939', country: 'Puerto Rico' },
	{ value: '+974', label: '+974', country: 'Qatar' },
	{ value: '+242', label: '+242', country: 'Republic of the Congo' },
	{ value: '+262', label: '+262', country: 'Reunion' },
	{ value: '+40', label: '+40', country: 'Romania' },
	{ value: '+7', label: '+7', country: 'Russia' },
	{ value: '+250', label: '+250', country: 'Rwanda' },
	{ value: '+590', label: '+590', country: 'Saint Barthelemy' },
	{ value: '+290', label: '+290', country: 'Saint Helena' },
	{ value: '+1-869', label: '+1-869', country: 'Saint Kitts and Nevis' },
	{ value: '+1-758', label: '+1-758', country: 'Saint Lucia' },
	{ value: '+590', label: '+590', country: 'Saint Martin' },
	{ value: '+508', label: '+508', country: 'Saint Pierre and Miquelon' },
	{ value: '+1-784', label: '+1-784', country: 'Saint Vincent and the Grenadines' },
	{ value: '+685', label: '+685', country: 'Samoa' },
	{ value: '+378', label: '+378', country: 'San Marino' },
	{ value: '+239', label: '+239', country: 'Sao Tome and Principe' },
	{ value: '+966', label: '+966', country: 'Saudi Arabia' },
	{ value: '+221', label: '+221', country: 'Senegal' },
	{ value: '+381', label: '+381', country: 'Serbia' },
	{ value: '+248', label: '+248', country: 'Seychelles' },
	{ value: '+232', label: '+232', country: 'Sierra Leone' },
	{ value: '+65', label: '+65', country: 'Singapore' },
	{ value: '+1-721', label: '+1-721', country: 'Sint Maarten' },
	{ value: '+421', label: '+421', country: 'Slovakia' },
	{ value: '+386', label: '+386', country: 'Slovenia' },
	{ value: '+677', label: '+677', country: 'Solomon Islands' },
	{ value: '+252', label: '+252', country: 'Somalia' },
	{ value: '+27', label: '+27', country: 'South Africa' },
	{ value: '+82', label: '+82', country: 'South Korea' },
	{ value: '+211', label: '+211', country: 'South Sudan' },
	{ value: '+34', label: '+34', country: 'Spain' },
	{ value: '+94', label: '+94', country: 'Sri Lanka' },
	{ value: '+249', label: '+249', country: 'Sudan' },
	{ value: '+597', label: '+597', country: 'Suriname' },
	{ value: '+47', label: '+47', country: 'Svalbard and Jan Mayen' },
	{ value: '+268', label: '+268', country: 'Swaziland' },
	{ value: '+46', label: '+46', country: 'Sweden' },
	{ value: '+41', label: '+41', country: 'Switzerland' },
	{ value: '+963', label: '+963', country: 'Syria' },
	{ value: '+886', label: '+886', country: 'Taiwan' },
	{ value: '+992', label: '+992', country: 'Tajikistan' },
	{ value: '+255', label: '+255', country: 'Tanzania' },
	{ value: '+66', label: '+66', country: 'Thailand' },
	{ value: '+228', label: '+228', country: 'Togo' },
	{ value: '+690', label: '+690', country: 'Tokelau' },
	{ value: '+676', label: '+676', country: 'Tonga' },
	{ value: '+1-868', label: '+1-868', country: 'Trinidad and Tobago' },
	{ value: '+216', label: '+216', country: 'Tunisia' },
	{ value: '+90', label: '+90', country: 'Turkey' },
	{ value: '+993', label: '+993', country: 'Turkmenistan' },
	{ value: '+1-649', label: '+1-649', country: 'Turks and Caicos Islands' },
	{ value: '+688', label: '+688', country: 'Tuvalu' },
	{ value: '+1-340', label: '+1-340', country: 'U.S. Virgin Islands' },
	{ value: '+256', label: '+256', country: 'Uganda' },
	{ value: '+380', label: '+380', country: 'Ukraine' },
	{ value: '+971', label: '+971', country: 'United Arab Emirates' },
	{ value: '+44', label: '+44', country: 'United Kingdom' },
	{ value: '+1', label: '+1', country: 'United States' },
	{ value: '+598', label: '+598', country: 'Uruguay' },
	{ value: '+998', label: '+998', country: 'Uzbekistan' },
	{ value: '+678', label: '+678', country: 'Vanuatu' },
	{ value: '+39-06', label: '+39-06', country: 'Vatican' },
	{ value: '+58', label: '+58', country: 'Venezuela' },
	{ value: '+84', label: '+84', country: 'Vietnam' },
	{ value: '+681', label: '+681', country: 'Wallis and Futuna' },
	{ value: '+212', label: '+212', country: 'Western Sahara' },
	{ value: '+967', label: '+967', country: 'Yemen' },
	{ value: '+260', label: '+260', country: 'Zambia' },
	{ value: '+263', label: '+263', country: 'Zimbabwe' }
];
