const currencies = [
	{
		name: 'Australia',
		currency: 'Australian Dollar',
		code: 'AUD',
		symbol: 'A$'
	},
	{
		name: 'China',
		currency: 'Chinese Yuan',
		code: 'CNY',
		symbol: '¥'
	},
	{
		name: 'United Kingdom',
		currency: 'British Pound',
		code: 'GBP',
		symbol: '£'
	},
	{
		name: 'Hong Kong',
		currency: 'Hong Kong Dollar',
		code: 'HKD',
		symbol: 'HK$'
	},
	{
		name: 'Japan',
		currency: 'Japanese Yen',
		code: 'JPY',
		symbol: '¥'
	},
	{
		name: 'United States',
		currency: 'United States Dollar',
		code: 'USD',
		symbol: '$'
	},
	{
		name: 'Canada',
		currency: 'Canadian Dollar',
		code: 'CAD',
		symbol: 'CA$'
	}
];

export default currencies;
