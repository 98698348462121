import { connect } from 'react-redux';
import { getIntuitConnections, resetPage } from '../../actions/IntuitConnectionPage';
import { disconnectFromQuickBooks } from '../../actions/DisconnectFromQuickBookCallBackPage';
import IntuitConnectionPage from './IntuitConnectionPage';

const mapsStateToProps = state => ({
	intuitConnectionPageProps: state.intuitConnectionPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getIntuitConnections(options) {
		dispatch(getIntuitConnections(options));
	},
	disconnectFromQuickBooks(options) {
		dispatch(disconnectFromQuickBooks(options));
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(IntuitConnectionPage);
