import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	total: 0,
	page: 1,
	limit: 10,
	count: 0,
	invoices: [],
	loadingReceipts: false
};

export default function subscriptionReceiptPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_SUBSCRIPTION_RECEIPTS_REQUEST:
		case ActionTypes.GET_SUBSCRIPTION_RECEIPTS_ERROR:
		case ActionTypes.GET_SUBSCRIPTION_RECEIPTS_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_SUBSCRIPTION_RECEIPTS_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_SUBSCRIPTION_RECEIPTS_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
