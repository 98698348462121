import { connect } from 'react-redux';
import { verifyAccountLinkEmail, resetMeta, resetPage } from '../../actions/ChannelConfirmationPage';
import ChannelConfirmationPage from './ChannelConfirmationPage';

const mapsStateToProps = state => ({
	channelConfirmPageProps: state.channelConfirmPageReducer
});

const mapsDispatchToProps = dispatch => ({
	verifyAccountLinkEmail(token) {
		dispatch(verifyAccountLinkEmail(token));
	},
	resetMeta() {
		dispatch(resetMeta());
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(ChannelConfirmationPage);
