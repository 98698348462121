const LocalStorageHelper = {
	/**
	 * Get the bearer token from local storage
	 * @returns {String} returns empty string if the item is not present
	 */
	getToken: () => {
		return localStorage.getItem('user_token') || '';
	},

	/**
	 * Set the bearer token to local storage
	 * @param {String} token
	 * @returns {void}
	 */
	setToken: (token) => {
		try {
			localStorage.setItem('user_token', token);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Set the user data to the local storage
	 * @param {Object} user
	 * @returns {void}
	 */
	setUser: (user) => {
		// Wrap in a try-catch block to capture error when JSON.stringify fails
		try {
			localStorage.setItem('user', JSON.stringify(user));
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Get user data from local storage
	 * @returns {Object}
	 */
	getUser: () => {
		// Wrap in a try-catch block to capture error when JSON.parse fails
		try {
			const jsonUser = JSON.parse(localStorage.getItem('user'));
			return jsonUser;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Remove user item from local storage
	 * @returns {void}
	 */
	removeUser: () => {
		localStorage.removeItem('user');
	},

	/**
	 * Remove bearer token item from local storage
	 * @returns {void}
	 */
	removeToken: () => {
		localStorage.removeItem('user_token');
	},

	/**
	 * Set the user data to the local storage
	 * @param {String} email
	 * @returns {void}
	 */
	setEmail: (email) => {
		localStorage.setItem('email', email);
	},

	getEmail: () => {
		return localStorage.getItem('email') || '';
	},

	setTime: (time) => {
		localStorage.setItem('time', time);
	},

	getTime: () => {
		return parseInt(localStorage.getItem('time'), 10) || 0;
	},

	removeTime: () => {
		localStorage.removeItem('time');
	},

	getUserId: () => {
		// Wrap in a try-catch block to capture error when JSON.parse fails
		try {
			const jsonUser = JSON.parse(localStorage.getItem('user'));
			return jsonUser._id;
		} catch (error) {
			console.log(error);
		}
	}
};

export default LocalStorageHelper;
