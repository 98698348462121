import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Select, Row, Col, ConfigProvider, Image } from 'antd';
import _ from 'lodash';

import UrlParse from 'url-parse';

import Card from '../../../../components/Card';

import quickbook_sync from '../../../../images/quickbook_sync.png';

const { Item } = Form;

const IntuitConnectionBasicForm = (props) => {
	// Props
	const { intuitConnectionBasicPageProps, onSubmit, location } = props;
	const { intuitConnection, loadingSubmit, meta } = intuitConnectionBasicPageProps;
	const { token_valid, enable_sync, sync_account_name, sync_expense_accounts, sync_payment_methods } = intuitConnection || '';

	const formRef = useRef();

	// States
	const [disabled, setDisabled] = useState(true);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const { query } = UrlParse(location.search, true);

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (meta && meta.code === 200) {
			setDisabled(true);
		}
	}, [meta]);

	// --------------------------------- Start of Functions --------------------------------- //

	const checkFormValues = () => {
		const syncAccountNameRef = formRef.current.getFieldValue('sync_account_name');
		const syncExpenseAccountsRef = formRef.current.getFieldValue('sync_expense_accounts').some(obj => obj.hasOwnProperty('sync_name') && obj['sync_name'] === '');
		const syncPaymentMethodsRef = formRef.current.getFieldValue('sync_payment_methods').some(obj => obj.hasOwnProperty('sync_name') && obj['sync_name'] === '');

		if (syncExpenseAccountsRef || syncPaymentMethodsRef || syncAccountNameRef === '' || syncAccountNameRef === undefined) {
			setDisabled(true);
		} else {
			const currentValues = _.omit(formRef.current.getFieldsValue(), 'token_valid');
			const isChanged = JSON.stringify({ enable_sync, sync_account_name, sync_expense_accounts, sync_payment_methods }) !== JSON.stringify(currentValues);
			setDisabled(!isChanged);
		}
	};

	const handleOnSubmit = (values) => {
		const { enable_sync, sync_account_name, sync_expense_accounts, sync_payment_methods } = values;
		const fields = {
		  enable_sync,
			sync_account_name,
		  sync_expense_accounts,
		  sync_payment_methods
		};
		onSubmit(fields);
	};

	// ---------------------------------- End of Functions ---------------------------------- //

	return (
		<ConfigProvider
			theme={{
				components: {
					Form: {
						labelFontSize: 16
					}
				}
			}}
		>
			<div style={{ maxWidth: 485 }}>
				<div style={{ width: windowWidth < 700 ? '100%' : 485 }}/>
				<Form
					style={{ width: '100%' }}
					layout="vertical"
					initialValues={{
						token_valid: token_valid ? 'True' : 'False',
						enable_sync,
						sync_account_name,
						sync_expense_accounts,
						sync_payment_methods
					}}
					onFinish={handleOnSubmit}
					ref={formRef}
				>
					<Row
						justify="space-between"
						gutter={8}
					>
						<Col span={24}>
							<p style={{ marginBottom: 10 }}>Sample Image: </p>
							<Image
								src={quickbook_sync}
								alt="QuickBook Sync"
							/>
						</Col>
						<Col
							span={24}
							style={{ marginTop: 10 }}
						>
							<Item
								name="token_valid"
								label="Connected"
							>
								<Input
									placeholder="-"
									disabled={true}
								/>
							</Item>
						</Col>
						<Col span={24}>
							<Item
								name="enable_sync"
								label="Send expense data to QuickBook every 30 minutes"
								rules={[{ message: 'Sync Data is required', required: true }]}
							>
								<Select
									placeholder="Enable Sync"
									options={[
										{
											value: true,
											label: 'Enabled'
										},
										{
											value: false,
											label: 'Disabled'
										}
									]}
									onChange={checkFormValues}
								/>
							</Item>
						</Col>
						<Col span={24}>
							<Item
								name="sync_account_name"
								label="QuickBook Payment Account Name (case sensitive)"
								rules={[{ message: 'Payment Account Name is required', required: true }]}
							>
								<Input
									placeholder="Enter Payment Account Name"
									onChange={checkFormValues}
								/>
							</Item>
						</Col>
						<Col span={24}>
							<Form.List name="sync_expense_accounts">
								{(fields) => (
									<>
										<p style={{ marginBottom: 7, fontSize: '16px', fontWeight: 510 }}>QuickBook Expense Category (case sensitive)</p>
										<Card style={{ marginBottom: 15 }}>
											{fields.map((field, index) => (
												<>
													<label><span style={{ color: 'red' }}>*</span> QuickBook Expense Category for {sync_expense_accounts[field.name].sync_name}</label>
													<Form.Item
														{...field}
														name={[field.name, 'sync_name']}
														fieldKey={[field.fieldKey, 'sync_name']}
														rules={[{ required: true, message: 'Missing Category Name' }]}
													>
														<Input
															placeholder="Enter Category Name"
															onChange={checkFormValues}
														/>
													</Form.Item>
												</>
											))}
										</Card>
									</>
								)}
							</Form.List>
						</Col>
						<Col span={24}>
							<Form.List name="sync_payment_methods">
								{(fields) => (
									<>
										<p style={{ marginBottom: 7, fontSize: '16px', fontWeight: 510 }}>QuickBook Payment Methods (case sensitive)</p>
										<Card style={{ marginBottom: 15 }}>
											{fields.map((field, index) => (
												<>
													<label><span style={{ color: 'red' }}>*</span> QuickBook Account Name for payment through {sync_payment_methods[field.name].sync_name}</label>
													<Form.Item
														{...field}
														name={[field.name, 'sync_name']}
														fieldKey={[field.fieldKey, 'sync_name']}
														rules={[{ required: true, message: 'Missing Payment Method Name' }]}
													>
														<Input
															placeholder="Enter Payment Method Name"
															onChange={checkFormValues}
														/>
													</Form.Item>
												</>
											))}
										</Card>
									</>
								)}
							</Form.List>
						</Col>
					</Row>
					{ query.id && (
						<Item style={{ marginTop: 20 }}>
							<Row justify="end">
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: 100 }}
									loading={loadingSubmit}
									disabled={disabled}
								>
							Save
								</Button>
							</Row>
						</Item>
					)}
				</Form>
			</div>
		</ConfigProvider>
	);
};

export default IntuitConnectionBasicForm;
