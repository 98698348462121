import { connect } from 'react-redux';
import HowItWorksPage from './HowItWorksPage';

const mapsStateToProps = state => ({
});

const mapsDispatchToProps = dispatch => ({
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(HowItWorksPage);
