import React, { useState, useEffect } from 'react';
import _, { isEmpty } from 'lodash';
import { Row, Typography, Input, Button, ConfigProvider, Space } from 'antd';
import UrlParse from 'url-parse';

import PasswordHelper from '../../helpers/password';
import Loading from '../../components/Loading';
import Card from '../../components/Card';

import nekoLogo from '../../images/logo.svg';
import PixelHelper from '../../helpers/pixel';

const { Text } = Typography;

const ResetPasswordPage = (props) => {
	// States
	const [newPassword, setNewPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [disabled, setDisabled] = useState(true);
	const [newPassValidationMsg, setNewPassValidationMsg] = useState(null);
	const [confirmPassValidationMsg, setConfirmPassValidationMsg] = useState(null);


	// Props
	const { getUserByResetPasswordToken, resetPassword, location, resetPage, resetMeta, resetPasswordPageProps, history } = props;
	const { user, loading, loadingSubmit, meta } = resetPasswordPageProps;
	const { query } = UrlParse(location.search, true);

	// Functions
	const handleProceed = () => {
		const token = query.t;
		const fields = {
			new_password: newPassword,
			confirm_password: confirmPassword
		};
		resetPassword(token, fields);
	};

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	// Use Effects
	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		const token = query.t;
		getUserByResetPasswordToken(token);

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			resetPage();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(meta){
			meta.code !== 403 && meta.code !== 200 && history.push(`/error?message=${meta.message}`);
			if(meta.code === 403){
				setNewPassValidationMsg(meta.message);
				setConfirmPassValidationMsg(meta.message);
			}
			resetMeta();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);

	useEffect(() => {
		if (_.isEmpty(newPassword) || isEmpty(confirmPassword)) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newPassword, confirmPassword]);

	useEffect(() => {
		if (newPassword && newPassword.length < 8) {
			setNewPassValidationMsg('Password must be atleast 8 characters.');
		} else if (newPassword && !PasswordHelper.validatePassword(newPassword)) {
			setNewPassValidationMsg('The password must contain at least one uppercase letter, one lowercase letter, one number, and one special character from the following set: _!@#$%^&*(),.?":{}|<>.');
		} else {
			setNewPassValidationMsg(null);
		}

		if (confirmPassword && confirmPassword !== newPassword) {
			setConfirmPassValidationMsg('The password did not match!');
		} else {
			setConfirmPassValidationMsg(null);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newPassword]);

	useEffect(() => {
		if (newPassword !== confirmPassword) {
			setConfirmPassValidationMsg('The password did not match!');
		} else {
			setConfirmPassValidationMsg(null);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmPassword]);

	if (loading) {
		return (
			<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Loading/>
			</div>
		);
	}

	return(
		<ConfigProvider
			theme={{
				token: {
					fontSize: 19,
					fontSizeHeading1: 30,
					colorTextHeading: '#344E41'
				}
			}}
		>
			<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5F5F5' }}>
				<Card style={{ width: windowWidth < 600 ? '100%' : 595, padding: windowWidth < 600 ? 30 : '63px 70px', borderRadius: 50 }}>
					<div>
						<img
							src={nekoLogo}
							alt="nekoexpense-logo"
							style={{ width: windowWidth < 450 ? '100%' : 341 }}
						/>
						<p style={{ margin: 0, marginBottom: 39 }}>Enter your new password</p>
					</div>
					<div>
						<Space
							block
							direction="vertical"
							style={{ width: '100%' }}
						>
							<Text strong>Email Address</Text>
							<Input
								value={user?.email}
								placeholder="Enter Email"
								disabled={true}
								style={{ height: 48 }}
							/>
						</Space>
						{
							meta && meta?.code !== 200 ?
								<Text
									type="danger"
									style={{ fontSize: 16 }}
								>
									{meta?.message}
								</Text> :
								''
						}
					</div>
					<div style={{ marginTop: 23 }}>
						<Space
							block
							direction="vertical"
							style={{ width: '100%' }}
						>
							<Text strong>New Password</Text>
							<Input.Password
								placeholder="Enter Password"
								disabled={loadingSubmit || !user}
								onChange={e => setNewPassword(e.target.value)}
								style={{ height: 48 }}
							/>
						</Space>
						{
							meta && meta?.code !== 200 ?
								<Text
									type="danger"
									style={{ fontSize: 16 }}
								>
									{meta?.message}
								</Text> :
								''
						}
						{
							newPassValidationMsg ?
								<Text
									type="danger"
									style={{ fontSize: 16 }}
								>
									{newPassValidationMsg}
								</Text> :
								''
						}
					</div>
					<div style={{ marginTop: 23 }}>
						<Space
							block
							direction="vertical"
							style={{ width: '100%' }}
						>
							<Text strong>Confirm Password</Text>
							<Input.Password
								placeholder="Enter Confirmation Password"
								disabled={!newPassword || newPassValidationMsg || loadingSubmit || !user}
								onChange={e => setConfirmPassword(e.target.value)}
								style={{ height: 48 }}
								onPaste={(e) => e.preventDefault()}
							/>
						</Space>
						{
							meta && meta?.code !== 200 ?
								<Text
									type="danger"
									style={{ fontSize: 16 }}
								>
									{meta?.message}
								</Text> :
								''
						}
						{
							confirmPassValidationMsg ?
								<Text
									type="danger"
									style={{ fontSize: 16 }}
								>
									{confirmPassValidationMsg}
								</Text> :
								''
						}
					</div>
					<Button
						block
						type="primary"
						size="large"
						disabled={disabled || loadingSubmit || !user || newPassValidationMsg || confirmPassValidationMsg}
						onClick={handleProceed}
						style={{ height: 56, marginTop: 53  }}
					>
						{
							loadingSubmit ?
								<Row justify="center">
									<Loading/>
								</Row> :
								'Proceed'
						}
					</Button>
				</Card>
			</div>
		</ConfigProvider>
	);
};

export default ResetPasswordPage;
