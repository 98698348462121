import config from '../config';
import LocalStorageHelper from './local_storage';

const AssetHelper = {
	/**
	 * 
	 * @returns {String}
	 */
	getActionUrl() {
		return `${config.api}/app/assets`;
	},

	/**
	 * 
	 * @param {String} id 
	 * @returns {String}
	 */
	getDownloadUrl(id) {
		const token = LocalStorageHelper.getToken();
		return `${config.api}/app/assets/${token}/${id}`;
	},

	/**
	 * 
	 * @returns {String}
	 */
	getHeader() {
		const token = LocalStorageHelper.getToken();
		return { 'Authorization': `Bearer ${token}` };
	},

	/**
	 * 
	 * @returns {Integer} bytes
	 */
	getMaxSize() {
		return 5242880; // 5 MB
	},

	/**
	 * 
	 * @returns {String} message
	 */
	getMaxSizeMsg() {
		return 'exceeded max limitation of 5MB!';
	},

	/**
	 * 
	 * @returns {String}
	 */
	exportExpenses() {
		const token = LocalStorageHelper.getToken();
		return `${config.api}/app/expenses/export/${token}`;
	}
};

export default AssetHelper;
