import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

export function verifyAccountLinkEmail(t) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.VERIFY_CHANNEL_REQUEST,
			payload: { loadingVerify: true }
		});

		const response = await API.account_link.verifyAccountLinkEmail(t);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.VERIFY_CHANNEL_ERROR,
				payload: {
					meta: response.meta,
					loadingVerify: false
				}
			});
		}
		const { account_link: accountLink } = response.data;

		dispatch({
			type: ActionTypes.VERIFY_CHANNEL_SUCCESS,
			payload: {
				accountLink,
				loadingVerify: false,
				meta: response.meta
			}
		});
	};

}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_VERIFY_CHANNEL_PAGE
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_VERIFY_CHANNEL_META
		});
	};
}
