import _ from 'lodash';
import LocalStorageHelper from './local_storage';

const AuthHelper = {
	/**
	 * Check if current user is aithenticated
	 * @returns {Boolean} true if authenticated otherwise false
	 */
	isAuthenticated() {
		const token = LocalStorageHelper.getToken();
		return !_.isEmpty(token);
	}
};

export default AuthHelper;
