import { connect } from 'react-redux';
import LoginIntuitPage from './LoginIntuitPage';

const mapsStateToProps = state => ({

});

const mapsDispatchToProps = dispatch => ({

});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(LoginIntuitPage);
