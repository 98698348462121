import { connect } from 'react-redux';
import { getActiveSubscription } from '../../actions/ParentLayout';
import NavigationLayout from './NavigationLayout';

const mapStateToProps = state => ({
	parentLayoutProps: state.parentLayoutReducer
});

const mapDispatchToProps = dispatch => ({
	getActiveSubscription() {
		dispatch(getActiveSubscription());
	}
});

export default connect(
	mapStateToProps, 
	mapDispatchToProps
)(NavigationLayout);