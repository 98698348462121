import { useEffect, useState } from 'react';

const useUpdatePaymentMethodSuccess = (payment_method_success) => {
	const paymentMethodSuccess = localStorage.getItem(payment_method_success) === 'true' || '';
	const [successPaymentMethod, setPaymentMethodSuccess] = useState(paymentMethodSuccess);

	const updatePaymentMethodSuccess = (args) => {
		setPaymentMethodSuccess(args === 'yes');
	};

	useEffect(() => {
		localStorage.setItem(payment_method_success, successPaymentMethod);
	}, [payment_method_success, successPaymentMethod]);

	return [successPaymentMethod, updatePaymentMethodSuccess];
};

export default useUpdatePaymentMethodSuccess;
