import { push } from 'connected-react-router';
import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

export function getAccountLinks(options) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_ACCOUNT_LINKS_REQUEST,
			payload: { loading: true }
		});
		
		const response = await API.account_link.getAccountLinks({ ...options });

		if(response.meta.code !== 200){
			dispatch({
				type: ActionTypes.GET_ACCOUNT_LINKS_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { total, page, limit, count, account_links } = response.data;

		dispatch({
			type: ActionTypes.GET_ACCOUNT_LINKS_SUCCESS,
			payload: {
				total,
				page,
				limit,
				count,
				accountLinks: account_links,
				loading: false
			}
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_ACCOUNT_LINKS_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_ACCOUNT_LINKS_PAGE
		});
	};
}