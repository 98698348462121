import { connect } from 'react-redux';
import { onSubmit, resetMeta, resetPage, getPaymentOptions, submitPaymentOptions } from '../../actions/SettingPage';
import SettingPage from './SettingPage';

const mapsStateToProps = state => ({
	settingPageProps: state.settingPageReducer
});

const mapsDispatchToProps = dispatch => ({
	onSubmit(options) {
		dispatch(onSubmit(options));
	},
	getPaymentOptions() {
		dispatch(getPaymentOptions());
	},
	submitPaymentOptions(options) {
		dispatch(submitPaymentOptions(options));
	},
	resetMeta() {
		dispatch(resetMeta());
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(SettingPage);
