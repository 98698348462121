import { push } from 'connected-react-router';
import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

export function getUserByResetPasswordToken(token) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_USER_BY_RESET_PASSWORD_TOKEN_REQUEST,
			payload: { loading: true }
		});

		const response = await API.user.getUserByResetPasswordToken(token);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_USER_BY_RESET_PASSWORD_TOKEN_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { user } = response.data;

		dispatch({
			type: ActionTypes.GET_USER_BY_RESET_PASSWORD_TOKEN_SUCCESS,
			payload: {
				user,
				loading: false
			}
		});
	};
}

export function resetPassword(t, fields) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST,
			payload: { loadingSubmit: true }
		});

		const body = {
			...fields
		};

		const response = await API.user.resetPassword(t, body);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_RESET_PASSWORD_ERROR,
				payload: {
					meta: response.meta,
					loadingSubmit: false
				}
			});
			return;
		}


		dispatch({
			type: ActionTypes.SUBMIT_RESET_PASSWORD_SUCCESS,
			payload: {
				loadingSubmit: false,
				meta: response.meta
			}
		});

		// Navigate to next page
		dispatch(push('/login'));
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_RESET_PASSWORD_PAGE
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_RESET_PASSWORD_META
		});
	};
}