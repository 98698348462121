/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Row, Col, Form, Input, Typography, notification, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

// Helpers
import LocalStorageHelper from '../../helpers/local_storage';
import PasswordHelper from '../../helpers/password';

import Currencies from '../../constants/Currencies';

import Card from '../../components/Card';
import PixelHelper from '../../helpers/pixel';

const { Item } = Form;
const { Text, Title } = Typography;

const SettingPage = (props) => {
	// States
	const formRef = useRef();
	const formRef2 = useRef();
	const [showPasswordOne, setShowPasswordOne] = useState(false);
	const [showPasswordTwo, setShowPasswordTwo] = useState(false);
	const [showPasswordThree, setShowPasswordThree] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [disabled2, setDisabled2] = useState(true);
	const [match, setMatch] = useState(true);
	const [newPassword, setNewPassword] = useState(null);
	const [newPassValidationMsg, setNewPassValidationMsg] = useState(null);
	const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([null]);
	const [selectedSupportedCurrencies, setSelectedSupportedCurrencies] = useState([null]);

	// Props
	const {
		getPaymentOptions,
		submitPaymentOptions,
		onSubmit,
		settingPageProps,
		resetPage,
		resetMeta
	} = props;

	// Other Variables
	const user = LocalStorageHelper.getUser();
	const { meta, paymentOptions, loading, loadingSubmit, loadingSubmitTwo } = settingPageProps;
	const { name, email, login_method } = user || {};
	const { supported_currencies: supportedCurrencies, supported_payment_methods: supportedPaymentMethods } = paymentOptions || {};

	const payment_methods_options = [
		{
			label: 'Alipay',
			value: 'alipay'
		},
		{
			label: 'Bank Transfer',
			value: 'bank_transfer'
		},
		{
			label: 'Cash',
			value: 'cash'
		},
		{
			label: 'Credit/Debit Card',
			value: 'credit_debit_card'
		},
		{
			label: 'Octopus',
			value: 'octopus'
		},
		{
			label: 'Wechat Pay',
			value: 'wechat_pay'
		},
		{
			label: 'Cheque',
			value: 'cheque'
		},
		{
			label: 'Other',
			value: 'other'
		}
	];

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);
		getPaymentOptions();
		return () => resetPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (paymentOptions) {
			//	update state
			setSelectedPaymentMethods(paymentOptions.supported_payment_methods);
			setSelectedSupportedCurrencies(paymentOptions.supported_currencies);

			//update initial value
			formRef2.current.setFieldsValue({
				supported_payment_methods: paymentOptions.supported_payment_methods,
				supported_currencies: paymentOptions.supported_currencies
			});

			disableConfirmTwo();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentOptions]);

	useEffect(() => {
		if (meta) {
			const type = meta.code === 200 ? 'success' : 'error';
			if (!meta?.message?.toLowerCase().includes('password')) {
				notification[type]({
					message: meta.message,
					placement: 'bottomRight'
				});
				// Reset page meta
				resetMeta();
			}

			if (type === 'success') {
				setDisabled(true);
				setMatch(true);
				setNewPassValidationMsg(null);
				setNewPassword(null);
				formRef.current.setFieldsValue({
					current_password: undefined,
					new_password: undefined,
					confirm_password: undefined
				});
				setShowPasswordOne(false);
				setShowPasswordTwo(false);
				setShowPasswordThree(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);

	useEffect(() => {
		if (newPassword && newPassword.length < 8) {
			setNewPassValidationMsg('Password must be atleast 8 characters.');
		} else if (newPassword && !PasswordHelper.validatePassword(newPassword)) {
			setNewPassValidationMsg('The password must contain at least one uppercase letter, one lowercase letter, one number, and one special character from the following set: _!@#$%^&*(),.?":{}|<>.');
		} else {
			setNewPassValidationMsg(null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newPassword]);

	// ---------------------------------- Start of Functions ---------------------------------- //
	const toggleShowPassword = (type) => {
		if (type === 'current') {
			setShowPasswordOne(prevShowPassword => !prevShowPassword);
		} else if (type === 'new') {
			setShowPasswordTwo(prevShowPassword => !prevShowPassword);
		} else if (type === 'confirm') {
			setShowPasswordThree(prevShowPassword => !prevShowPassword);
		}
	};

	const checkFormValues = () => {
		const currentPasswordValue = formRef.current.getFieldValue('current_password');
		const newPasswordValue = formRef.current.getFieldValue('new_password');
		const confirmNewPasswordValue = formRef.current.getFieldValue('confirm_password');

		const isCurrentPasswordProvided = currentPasswordValue !== undefined && currentPasswordValue !== '';

		if (
			formRef.current.getFieldValue('name') &&
			(!isCurrentPasswordProvided || (isCurrentPasswordProvided && newPasswordValue && confirmNewPasswordValue)) &&
			(newPasswordValue === confirmNewPasswordValue)
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};


	const handleOnSubmit = async (values) => {
		const { name, confirm_password, current_password, new_password } = values;
		const fields = {
			name,
			current_password,
			confirm_password,
			new_password
		};
		onSubmit(fields);
	};

	const handleChangeSelectedPaymentMethods = (index, value) => {
		const newSelectedPaymentMethods = [...selectedPaymentMethods];
		newSelectedPaymentMethods[index] = value;
		setSelectedPaymentMethods(newSelectedPaymentMethods);
	};

	const handleRemoveSelectedPaymentMethods = (index) => {
		const newSelectedPaymentMethods = [...selectedPaymentMethods];
		newSelectedPaymentMethods.splice(index, 1);
		setSelectedPaymentMethods(newSelectedPaymentMethods);
	};

	const handleChangeSelectedCurrencies = (index, value) => {
		const newSelectedSupportedCurrencies = [...selectedSupportedCurrencies];
		newSelectedSupportedCurrencies[index] = value;
		setSelectedSupportedCurrencies(newSelectedSupportedCurrencies);
	};

	const handleRemoveSelectedCurrencies = (index) => {
		const newSelectedSupportedCurrencies = [...selectedSupportedCurrencies];
		newSelectedSupportedCurrencies.splice(index, 1);
		setSelectedSupportedCurrencies(newSelectedSupportedCurrencies);
	};

	const handleValidationMsg = (fieldName) => {
		if (meta && meta?.code !== 200 && fieldName === 'current_password') {
			return (
				<Text
					type="danger"
					style={{ fontSize: 16 }}
				>
					{meta.message === 'Current password is incorrect.' ? meta?.message : ''}
				</Text>
			);
		}

		if (meta && meta?.code !== 200 && fieldName !== 'current_password') {
			return (
				<Text
					type="danger"
					style={{ fontSize: 16 }}
				>
					{meta.message !== 'Current password is incorrect.' ? meta?.message : ''}
				</Text>
			);
		}

		if (fieldName === 'new_password') {
			if (newPassValidationMsg) {
				return (
					<Text
						type="danger"
						style={{ fontSize: 16 }}
					>
						{newPassValidationMsg}
					</Text>);
			}
		}
		return null;
	};

	function arraysEqual(arr1, arr2) {
		if (arr1.length !== arr2.length) return false;

		return arr1.every(value => arr2.includes(value));
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const disableConfirmTwo = () => {
		const paymentMethodsHasNullOrUndefined = formRef2.current && formRef2.current.getFieldValue('supported_payment_methods').findIndex(value => value === null || value === undefined);
		const supportedCurrenciesHasNullOrUndefined = formRef2.current && formRef2.current.getFieldValue('supported_currencies').findIndex(value => value === null || value === undefined);
		const checkIfSameValues = paymentOptions ? formRef2.current && ((arraysEqual(formRef2.current.getFieldValue('supported_currencies'), supportedCurrencies)) &&
			(arraysEqual(formRef2.current.getFieldValue('supported_payment_methods'), supportedPaymentMethods))) : false;

		const disabled2 = checkIfSameValues || (paymentMethodsHasNullOrUndefined !== -1 || supportedCurrenciesHasNullOrUndefined !== -1) ||
			(formRef2.current && formRef2.current.getFieldValue('supported_payment_methods').length <= 0 || formRef2.current && formRef2.current.getFieldValue('supported_currencies').length <= 0);

		setDisabled2(disabled2);
	};

	// ---------------------------------- End of Functions ---------------------------------- //
	return (
		<div>
			<Row>
				<Col
					span={24}
					style={{ marginBottom: 15 }}
				>
					<Card
						title="Account Settings"
						loading={loading}
						style={{ width: '100%' }}
					>
						<Row>
							<Col
								xxl={{ span: 9, offset: 2 }}
								lg={{ span: 9, offset: 2 }}
								md={{ span: 9, offset: 2 }}
								sm={{ span: 24 }}
								xs={{ span: 24 }}
							>
								<Form
									style={{ width: '100%' }}
									ref={formRef}
									layout="vertical"
									initialValues={{
										name,
										email
									}}
									onFinish={handleOnSubmit}
								>
									<Item
										name="name"
										label="Name"
										rules={[
											{ message: 'Name is required', required: true }
										]}
										onChange={checkFormValues}
									>
										<Input />
									</Item>
									<Item
										name="email"
										label="Email"
										rules={[
											{ message: 'Email is required', required: true },
											{ type: 'email', message: 'Email is not valid' }
										]}
										onChange={checkFormValues}
									>
										<Input disabled />
									</Item>
									<Item
										name="current_password"
										label="Current Password"
										rules={[
											{ message: 'Current Password is required', required: false }
										]}
										onChange={checkFormValues}
										help={handleValidationMsg('current_password')}
									>
										<Input.Password
											placeholder="Enter Current Password"
											disabled={login_method === 'google' || loadingSubmit || !user}
											visibilityToggle={{ visible: showPasswordOne, onVisibleChange: () => toggleShowPassword('current') }}
										/>
									</Item>
									<Item
										name="new_password"
										label="New Password"
										rules={[
											{
												required: formRef.current?.getFieldValue('current_password'),
												message: 'New Password is required'
											}
										]}
										help={handleValidationMsg('new_password')}
									>
										<Input.Password
											placeholder="Enter New Password"
											onChange={(e) => {
												let confirmPassword = formRef.current.getFieldValue('confirm_password');
												setNewPassword(e.target.value);
												if (e.target.value) {
													if (confirmPassword) {
														if (e.target.value && e.target.value !== confirmPassword) {
															setMatch(false);
														} else {
															setMatch(true);
														}
													}
												} else {
													setMatch(true);
												}
												checkFormValues();
											}}
											disabled={login_method === 'google' || loadingSubmit || !user}
											visibilityToggle={{ visible: showPasswordTwo, onVisibleChange: () => toggleShowPassword('new') }}
										/>
									</Item>
									<Item
										name="confirm_password"
										label="Confirm Password"
										rules={[
											{
												required: formRef.current?.getFieldValue('current_password'),
												message: 'Confirm Password is required'
											}
										]}
										help={!match ? <Text type="danger">The password did not match!</Text> : handleValidationMsg(null)}
									>
										<Input.Password
											placeholder="Enter Confirm Password"
											onChange={(e) => {
												let newPassword = formRef.current.getFieldValue('new_password');
												if (newPassword) {
													if (e.target.value && e.target.value !== newPassword) {
														setMatch(false);
													} else {
														setMatch(true);
													}
												}
												checkFormValues();
											}}
											disabled={login_method === 'google' || !newPassword || newPassValidationMsg || loadingSubmit || !user}
											visibilityToggle={{ visible: showPasswordThree, onVisibleChange: () => toggleShowPassword('confirm') }}
											onPaste={(e) => e.preventDefault()}
										/>
									</Item>
									<Item>
										<Row justify="end">
											<Button
												type="primary"
												htmlType="submit"
												style={{
													width: 100,
													marginTop: 10
												}}
												disabled={disabled || newPassValidationMsg || loadingSubmit || !user}
												loading={loadingSubmit}
											>
												Confirm
											</Button>
										</Row>
									</Item>
								</Form>
							</Col>
							<Col
								xxl={{ span: 9, offset: 2 }}
								lg={{ span: 9, offset: 2 }}
								md={{ span: 9, offset: 2 }}
								sm={{ span: 24 }}
								xs={{ span: 24 }}
							>

								<Form
									style={{ width: '100%' }}
									ref={formRef2}
									layout="vertical"
									initialValues={{
										supported_payment_methods: paymentOptions && selectedPaymentMethods.length > 0 ? selectedPaymentMethods : [null],
										supported_currencies: paymentOptions && selectedSupportedCurrencies.length > 0 ? selectedSupportedCurrencies : [null]
									}}
									onFinish={(values) => {
										submitPaymentOptions(values);
									}}
								>
									<p style={{ fontSize: '16px', fontWeight: 510, marginBottom: 5 }}>Supported Payment Methods</p>
									<Card style={{ marginBottom: 20 }}>
										<Form.List
											name="supported_payment_methods"
										>
											{(supported_payment_methods, { add, remove }) => (
												<>
													<p style={{ marginBottom: 3, fontSize: 14, fontWeight: 400 }}><span style={{ color: 'red' }}>*</span> Payment Methods</p>
													{supported_payment_methods.map((field, index) => {
														return (
															<div
																key={field.name}
																style={{ display: 'flex', flexDirection: 'row', gap: 5, marginBottom: 5 }}
															>
																<Form.Item
																	{...field}
																	// {...index === 0 && { label: 'Payment Methods' }}
																	rules={[
																		{ required: true, message: 'Payment Methods is required.' }
																	]}
																	style={{ width: '100%', margin: '2px 0px' }}
																>
																	<Select
																		onChange={val => {
																			handleChangeSelectedPaymentMethods(index, val);
																			disableConfirmTwo();
																		}}
																		placeholder="Select Payment Method"
																	>
																		{
																			payment_methods_options.length > 0 && payment_methods_options.map((option, index) => (
																				<Select.Option
																					key={index}
																					value={option.value}
																					disabled={formRef2.current && formRef2.current?.getFieldValue('supported_payment_methods').includes(option.value)}
																				>{option.label}</Select.Option>
																			))
																		}
																	</Select>
																</Form.Item>
																<a
																	onClick={() => {
																		handleRemoveSelectedPaymentMethods(index);
																		remove(field.name);
																		disableConfirmTwo();
																	}}
																	style={{ marginTop: index === 0 ? 6 : 6 }}
																><CloseCircleOutlined style={{ color: '#E90000' }} /></a>
															</div>
														);
													})}
													<Button
														style={{ color: '#FF8A00', borderBlockColor: '#FF8A00' }}
														type="dashed"
														onClick={() => {
															add();
															disableConfirmTwo();
														}}
														block
														{...supported_payment_methods.length >= payment_methods_options.length && { disabled: true }}
													>
														+ Add Payment Method
													</Button>
												</>
											)}
										</Form.List>
									</Card>

									<p style={{ fontSize: '16px', fontWeight: 510, marginBottom: 5 }}>Supported Currencies</p>
									<Card style={{ marginBottom: 15 }}>
										<Form.List
											name="supported_currencies"
										>
											{(supported_currencies, { add, remove }) => (
												<>
													<p style={{ marginBottom: 3, fontSize: 14, fontWeight: 400 }}><span style={{ color: 'red' }}>*</span> Currencies</p>
													{supported_currencies.map((field, index) => {
														return (
															<div
																key={field.name}
																style={{ display: 'flex', flexDirection: 'row', gap: 5, marginBottom: 5 }}
															>
																<Form.Item
																	{...field}
																	// {...index === 0 && { label: 'Currencies' }}
																	rules={[
																		{ required: true, message: 'Currencies is required.' }
																	]}
																	style={{ width: '100%', margin: '2px 0px' }}
																>
																	<Select
																		onChange={val => {
																			handleChangeSelectedCurrencies(index, val);
																			disableConfirmTwo();
																		}}
																		placeholder="Select Currency"
																	>
																		{
																			Currencies.length > 0 && Currencies.map((option, index) => (
																				<Select.Option
																					key={index}
																					value={option.code.toLowerCase()}
																					disabled={formRef2.current && formRef2.current.getFieldValue('supported_currencies').includes(option.code.toLowerCase())}
																				>{`${option.code} (${option.symbol})`}</Select.Option>
																			))
																		}
																	</Select>
																</Form.Item>
																<a
																	onClick={() => {
																		handleRemoveSelectedCurrencies(index);
																		remove(field.name);
																		disableConfirmTwo();
																	}}
																	style={{ marginTop: index === 0 ? 6 : 6 }}
																><CloseCircleOutlined style={{ color: '#E90000' }} /></a>
															</div>
														);
													})}
													<Button
														style={{ color: '#FF8A00', borderBlockColor: '#FF8A00' }}
														type="dashed"
														onClick={() => {
															add();
															disableConfirmTwo();
														}}
														block
														{...supported_currencies.length >= Currencies.length && { disabled: true }}
													>
														+ Add Currency
													</Button>
												</>
											)}
										</Form.List>
									</Card>
									<Item>
										<Row justify="end">
											<Button
												type="primary"
												htmlType="submit"
												style={{
													width: 100,
													marginTop: 10
												}}
												disabled={disabled2 || !user || loadingSubmitTwo}
												loading={loadingSubmitTwo}
											>
												Confirm
											</Button>
										</Row>
									</Item>
								</Form>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div >
	);
};

export default SettingPage;
