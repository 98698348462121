import { connect } from 'react-redux';
import { onSubmit, resetMeta } from '../../../actions/IntuitConnectionDetailPage/IntuitConnectionBasicPage';

import IntuitConnectionBasicPage from './IntuitConnectionBasicPage';

const mapsStateToProps = state => ({
	intuitConnectionBasicPageProps: state.intuitConnectionDetailPage.intuitConnectionBasicPageReducer
});

const mapsDispatchToProps = dispatch => ({
	onSubmit(options) {
		dispatch(onSubmit(options));
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(IntuitConnectionBasicPage);
