import { connect } from 'react-redux';
import { getInvoices, resetMeta, resetPage } from '../../actions/SubscriptionReceiptPage';
import SubscriptionReceiptPage from './SubscriptionReceiptPage';

const mapsStateToProps = state => ({
	subscriptionReceiptPageProps: state.subscriptionReceiptPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getInvoices(options) {
		dispatch(getInvoices(options));
	},
	resetMeta() {
		dispatch(resetMeta());
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(SubscriptionReceiptPage);
