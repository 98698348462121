import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	loadingSubmit: false,
	user: null
};

export default function createAccountPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SUBMIT_CREATE_ACCOUNT_REQUEST:
		case ActionTypes.SUBMIT_CREATE_ACCOUNT_ERROR:
		case ActionTypes.SUBMIT_CREATE_ACCOUNT_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_CREATE_ACCOUNT_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_CREATE_ACCOUNT_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
