import { connect } from 'react-redux';
import { getIntuitConnectionById, resetPage } from '../../actions/IntuitConnectionDetailPage/IntuitConnectionBasicPage';

import IntuitConnectionDetailPage from './IntuitConnectionDetailPage';

const mapsStateToProps = state => ({
	intuitConnectionDetailPageProps: state.intuitConnectionDetailPage.intuitConnectionBasicPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getIntuitConnectionById(id) {
		dispatch(getIntuitConnectionById(id));
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(IntuitConnectionDetailPage);
