import React, { useEffect } from 'react';
import UrlParse from 'url-parse';
import { Row } from 'antd';

import useNotification from '../../../helpers/hooks/useNotification';

// Components
import Card from '../../../components/Card';
// import AccountLinkBasicForm from './components/AccountLinkBasicForm';
import IntuitConnectionBasicForm from './components/IntuitConnectionBasicForm';

const IntuitConnectionBasicPage = (props) => {
	const {
		location,
		intuitConnectionBasicPageProps,
		getIntuitConnectionById,
		resetMeta
	} = props;
	const { loading, meta, intuitConnection } = intuitConnectionBasicPageProps;
	const { query } = UrlParse(location.search, true);

	// Handle notifications
	useNotification(meta, resetMeta);

	useEffect(() => {
		if(meta && meta.code !== 200){
			query.id && getIntuitConnectionById(query.id);
		}
	}, [getIntuitConnectionById, meta, query.id]);

	return (
		<Card
			title="Intuit Connection Details"
			loading={loading}
		>
			<Row justify="center">
				<IntuitConnectionBasicForm
					{...props}
					intuitConnection={intuitConnection}
				/>
			</Row>
		</Card>
	);
};

export default IntuitConnectionBasicPage;
