import React, { Component } from 'react';
import Loading from '../../components/Loading';

class ConnectToQuickBookCallBackPage extends Component {
	componentDidMount() {
		this.props.connectToQuickBooks();
	}
	render() {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
				<Loading />
			</div>
		);
	}
}

// Use redux's compose to use multiple
// HOC(Higher Order Component) wrappers
export default ConnectToQuickBookCallBackPage;
