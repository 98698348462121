import request from './request';

export default {
	async login(body) {
		const response = await request.post('/app/auth/login', body);
		return response;
	},
	async loginWithGoogle(body) {
		const response = await request.post('/app/auth/login/google', body);
		return response;
	},
	async loginWithXero(body) {
		const response = await request.post('/app/auth/login/xero', body);
		return response;
	},
	async loginWithIntuit(body) {
		const response = await request.post('/app/auth/login/intuit', body);
		return response;
	},
	async logout() {
		const res = await request.post('/app/auth/logout');
		return res;
	}
};
