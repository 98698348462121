import React, { Component } from 'react';

class CallbackIntuitPage extends Component {
	componentDidMount() {
		this.props.intuitAuthorize();
	}
	render() {
		return (
			<div>Redirecting...</div>
		);
	}
}

// Use redux's compose to use multiple
// HOC(Higher Order Component) wrappers
export default CallbackIntuitPage;
