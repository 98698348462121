import TagManager from 'react-gtm-module';

const GRM_ID = 'G-THBDXM5Y9M';

const GtmHelper = {
	init() {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			const tagManagerArgs = {
				gtmId: GRM_ID
			};

			TagManager.initialize(tagManagerArgs);
		} catch (e) {
		}
	},
	dataLayer({ userId, page }) {
		if (process.env.NODE_ENV !== 'production') {
			return;
		}
		try {
			const tagManagerArgs = {
				dataLayer: {
					...userId && { userId },
					page
				},
				dataLayerName: 'PageDataLayer'
			};
			TagManager.dataLayer(tagManagerArgs);
		} catch (e) {
		}
	}
};

export default GtmHelper;

