import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	loading: false,
	loadingSubmit: false,
	loadingResetPassword: false
};

export default function loginPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.LOGIN_REQUEST:
		case ActionTypes.LOGIN_ERROR:
		case ActionTypes.LOGIN_SUCCESS:
		case ActionTypes.LOGIN_WITH_GOOGLE_REQUEST:
		case ActionTypes.LOGIN_WITH_GOOGLE_ERROR:
		case ActionTypes.LOGIN_WITH_GOOGLE_SUCCESS:
		case ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_REQUEST:
		case ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_ERROR:
		case ActionTypes.SUBMIT_RESET_PASSWORD_REQUEST_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_LOGIN_PAGE_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_LOGIN_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
