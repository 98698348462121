import { push } from 'connected-react-router';
import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';

export function xeroAuthorize() {
	return async dispatch => {
		const url = window.location.href;

		const response = await API.auth.loginWithXero({ url });

		if (response.meta.code !== 200) {
			dispatch(push('/login'));
			return;
		}

		const { auth, user } = response.data;

		// store response
		LocalStorageHelper.setUser(user);
		LocalStorageHelper.setToken(auth.token);

		if (!user.subscription) {
			// Navigate to link page
			dispatch(push('/how-it-works'));
			return;
		}

		// Navigate to next page
		dispatch(push('/expenses'));
	};
}
