import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

// getInvoices
export function getInvoices(options) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_SUBSCRIPTION_RECEIPTS_REQUEST,
			payload: { loading: true }
		});

		const response = await API.invoice.getInvoices({ ...options });

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_SUBSCRIPTION_RECEIPTS_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { total, page, limit, count, invoices } = response.data;

		dispatch({
			type: ActionTypes.GET_SUBSCRIPTION_RECEIPTS_SUCCESS,
			payload: {
				total,
				page,
				limit,
				count,
				invoices,
				loading: false
			}
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_SUBSCRIPTION_RECEIPTS_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_SUBSCRIPTION_RECEIPTS_PAGE
		});
	};
}