import React, { Component } from 'react';
import config from '../../config';

class CallbackXeroPage extends Component {
	xeroAuthUrl = () => {
		return `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${config.xeroAuth.clientId}&redirect_uri=${encodeURIComponent(config.xeroAuth.redirectUri)}&scope=${encodeURIComponent(config.xeroAuth.scopes)}&state=123&prompt=login`;
	};
	componentDidMount() {
		window.location.href = this.xeroAuthUrl();
	}
	render() {
		return (
			<div>Redirecting...</div>
		);
	}
}

// Use redux's compose to use multiple
// HOC(Higher Order Component) wrappers
export default CallbackXeroPage;
