import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	loadingSubmit: false,
	accountLinks: []
};

export default function connectWhatsappPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SUBMIT_WHATSAPP_ACCOUNT_LINK_REQUEST:
		case ActionTypes.SUBMIT_WHATSAPP_ACCOUNT_LINK_ERROR:
		case ActionTypes.SUBMIT_WHATSAPP_ACCOUNT_LINK_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_WHATSAPP_ACCOUNT_LINK_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_WHATSAPP_ACCOUNT_LINK_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
