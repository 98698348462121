import { connect } from 'react-redux';
import { login, loginWithGoogle, requestResetPasswordLink, resetPage, resetMeta } from '../../actions/LoginPage';
import LoginPage from './LoginPage';

const mapsStateToProps = state => ({
	loginPageProps: state.loginPageReducer
});

const mapsDispatchToProps = dispatch => ({
	login(options) {
		dispatch(login(options));
	},
	loginWithGoogle(options) {
		dispatch(loginWithGoogle(options));
	},
	requestResetPasswordLink(email) {
		dispatch(requestResetPasswordLink(email));
	},
	resetPage() {
		dispatch(resetPage());
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(LoginPage);
