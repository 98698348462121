/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, ConfigProvider, Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import LocalStorageHelper from '../../helpers/local_storage';
import nekoLogo from '../../images/logo.svg';
import PixelHelper from '../../helpers/pixel';
import small_logo_face from '../../images/small_logo_face.png';
import bgvector from '../../images/pricing_vector.svg';

const { Title, Text } = Typography;

const PricingPage = (props) => {
	// States
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [modalState, setModalState] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(null);

	// Other Variable
	const pricing = [
		{
			label: 'Basic Plan',
			sub_label: null,
			description: 'Experience a 14 days free trial! Process up to 50 receipts each month with the flexibility to cancel anytime. Start enjoying the benefits today.',
			price: 2.99
		},
		{
			label: 'Basic Plan',
			sub_label: 'Recommended',
			description: 'Experience a 14 days free trial at no cost.',
			price: 2.99
		}
	];
	const user = LocalStorageHelper.getUser();
	const { recurring, subscription, subscription_status } = user;

	// Props
	const { proceedCheckout, resetPage, history } = props;

	// Functions
	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	const handlePricingClick = (index) => {
		if (index === 0) {
			proceedCheckout({ trial: 'yes', email: user.email, isUpdatePaymentMethod: 'no' });
			return;
		}

		proceedCheckout({ trial: 'no', email: user.email, isUpdatePaymentMethod: 'no' });
	};

	const handleButtonLabel = (index) => {
		if(index === 0) {
			return 'Start Free Trial';
		} else {
			return 'Subscribe';
		}
	};

	const confirmationModal = () => {
		return (
			<Modal
				open={modalState}
				footer={null}
				closable={false}
				centered
				onCancel={() => {
					setModalState(false);
					setSelectedIndex(null);
				}}
				width={320}
			>
				<Row
					justify="center"
					style={{ marginBottom: '10px', marginTop: 15 }}
				>
					<img
						src={small_logo_face}
						alt="logo"
					/>
				</Row>
				<Row
					justify="center"
					style={{ marginBottom: 25, marginTop: 25 }}
				>
					<Text style={{ fontSize: 16, fontWeight: 'bold', color: '#344E41', padding: 0, margin: '0px 0px 5px 0px', textAlign: 'center' }}>Proceed with card information?</Text>
					<Text style={{ fontSize: 14, padding: 0, margin: 0, textAlign: 'center' }}>Entering your card information will make it much easier for you later on.</Text>
				</Row>
				<Row>
					<Button
						type="primary"
						block
						style={{ fontSize: 14, height: 40 }}
						onClick={() => selectedIndex !== null ? handlePricingClick(selectedIndex) : alert('Please select a pricing')}
					>
            				Enter Card Information
					</Button>
				</Row>
				<Row>
					<Button
						block
						style={{ marginTop: 15, border: 'none', outline: 'none', fontSize: 14, height: 40 }}
						onClick={() => {
							setModalState(false);
							setSelectedIndex(null);
						}}
					>
            				Skip
					</Button>
				</Row>
				<CloseOutlined
					onClick={() => {
						setModalState(false);
						setSelectedIndex(null);
					}}
					style={{
						position: 'absolute',
						top: '15px',
						right: '15px',
						fontSize: '12px',
						cursor: 'pointer',
						opacity: .5,
						backgroundColor: 'red'
					}}
					// onClick={onClose}
				/>
			</Modal>
		);
	};

	// Use Effects
	useEffect(() => {
		if (!user) {
			history.push('/create');
			return;
		}

		// Redirect if user already subscribed
		if (user.subscription && user.subscription_status !== 'canceled') {
			history.push('/billing');
			return;
		}

		PixelHelper.pageView(window.location.pathname);

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			resetPage();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ConfigProvider
			theme={{
				token: {
					fontSize: 19,
					fontSizeHeading1: 30,
					colorTextHeading: '#344E41'
				},
				components: {
					Form: {
						labelFontSize: 19
					},
					Modal: {
						// borderRadiusLG: 50
					}
				}
			}}
		>
			{confirmationModal()}
			<Row style={{ backgroundColor: '#F5F5F5' }}>
				<Col
					flex="515px"
					style={{ display: windowWidth < 980 ? 'none' : '', height: '100vh', backgroundColor: '#FFFFFF', position: 'sticky', top: 0, boxShadow: '0px 4px 10px 0px #0000001A', borderRadius: '0px 50px 50px 0px' }}
				>
					<Row justify="center">
						<div style={{ padding: '78px 74px 0px 100px' }}>
							<img
								src={nekoLogo}
								alt="nekoexpense-logo"
								style={{ width: '100%', marginBottom: windowWidth < 510 ? 30 : '19vh' }}
							/>
							<Title
								level={1}
								style={{ margin: 0, marginBottom: 19 }}
							>
								Pick The Best Plan
							</Title>
							<Text style={{ fontSize: 16 }}>
								Take your desired plan to get access to our content easily,
								we like to offer special license offer to our users.
							</Text>
						</div>
						{
							recurring && subscription && subscription_status &&
							<Button
								style={{ width: '100%', height: 56, borderRadius: 10, margin: '40px 74px 0 100px' }}
								onClick={() => history.push('/billing')}
							>
							Cancel
							</Button>
						}
					</Row>
				</Col>
				<Col
					flex="auto"
					style={windowWidth < 980 ? { padding: windowWidth < 350 ? 20 : 40, height: '100%' } : { padding: '40px 0px' }}
				>
					{
						windowWidth < 980 ?
							<Row justify="center">
								<div style={{ width: '100%', maxWidth: 400, marginBottom: 40 }}>
									<img
										src={nekoLogo}
										alt="nekoexpense-logo"
										style={{ width: '100%', marginBottom: 30 }}
									/>
									<Title
										level={1}
										style={{ margin: 0, marginBottom: 19 }}
									>
										Pick The Best Plan
									</Title>
									<Text style={{ fontSize: 16 }}>
										Take your desired plan to get access to our content easily,
										we like to offer special license offer to our users.
									</Text>
								</div>
							</Row> : <></>
					}
					<div style={{ height: '100%', gap: 51, display: 'flex', flexDirection: windowWidth < 1500 ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
						{
							pricing.map((data, index) => {
								if (!user.recurring && index === 1) {
									return;
								}
								if (user.recurring && index === 0) {
									return;
								}
								return (
									<div
										key={index}
										style={{
											maxWidth: 400,
											width: '100%',
											background: '#FFFFFF',
											borderRadius: 50,
											boxShadow: '0px 4px 10px 0px #0000001A',
											padding: windowWidth < 460 ? 30 : '50px 49px 48px 50px',
											zIndex: 1
										}}
									>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<Text style={{ color: '#344E41' , fontSize: 20, fontWeight: 500, marginBottom: 24 }}>
												{data.sub_label}
											</Text>
											<Text style={{ color: '#344E41' , fontSize: 32, fontWeight: 600, marginBottom: 16 }}>
												{data.label}
											</Text>
											<Text style={{ color: '#344E41', fontSize: 16, fontWeight: 400, marginBottom: 40 }}>
												{data.description}
											</Text>
										</div>
										<div>
											<Text style={{ color: '#344E41', fontSize: 38, fontWeight: 700 }}>
												${data.price}
											</Text>
											{ !user.recurring && <Text style={{ color: '#344E41', fontSize: 25 }}>/month</Text> }
											<Button
												block
												type={'primary'}
												onClick={() => {
													// setModalState(true);
													// setSelectedIndex(index);

													handlePricingClick(index);
												}}
												style={{ height: 56, borderRadius: 10, marginTop: 20 }}
											>
												{handleButtonLabel(index)}
											</Button>
										</div>
									</div>
								);
							})
						}
						<img
							src={bgvector}
							alt="vector"
							style={{ position: 'absolute', bottom: 40, right: 100, width: '100%', maxWidth: 320, height: 'auto' }}
						/>
					</div>
				</Col>
			</Row>
		</ConfigProvider>
	);
};

export default PricingPage;
