import { push } from 'connected-react-router';
import _ from 'lodash';

import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';


export function createWhatsappAccountLink(fields) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.SUBMIT_WHATSAPP_ACCOUNT_LINK_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const body = {
			account_link: {
				...fields,
				channel: 'whatsapp'
			}
		};

		const response = await API.account_link.createWhatsappAccountLink(body);
	
		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_WHATSAPP_ACCOUNT_LINK_ERROR,
				payload: {
					loadingSubmit: false,
					meta: response.meta
				}
			});
			return;
		}

		dispatch({
			type: ActionTypes.SUBMIT_WHATSAPP_ACCOUNT_LINK_SUCCESS,
			payload: {
				loadingSubmit: false,
				meta: response.meta
			}
		});
		
		// Navigate to next page
		dispatch(push('/pricing'));
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_WHATSAPP_ACCOUNT_LINK_PAGE
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_WHATSAPP_ACCOUNT_LINK_META
		});
	};
}
