const PasswordHelper = {
	validatePassword: (password) => {
		const uppercaseRegex = /[A-Z]/;
		const lowercaseRegex = /[a-z]/;
		const specialCharRegex = /[_!@#$%^&*(),.?":{}|<>]/;
		const numberRegex = /[0-9]/;

		if (
			uppercaseRegex.test(password)
			&& lowercaseRegex.test(password)
			&& specialCharRegex.test(password)
			&& numberRegex.test(password)
		) {
			return true;
		}

		return false;
	}
};

export default PasswordHelper;
