import request from './request';

export default {
	async getIntuitConnections(query) {
		const response = await request.get('/app/intuit-connections', query);
		return response;
	},
	async getIntuitConnectionById(id) {
		const response = await request.get(`/app/intuit-connections/${id}`);
		return response;
	},
	async updateIntuitConnectionById(id, body) {
		const response = await request.patch(`/app/intuit-connections/${id}`, body);
		return response;
	},
	async disconnectFromQuickBooks(query) {
		const response = await request.post('/app/intuit-connections/disconnect', query);
		return response;
	},
	async connectToQuickBooks(body) {
		const response = await request.post('/app/intuit-connections/connect', body);
		return response;
	}
};
