import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Row, Col, Table, Button, Modal } from 'antd';
import UrlParse from 'url-parse';
import moment from 'moment';

// Components
import Loading from '../../components/Loading';
import Card from '../../components/Card';

// Helpers
import GeneralHelper from '../../helpers/general';

// Common Enums
import CommonEnum from '../../constants/CommonEnum';
import config from '../../config';
import PixelHelper from '../../helpers/pixel';

const IntuitConnectionPage = (props) => {
	const { getIntuitConnections, resetPage, intuitConnectionPageProps, history, location, disconnectFromQuickBooks } = props;
	const { total, page, limit, intuitConnections, loading } = intuitConnectionPageProps;
	const { query } = UrlParse(location.search, true);

	const [modalVisible, setModalVisible] = useState(false);

	const connectToQuickBookUrl = () => {
		return `https://appcenter.intuit.com/app/connect/oauth2?response_type=code&client_id=${config.quickbookAuth.clientId}&redirect_uri=${encodeURIComponent(config.quickbookAuth.connectToQuickBooksRedirectUri)}&scope=${encodeURIComponent(config.quickbookAuth.scopes)}&state=123&prompt=select_account`;
	};

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		getIntuitConnections(query);

		return () => resetPage();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			render: () => 'Intuit'
		},
		{
			title: 'Connected',
			dataIndex: 'token_valid',
			key: 'token_valid',
			render: (text, record) => record.token_valid ? 'Yes' : 'No'
		},
		{
			title: 'Last Synced At',
			dataIndex: 'last_synced_at',
			key: 'last_synced_at',
			render: (text, record) => record.last_synced_at ? moment(record.last_synced_at).format('YYYY-MM-DD HH:mm:ss') : '-'
		},
		{
			title: 'Sync',
			dataIndex: 'enable_sync',
			key: 'enable_sync',
			render: (text, record) => record.enable_sync ? 'Enabled' : 'Disabled'
		},
		{
			title: 'Action',
			dataIndex: 'action',
			width: '10%',
			render: (text, record) => (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<a
					onClick={e => {
						e.preventDefault();
						history.push(`/intuit-connections/id?id=${record._id}`);
					}}
				>
					Edit
				</a>
			)
		}
	];
	
	const handleDisconnect = () => {
		disconnectFromQuickBooks();
		setModalVisible(false);
	 };

	return (
		<Card
			title="Intuit Connections"
			loading={loading}
		>	{!_.isEmpty(intuitConnections) &&
			<Row 
				justify="end"
				style={{ marginBottom: 15 }}
			>
				<Button
					type="primary"
					size="middle"
					style={{ color: '#ffffff',  backgroundColor: '#2ba01d' }}
					onClick={() => {
						setModalVisible(true);
					}}
				>
                    Disconnect From QuickBooks
				</Button>
				<Modal
					title="Confirm Disconnection"
					open={modalVisible}
					onOk={handleDisconnect}
					onCancel={() => setModalVisible(false)}
					okText="Disconnect"
					cancelText="Cancel"
				>
					<p>Are you sure you want to disconnect from QuickBooks?</p>
				</Modal>
			</Row> 
			}
			{_.isEmpty(intuitConnections) &&
			<Row 
				justify="end"
				style={{ marginBottom: 15 }}
			>
				<Button
					type="primary"
					size="middle"
					style={{ color: '#ffffff',  backgroundColor: '#2ba01d' }}
					onClick={() => {
						window.location.href = connectToQuickBookUrl();
					}}
				>
					Connect to QuickBooks
				</Button>
			</Row>
			}
			<div className="main-content">
				<Row>
					<Col flex="auto">
						{loading ?
							<Loading /> :
							<Table
								scroll={ { x: true } }
								columns={columns}
								dataSource={intuitConnections.map(intuitConnection => (
									{
										...intuitConnection,
										key: intuitConnection._id
									}
								))}
								pagination={{
									current: page,
									position: ['bottomCenter'],
									onChange: (current, pageSize) => {
										const queryObj = { ...query, page: current, limit: pageSize };
										const queryString = GeneralHelper.objectToQueryString(queryObj);
										getIntuitConnections(queryObj);
										history.push(`${location.pathname}?${queryString}`);
									},
									total,
									pageSize: limit
								}}
							/>
						}
					</Col>
				</Row>
			</div>
		</Card>
	);
};

export default IntuitConnectionPage;
