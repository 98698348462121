/* LoginPage */
export const RESET_LOGIN_PAGE = 'RESET_LOGIN_PAGE';
export const RESET_LOGIN_PAGE_META = 'RESET_LOGIN_PAGE_META';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGIN_WITH_GOOGLE_REQUEST = 'LOGIN_WITH_GOOGLE_REQUEST';
export const LOGIN_WITH_GOOGLE_SUCCESS = 'LOGIN_WITH_GOOGLE_SUCCESS';
export const LOGIN_WITH_GOOGLE_ERROR = 'LOGIN_WITH_GOOGLE_ERROR';

// RESET PASSWORD PAGE
export const GET_USER_BY_RESET_PASSWORD_TOKEN_REQUEST = 'GET_USER_BY_RESET_PASSWORD_TOKEN_REQUEST';
export const GET_USER_BY_RESET_PASSWORD_TOKEN_ERROR = 'GET_USER_BY_RESET_PASSWORD_TOKEN_ERROR';
export const GET_USER_BY_RESET_PASSWORD_TOKEN_SUCCESS = 'GET_USER_BY_RESET_PASSWORD_TOKEN_SUCCESS';

export const SUBMIT_RESET_PASSWORD_REQUEST_REQUEST = 'SUBMIT_RESET_PASSWORD_REQUEST_REQUEST';
export const SUBMIT_RESET_PASSWORD_REQUEST_ERROR = 'SUBMIT_RESET_PASSWORD_REQUEST_ERROR';
export const SUBMIT_RESET_PASSWORD_REQUEST_SUCCESS = 'SUBMIT_RESET_PASSWORD_REQUEST_SUCCESS';

export const SUBMIT_RESET_PASSWORD_REQUEST = 'SUBMIT_RESET_PASSWORD_REQUEST';
export const SUBMIT_RESET_PASSWORD_ERROR = 'SUBMIT_RESET_PASSWORD_ERROR';
export const SUBMIT_RESET_PASSWORD_SUCCESS = 'SUBMIT_RESET_PASSWORD_SUCCESS';

export const RESET_RESET_PASSWORD_META = 'RESET_RESET_PASSWORD_META';
export const RESET_RESET_PASSWORD_PAGE = 'RESET_RESET_PASSWORD_PAGE';

// Create Account Page
export const SUBMIT_CREATE_ACCOUNT_REQUEST = 'SUBMIT_CREATE_ACCOUNT_REQUEST';
export const SUBMIT_CREATE_ACCOUNT_SUCCESS = 'SUBMIT_CREATE_ACCOUNT_SUCCESS';
export const SUBMIT_CREATE_ACCOUNT_ERROR = 'SUBMIT_CREATE_ACCOUNT_ERROR';

export const RESET_CREATE_ACCOUNT_META = 'RESET_CREATE_ACCOUNT_META';
export const RESET_CREATE_ACCOUNT_PAGE = 'RESET_CREATE_ACCOUNT_PAGE';

// Pricing Page
export const GET_CHECKOUT_SESSION_LINK_REQUEST = 'GET_CHECKOUT_SESSION_LINK_REQUEST';
export const GET_CHECKOUT_SESSION_LINK_ERROR = 'GET_CHECKOUT_SESSION_LINK_ERROR';
export const GET_CHECKOUT_SESSION_LINK_SUCCESS = 'GET_CHECKOUT_SESSION_LINK_SUCCESS';

export const RESET_CHECKOUT_SESSION_LINK_META = 'CHECKOUT_SESSION_LINK_META';
export const RESET_CHECKOUT_SESSION_LINK_PAGE = 'CHECKOUT_SESSION_LINK_PAGE';

// Connect Whatsapp Page
export const SUBMIT_WHATSAPP_ACCOUNT_LINK_REQUEST = 'SUBMIT_WHATSAPP_ACCOUNT_LINK_REQUEST';
export const SUBMIT_WHATSAPP_ACCOUNT_LINK_ERROR = 'SUBMIT_WHATSAPP_ACCOUNT_LINK_ERROR';
export const SUBMIT_WHATSAPP_ACCOUNT_LINK_SUCCESS = 'SUBMIT_WHATSAPP_ACCOUNT_LINK_SUCCESS';

export const RESET_WHATSAPP_ACCOUNT_LINK_META = 'RESET_WHATSAPP_ACCOUNT_LINK_META';
export const RESET_WHATSAPP_ACCOUNT_LINK_PAGE = 'RESET_WHATSAPP_ACCOUNT_LINK_PAGE';

// Expense Page
export const GET_EXPENSES_REQUEST = 'GET_EXPENSES_REQUEST';
export const GET_EXPENSES_ERROR = 'GET_EXPENSES_ERROR';
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';

export const DOWNLOAD_EXPENSES_REQUEST = 'DOWNLOAD_EXPENSES_REQUEST';
export const DOWNLOAD_EXPENSES_ERROR = 'DOWNLOAD_EXPENSES_ERROR';
export const DOWNLOAD_EXPENSES_SUCCESS = 'DOWNLOAD_EXPENSES_SUCCESS';

export const RESET_HAS_DOWNLOAD = 'RESET_HAS_DOWNLOAD';

export const GET_EXPENSE_BASIC_REQUEST = 'GET_EXPENSE_BASIC_REQUEST';
export const GET_EXPENSE_BASIC_ERROR = 'GET_EXPENSE_BASIC_ERROR';
export const GET_EXPENSE_BASIC_SUCCESS = 'GET_EXPENSE_BASIC_SUCCESS';

export const GET_EXPENSE_PAYMENT_OPTIONS_REQUEST = 'GET_EXPENSE_PAYMENT_OPTIONS_REQUEST';
export const GET_EXPENSE_PAYMENT_OPTIONS_ERROR = 'GET_EXPENSE_PAYMENT_OPTIONS_ERROR';
export const GET_EXPENSE_PAYMENT_OPTIONS_SUCCESS = 'GET_EXPENSE_PAYMENT_OPTIONS_SUCCESS';

export const RESET_SELECTED_EXPENSE = 'RESET_SELECTED_EXPENSE';

export const DELETE_EXPENSE_REQUEST = 'DELETE_EXPENSE_REQUEST';
export const DELETE_EXPENSE_ERROR = 'DELETE_EXPENSE_ERROR';
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS';

export const SUBMIT_EXPENSE_REQUEST = 'SUBMIT_EXPENSE_REQUEST';
export const SUBMIT_EXPENSE_ERROR = 'SUBMIT_EXPENSE_ERROR';
export const SUBMIT_EXPENSE_SUCCESS = 'SUBMIT_EXPENSE_SUCCESS';

export const RESET_EXPENSES_META = 'RESET_EXPENSES_META';
export const RESET_EXPENSES_PAGE = 'RESET_EXPENSES_PAGE';


// Account Links
export const GET_ACCOUNT_LINKS_REQUEST = 'GET_ACCOUNT_LINKS_REQUEST';
export const GET_ACCOUNT_LINKS_ERROR = 'GET_ACCOUNT_LINKS_ERROR';
export const GET_ACCOUNT_LINKS_SUCCESS = 'GET_ACCOUNT_LINKS_SUCCESS';

export const DELETE_ACCOUNT_LINK_REQUEST = 'DELETE_ACCOUNT_LINK_REQUEST';
export const DELETE_ACCOUNT_LINK_ERROR = 'DELETE_ACCOUNT_LINK_ERROR';
export const DELETE_ACCOUNT_LINK_SUCCESS = ' DELETE_ACCOUNT_LINK_SUCCESS';

export const RESET_ACCOUNT_LINKS_META = 'RESET_ACCOUNT_LINKS_META';
export const RESET_ACCOUNT_LINKS_PAGE = 'RESET_ACCOUNT_LINKS_PAGE';

//Account Link Email Confirmation
export const VERIFY_CHANNEL_REQUEST = 'VERIFY_CHANNEL_REQUEST';
export const VERIFY_CHANNEL_ERROR = 'VERIFY_CHANNEL_ERROR';
export const VERIFY_CHANNEL_SUCCESS = 'VERIFY_CHANNEL_SUCCESS';

export const RESET_VERIFY_CHANNEL_PAGE = 'RESET_VERIFY_CHANNEL_PAGE';
export const RESET_VERIFY_CHANNEL_META = 'RESET_VERIFY_CHANNEL_META';

//Account Email Confirmation
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';

export const RESET_VERIFY_EMAIL_PAGE = 'RESET_VERIFY_EMAIL_PAGE';
export const RESET_VERIFY_EMAIL_META = 'RESET_VERIFY_EMAIL_META';

// Account Link Detail Page
export const GET_ACCOUNT_LINK_BASIC_REQUEST = 'GET_ACCOUNT_LINK_BASIC_REQUEST';
export const GET_ACCOUNT_LINK_BASIC_ERROR = 'GET_ACCOUNT_LINK_BASIC_ERROR';
export const GET_ACCOUNT_LINK_BASIC_SUCCESS = 'GET_ACCOUNT_LINK_BASIC_SUCCESS';

export const SUBMIT_ACCOUNT_LINK_REQUEST = 'SUBMIT_ACCOUNT_LINK_REQUEST';
export const SUBMIT_ACCOUNT_LINK_ERROR = 'SUBMIT_ACCOUNT_LINK_ERROR';
export const SUBMIT_ACCOUNT_LINK_SUCCESS = 'SUBMIT_ACCOUNT_LINK_SUCCESS';

export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';
export const RESEND_VERIFICATION_ERROR = 'RESEND_VERIFICATION_ERROR';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';

export const RESET_ACCOUNT_LINK_BASIC_META = 'RESET_ACCOUNT_LINKS_BASIC_META';
export const RESET_ACCOUNT_LINK_BASIC_PAGE = 'RESET_ACCOUNT_LINKS_BASIC_PAGE';

//	Billing Page
export const GET_ACTIVE_SUBSCRIPTION_REQUEST = 'GET_ACTIVE_SUBSCRIPTION_REQUEST';
export const GET_ACTIVE_SUBSCRIPTION_ERROR = 'GET_ACTIVE_SUBSCRIPTION_ERROR';
export const GET_ACTIVE_SUBSCRIPTION_SUCCESS = 'GET_ACTIVE_SUBSCRIPTION_SUCCESS';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';

export const GET_SUBSCRIPTION_RECEIPTS_REQUEST = 'GET_SUBSCRIPTION_RECEIPTS_REQUEST';
export const GET_SUBSCRIPTION_RECEIPTS_ERROR = 'GET_SUBSCRIPTION_RECEIPTS_ERROR';
export const GET_SUBSCRIPTION_RECEIPTS_SUCCESS = 'GET_SUBSCRIPTION_RECEIPTS_SUCCESS';

export const RESET_BILLING_META = 'RESET_BILLING_META';
export const RESET_BILLING_PAGE = 'RESET_BILLING_PAGE';

//Settings
export const SUBMIT_SETTING_REQUEST = 'SUBMIT_SETTING_REQUEST';
export const SUBMIT_SETTING_ERROR = 'SUBMIT_SETTING_ERROR';
export const SUBMIT_SETTING_SUCCESS = 'SUBMIT_SETTING_SUCCESS';

export const GET_PAYMENT_OPTIONS_REQUEST = 'GET_PAYMENT_OPTIONS_REQUEST';
export const GET_PAYMENT_OPTIONS_ERROR = 'GET_PAYMENT_OPTIONS_ERROR';
export const GET_PAYMENT_OPTIONS_SUCCESS = 'GET_PAYMENT_OPTIONS_SUCCESS';

export const SUBMIT_PAYMENT_OPTIONS_REQUEST = 'SUBMIT_PAYMENT_OPTIONS_REQUEST';
export const SUBMIT_PAYMENT_OPTIONS_ERROR = 'SUBMIT_PAYMENT_OPTIONS_ERROR';
export const SUBMIT_PAYMENT_OPTIONS_SUCCESS = 'SUBMIT_PAYMENT_OPTIONS_SUCCESS';

export const RESET_SETTINGS_META = 'RESET_SETTINGS_META';
export const RESET_SETTINGS_PAGE = 'RESET_SETTINGS_PAGE';

export const RESET_SUBSCRIPTION_RECEIPTS_META = 'RESET_SUBSCRIPTION_RECEIPTS_META';
export const RESET_SUBSCRIPTION_RECEIPTS_PAGE = 'RESET_SUBSCRIPTION_RECEIPTS_PAGE';

export const GET_ACCOUNT_STATUS_BASIC_REQUEST = 'GET_ACCOUNT_STATUS_BASIC_REQUEST';
export const GET_ACCOUNT_STATUS_BASIC_ERROR = 'GET_ACCOUNT_STATUS_BASIC_ERROR';
export const GET_ACCOUNT_STATUS_BASIC_SUCCESS = 'GET_ACCOUNT_STATUS_BASIC_SUCCESS';

// Intuit Connections
export const GET_INTUIT_CONNECTIONS_REQUEST = 'GET_INTUIT_CONNECTIONS_REQUEST';
export const GET_INTUIT_CONNECTIONS_ERROR = 'GET_INTUIT_CONNECTIONS_ERROR';
export const GET_INTUIT_CONNECTIONS_SUCCESS = 'GET_INTUIT_CONNECTIONS_SUCCESS';

export const RESET_INTUIT_CONNECTIONS_META = 'RESET_INTUIT_CONNECTIONS_META';
export const RESET_INTUIT_CONNECTIONS_PAGE = 'RESET_INTUIT_CONNECTIONS_PAGE';

// Intuit Connection Detail Page
export const GET_INTUIT_CONNECTION_BASIC_REQUEST = 'GET_INTUIT_CONNECTION_BASIC_REQUEST';
export const GET_INTUIT_CONNECTION_BASIC_ERROR = 'GET_INTUIT_CONNECTION_BASIC_ERROR';
export const GET_INTUIT_CONNECTION_BASIC_SUCCESS = 'GET_INTUIT_CONNECTION_BASIC_SUCCESS';

export const SUBMIT_INTUIT_CONNECTION_REQUEST = 'SUBMIT_INTUIT_CONNECTION_REQUEST';
export const SUBMIT_INTUIT_CONNECTION_ERROR = 'SUBMIT_INTUIT_CONNECTION_ERROR';
export const SUBMIT_INTUIT_CONNECTION_SUCCESS = 'SUBMIT_INTUIT_CONNECTION_SUCCESS';

export const RESET_INTUIT_CONNECTION_BASIC_META = 'RESET_INTUIT_CONNECTION_BASIC_META';
export const RESET_INTUIT_CONNECTION_BASIC_PAGE = 'RESET_INTUIT_CONNECTION_BASIC_PAGE';
