import { connect } from 'react-redux';
import LoginXeroPage from './LoginXeroPage';

const mapsStateToProps = state => ({

});

const mapsDispatchToProps = dispatch => ({

});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(LoginXeroPage);
