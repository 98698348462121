import React, { useEffect } from 'react';
import UrlParse from 'url-parse';

import LocalStorageHelper from '../../helpers/local_storage';
import PixelHelper from '../../helpers/pixel';
import AccountLinkBasicPage from './AccountLinkBasicPage';

const AccountLinkDetailPage = (props) => {
	const {
		location,
		getAccountLinkById,
		resetPage
	} = props;
	const { query } = UrlParse(location.search, true);

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		query.id && getAccountLinkById(query.id);

		return () => {
			LocalStorageHelper.removeTime();
			resetPage();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AccountLinkBasicPage {...props} />
	);
};

export default AccountLinkDetailPage;