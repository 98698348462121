import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	subscription: null,
	paymentOptions: null,
	loading: false,
	loadingSubmit: false,
	loadingSubmitTwo: false,
	loadingCancel: false
};

export default function settingPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_REQUEST:
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR:
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS:
		case ActionTypes.SUBMIT_SETTING_REQUEST:
		case ActionTypes.SUBMIT_SETTING_ERROR:
		case ActionTypes.SUBMIT_SETTING_SUCCESS:
		case ActionTypes.CANCEL_SUBSCRIPTION_REQUEST:
		case ActionTypes.CANCEL_SUBSCRIPTION_ERROR:
		case ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
		case ActionTypes.GET_PAYMENT_OPTIONS_REQUEST:
		case ActionTypes.GET_PAYMENT_OPTIONS_ERROR:
		case ActionTypes.GET_PAYMENT_OPTIONS_SUCCESS:
		case ActionTypes.SUBMIT_PAYMENT_OPTIONS_REQUEST:
		case ActionTypes.SUBMIT_PAYMENT_OPTIONS_ERROR:
		case ActionTypes.SUBMIT_PAYMENT_OPTIONS_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_SETTINGS_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_SETTINGS_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
