import { push } from 'connected-react-router';
import API from '../helpers/api';

export function disconnectFromQuickBooks() {
	return async dispatch => {

		const response = await API.intuit_connection.disconnectFromQuickBooks();

		if (response.meta.code !== 200) {
			window.alert(response.meta.message);
			dispatch(push('/login'));
			return;
		}

		// Navigate to next page
		dispatch(push('/intuit-connections'));
	};
}
