import React from 'react';
import { Typography, Button, Modal, Divider, Row, Col } from 'antd';
import moment from 'moment';

import LabelHelper from '../../helpers/label';

const { Text } = Typography;

const ConfirmModal = ({ title, message, loading, open, onOk, onCancel, disabled, subscriptionPlan=null, subscriptionPeriod=null }) => {
	return(
		<>
			<Modal
				title={title}
    	        closable={false}
    	        open={open}
    	        width={350}
				bodyStyle={{
					padding: 0,
					margin: 0
				}}
				wrapClassName="custom-modal-content"
				footer={null}
				centered
			>
				<Divider
					className="custom-modal-divider"
					style={{ margin: 0, padding: 0 }}
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: 100
					}}
				>
					<Text style={{ textAlign: 'center', margin: 20, color: '#000000', whiteSpace: 'pre-line' }}>
						{message}
						{subscriptionPlan && subscriptionPeriod && (
							<Text>{'\n\n'}Just to let you know, you can still use your account with the {LabelHelper.subscriptionPlan(subscriptionPlan)} Plan until {'\n'}<span style={{ color: '#344E41', fontWeight: 'bold' }}>{moment(subscriptionPeriod).format('MMMM DD, YYYY')}.</span></Text>
						)}
					</Text>
				</div>
				<Row>
					<Col
						span={12}
					>
						<Button
							onClick={onCancel}
							disabled={loading}
							style={{ 
								border: '0.8px solid #0000001A', 
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
								color: '#000000',
								width: '100%',
								height: '100%',
								padding: 20
							 }}
							type="link"
						>
                        Cancel
						</Button>
					</Col>
					<Col
						span={12}
					>
						<Button
							htmlType="submit"
							onClick={onOk}
							loading={loading}
							disabled={disabled}
							style={{ 
								border: '0.8px solid #0000001A', 
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								borderBottomLeftRadius: 0,
								color: '#FF8A00',
								width: '100%',
								height: '100%',
								padding: 20
							 }}
						>
                        Confirm
						</Button>
					</Col>
				</Row>
			</Modal>
		</>
	);
};
export default ConfirmModal;
