import { connect } from 'react-redux';
import { onSubmit, resendEmailVerification, resetMeta } from '../../../actions/AccountLinkDetailPage/AccountLinkBasicPage';

import AccountLinkBasicPage from './AccountLinkBasicPage';

const mapsStateToProps = state => ({
	accountLinkBasicPageProps: state.accountLinkDetailPage.accountLinkBasicPageReducer
});

const mapsDispatchToProps = dispatch => ({
	onSubmit(options) {
		dispatch(onSubmit(options));
	},
	resendEmailVerification(options) {
		dispatch(resendEmailVerification(options));
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(AccountLinkBasicPage);