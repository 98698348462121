/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Button, Image, Card } from 'antd';
import LocalStorageHelper from '../../helpers/local_storage';
import successPng from '../../images/success.png';
import PixelHelper from '../../helpers/pixel';
import GtmHelper from '../../helpers/gtm';

const PaymentSuccessPage = (props) => {
	useEffect(() => {
	}, []);

	const { billingPageProps, getActiveSubscription } = props;
	const { subscription } = billingPageProps;

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);
		getActiveSubscription();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (subscription) {
			const user  = LocalStorageHelper.getUser();

			const newUser = {
				...user,
				subscription: true,
				recurring: true,
				subscription_status: subscription.status
			};
			LocalStorageHelper.setUser(newUser);

			PixelHelper.track('StartTrial', {
				subscription_id: LocalStorageHelper.getUserId()
			});
			GtmHelper.dataLayer({
				userId: LocalStorageHelper.getUserId(),
				page: window.location.pathname
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscription]);

	return (
		<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
			<Card
				style={{
					maxWidth: 773,
					maxHeight: 700,
					display: 'flex',
					justifyContent: 'center',
					padding: 30,
					alignItems: 'center',
					borderRadius: 50,
					boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
				}}
				bordered
			>
				<div
					style={{
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column'
					}}
				>
					<div style={{ marginBottom: 20 }}>
						<Image
							width={200}
							src={successPng}
							preview={false}
						/>
					</div>
					<div>
						<p style={{ fontWeight: 510, fontSize: '30px', textAlign: 'center', margin: 0  }}>Successfully Subscribed NekoExpense!</p>
					</div>
					<div>
						<p style={{ fontWeight: 274, fontSize: '19px', textAlign: 'center', margin: 0, padding: '0px 30px' }}>NekoExpense’s premium service granting you unrestricted access to all features and benefits without any commitment or cost.</p>
					</div>
					<Button
						type="primary"
						key="console"
						onClick={() => props.history.push('/login')}
						style={{
							width: '100%',
							textAlign: 'center',
						 marginTop: 20,
						 alignContent: 'center',
						 height: 50
						}}
					>
						Continue
					</Button>
				</div>
			</Card>
		</div>
	);
};

export default PaymentSuccessPage;
