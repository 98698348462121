import React, { useEffect, useRef } from 'react';
import { Button, Row, Col, Table, Form, Input, Select } from 'antd';
import { SearchOutlined, PlusSquareFilled } from '@ant-design/icons';
import UrlParse from 'url-parse';

// Components
import Loading from '../../components/Loading';
import Card from '../../components/Card';

// Helpers
import GeneralHelper from '../../helpers/general';
import LabelHelper from '../../helpers/label';

// Common Enums
import CommonEnum from '../../constants/CommonEnum';
import PixelHelper from '../../helpers/pixel';

const { Item } = Form;

const AccountLinkPage = (props) => {
	const { getAccountLinks, resetPage, accountLinkPageProps, history, location } = props;
	const { total, page, limit, accountLinks, loading } = accountLinkPageProps;
	const { query } = UrlParse(location.search, true);

	const searchFormRef = useRef();

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		getAccountLinks(query);

		return () => resetPage();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------- Start of Functions --------------------------------- //
	const handleOnSearch = values => {
		const { channel, status, details } = values;
		const searchKeys = {
			...(channel && { channel }),
			...(status && { status }),
			...(details && { details }),
			page: 1,
			limit
		};
		const queryString = GeneralHelper.objectToQueryString(searchKeys);
		getAccountLinks({ ...searchKeys });
		history.push(`${location.pathname}?${queryString}`);
	};

	const renderSearchForm = () => {
		return(
			<Form
				layout="inline"
				onFinish={handleOnSearch}
				initialValues={query}
				ref={searchFormRef}
			>
				<Item
					style={{ margin: 4 }}
					name="channel"
				>
					<Select
						placeholder="Search By Channel"
						style={{ width: 200 }}
						options={CommonEnum.account_channel}
						filterOption={(input, option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						allowClear
						onClear={() => searchFormRef.current.submit()}
					/>
				</Item>
				<Item
					style={{ margin: 4 }}
					name="status"
				>
					<Select
						placeholder="Search By Status"
						style={{ width: 200 }}
						options={CommonEnum.account_status}
						filterOption={(input, option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						allowClear
						onClear={() => searchFormRef.current.submit()}
					/>
				</Item>
				<Item
					style={{ margin: 4 }}
					name="details"
				>
					<Input
						prefix={<SearchOutlined style={{ color: '#bfbfbf', marginRight: 5 }}/>}
						placeholder="Search Details"
						style={{ width: 270 }}
						allowClear
						onChange={() => {
							let details = searchFormRef.current.getFieldValue('details');
							if (details.length === 0) searchFormRef.current.submit();
						}}
					/>
				</Item>
				<Item style={{ margin: 4 }}>
					<Button
						type="primary"
						htmlType="submit"
						icon={<SearchOutlined />}
					>
						Search
					</Button>
				</Item>
				<Item style={{ margin: 4 }}>
					<Button
						type="primary"
						icon={<PlusSquareFilled />}
						onClick={() => history.push('/account-links/create')}
					>
						New Account
					</Button>
				</Item>

			</Form>
		);
	};
	// ---------------------------------- End of Functions ---------------------------------- //


	const columns = [
		{
			title: 'Channel',
			dataIndex: 'channel',
			key: 'channel',
			render: data => LabelHelper.accountChannel(data)
		},
		{
			title: 'Details',
			dataIndex: 'channel',
			key: 'details',
			render: (channel, record) => {
			  	if (channel === 'email') {
					return record.email.replace(/\s+/, ''); //Remove space
			  	} else if (channel === 'whatsapp') {
					const phoneNumber = `${record.country_code} ${record.whatsapp_number.replace(record.country_code.match(/\d+/g).join(''), '')}`;
					return phoneNumber.replace(/\s+/, ''); //Remove space
			  	}
			  	return null; // Handle other cases or return an empty value if needed
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: data =>
				<span
					style={{
						backgroundColor: data === 'verified' ? '#53826A' : '#AA0000',
						padding: '4px',
						borderRadius: '6px',
						color: 'white' ,
						fontSize: '14px' }}
				> {LabelHelper.accountStatus(data)}
				</span>
		},
		{
			title: 'Action',
			dataIndex: 'action',
			width: '10%',
			render: (text, record) => (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<a
					onClick={e => {
						e.preventDefault();
						history.push(`/account-links/id?id=${record._id}`);
					}}
				>
					Edit
				</a>
			)
		}
	];

	return (
		<Card
			title="Account List"
			loading={loading}
		>
			<div className="main-content">
				<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
					<Row style={{ marginBottom: 20 }}>
						{renderSearchForm()}
					</Row>
				</div>
				<Row>
					<Col flex="auto">
						{loading ?
							<Loading /> :
							<Table
								scroll={ { x: true } }
								columns={columns}
								dataSource={accountLinks.map(accountLink => (
									{
										...accountLink,
										key: accountLink._id
									}
								))}
								pagination={{
									current: page,
									position: ['bottomCenter'],
									onChange: (current, pageSize) => {
										const queryObj = { ...query, page: current, limit: pageSize };
										const queryString = GeneralHelper.objectToQueryString(queryObj);
										getAccountLinks(queryObj);
										history.push(`${location.pathname}?${queryString}`);
									},
									total,
									pageSize: limit
								}}
							/>
						}
					</Col>
				</Row>
			</div>
		</Card>
	);
};

export default AccountLinkPage;
