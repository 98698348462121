import { push } from 'connected-react-router';

import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';

export function getActiveSubscription() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_ACTIVE_SUBSCRIPTION_REQUEST,
			payload: { loading: true }
		});

		const response = await API.subscription.getActiveSubscription();

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { subscription } = response.data;

		dispatch({
			type: ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS,
			payload: {
				subscription,
				loading: false
			}
		});
	};
}

export function cancelSubscription(id) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.CANCEL_SUBSCRIPTION_REQUEST,
			payload: {
				loadingCancel: true
			}
		});

		const response = await API.subscription.cancelSubscription(id);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.CANCEL_SUBSCRIPTION_ERROR,
				payload: {
					meta: response.meta,
					loadingCancel: false
				}
			});
			return;
		}

		dispatch({
			type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
			payload: {
				meta: response.meta,
				loadingCancel: false
			}
		});

		dispatch(push('/billing'));
	};
}

// getInvoices
export function getInvoices(options) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_SUBSCRIPTION_RECEIPTS_REQUEST,
			payload: { loading: true }
		});

		const response = await API.invoice.getInvoices({ ...options });

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_SUBSCRIPTION_RECEIPTS_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { total, page, limit, count, invoices } = response.data;

		dispatch({
			type: ActionTypes.GET_SUBSCRIPTION_RECEIPTS_SUCCESS,
			payload: {
				total,
				page,
				limit,
				count,
				invoices,
				loading: false
			}
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_BILLING_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_BILLING_PAGE
		});
	};
}