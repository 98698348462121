import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import WebFont from 'webfontloader';
import { ConfigProvider } from 'antd';
import store, { history } from './store';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './index.less';
import PixelHelper from './helpers/pixel';
import GtmHelper from './helpers/gtm';

WebFont.load({
	google: {
		families: ['Libre Franklin:300,400,500', 'Roboto', 'sans-serif']
	}
});

const alertOptions = {
	position: 'top center',
	timeout: 0,
	offset: '30px',
	transition: 'scale',
	zindex: 1001
};

PixelHelper.init();
GtmHelper.init();

ReactDOM.render(
	<ConfigProvider
		theme={{
			token: {
				// Seed Token
				fontSize: 16,

				// Map Token
				colorPrimaryHover: '#53826A'
			},
			components: {
				Layout: {
					lightSiderBg: '#FFFFFF'
				},
				Menu: {
					itemHeight: 59,
					itemSelectedColor: '#FFFFFF',
					fontSize: 15,
					algorithm: true
				},
				Button: {
					colorPrimary: '#53826A',
					colorText: '#53826A',
					// colorBorder: '#53826A',
					algorithm: true
				},
				Form: {
					labelFontSize: 14,
					itemMarginBottom: 20,
					verticalLabelPadding: '0 0 7px'
				},
				Input: {
					activeBorderColor: '#53826A',
					hoverBorderColor: '#53826A',
					activeShadow: '0 0 0 1px #53826A'
				},
				InputNumber: {
					activeBorderColor: '#53826A',
					hoverBorderColor: '#53826A',
					activeShadow: '0 0 0 1px #53826A'
				},
				DatePicker: {
					activeBorderColor: '#53826A',
					hoverBorderColor: '#53826A',
					activeShadow: '0 0 0 1px #53826A'
				},
				Select: {
					// colorBgElevated: '#53826A'
				},
				Checkbox: {
					colorPrimary: '#53826A'
				}
			}
		}}
	>
		<Provider store={store()}>
			<AlertProvider
				template={AlertTemplate}
				{...alertOptions}
			>
				<ConnectedRouter history={history}>
					<Routes />
				</ConnectedRouter>
			</AlertProvider>
		</Provider>
	</ConfigProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
