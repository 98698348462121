import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

/* Layouts */
import BasicLayout from './layouts/BasicLayout';
import NavigationLayout from './layouts/NavigationLayout/';

/* Containers */
import LoginPage from './containers/LoginPage';
import LogoutPage from './containers/LogoutPage';
import AuthHelper from './helpers/auth';
import UnauthorizedPage from './containers/UnauthorizedPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import CreateAccountPage from './containers/CreateAccountPage';
import ConnectWhatsappPage from './containers/ConnectWhatsappPage';
import PricingPage from './containers/PricingPage';
import PaymentSuccessPage from './containers/PaymentSuccessPage';
import ChangePlan from './containers/ChangePlan';
import CallbackXeroPage from './containers/CallbackXeroPage';
import LoginXeroPage from './containers/LoginXeroPage';
import CallbackIntuitPage from './containers/CallbackIntuitPage';
import ConnectToQuickBookCallBackPage from './containers/ConnectToQuickBookCallBackPage';
import LoginIntuitPage from './containers/LoginIntuitPage';

// Dashboard Page
import ExpensePage from './containers/ExpensePage';

// Account Link Page
import AccountLinkPage from './containers/AccountLinkPage';
import AccountLinkDetailPage from './containers/AccountLinkDetailPage';
import AccountLinkBasicPage from './containers/AccountLinkDetailPage/AccountLinkBasicPage';

//Account Email Confirmation Page
import EmailConfirmationPage from './containers/EmailConfirmationPage';

//Account Link Email Confirmation Page
import ChannelConfirmationPage from './containers/ChannelConfirmationPage';

// Settings Page
import SettingPage from './containers/SettingPage';

import BillingPage from './containers/BillingPage';

// Subscription Receipts Page
import SubscriptionReceiptPage from './containers/SubscriptionReceiptPage';

//Error Page
import ErrorPage from './containers/ErrorPage';

// How it works page
import HowItWorksPage from './containers/HowItWorksPage';

// Intuit Connection Page
import IntuitConnectionPage from './containers/IntuitConnectionPage';
import IntuitConnectionDetailPage from './containers/IntuitConnectionDetailPage';

const routes = [
	// General and Authentication
	{ path: '/unauthorized', exact: true, isPrivate: false, Layout: BasicLayout, layoutClass: 'narrow', Component: UnauthorizedPage },
	{ path: '/login', exact: true, isPrivate: false, Layout: BasicLayout, Component: LoginPage },
	{ path: '/logout', exact: true, isPrivate: false, Layout: BasicLayout, Component: LogoutPage },
	{ path: '/create', exact: true, isPrivate: false, Layout: BasicLayout, Component: CreateAccountPage },
	{ path: '/reset-password/t', exact: false, isPrivate: false, Layout: BasicLayout, Component: ResetPasswordPage },
	{ path: '/connect-whatsapp', exact: true, isPrivate: false, Layout: BasicLayout, Component: ConnectWhatsappPage },
	{ path: '/payment-success', exact: true, isPrivate: false, Layout: BasicLayout, Component: PaymentSuccessPage },
	{ path: '/callback/xero', exact: false, isPrivate: false, Layout: BasicLayout, Component: CallbackXeroPage },
	{ path: '/login/xero', exact: true, isPrivate: false, Layout: BasicLayout, Component: LoginXeroPage },
	{ path: '/callback/intuit', exact: false, isPrivate: false, Layout: BasicLayout, Component: CallbackIntuitPage },
	{ path: '/callback/connect-to-quickbooks', exact: false, isPrivate: false, Layout: BasicLayout, Component: ConnectToQuickBookCallBackPage },
	{ path: '/login/intuit', exact: true, isPrivate: false, Layout: BasicLayout, Component: LoginIntuitPage },
	{ path: '/', exact: true, isPrivate: true, Layout: NavigationLayout, Component: ExpensePage },

	// How it works
	{ path: '/how-it-works', exact: true, isPrivate: true, Layout: BasicLayout, Component: HowItWorksPage },

	// Pricing Page
	{ path: '/pricing', exact: true, isPrivate: true, Layout: BasicLayout, Component: PricingPage },

	// Expense Page
	{ path: '/expenses', exact: false, isPrivate: true, Layout: NavigationLayout, Component: ExpensePage },

	// Account Link Page
	{ path: '/account-links', exact: true, isPrivate: true, Layout: NavigationLayout, Component: AccountLinkPage },
	{ path: '/account-links/create', exact: true, isPrivate: true, Layout: NavigationLayout, Component: AccountLinkBasicPage },
	{ path: '/account-links/id', exact: true, isPrivate: true, Layout: NavigationLayout, Component: AccountLinkDetailPage },

	//Account Link Email Verification Page
	{ path: '/account-links/verify/t', exact: false, isPrivate: false, Layout: BasicLayout, Component: ChannelConfirmationPage },

	//Account Confirmation Page
	{ path: '/confirm-email/t', exact: false, isPrivate: false, Layout: BasicLayout, Component: EmailConfirmationPage },

	// Settings Page
	{ path: '/account-settings', exact: true, isPrivate: true, Layout: NavigationLayout, Component: SettingPage },

	// Billing Page
	{ path: '/billing', exact: true, isPrivate: true, Layout: NavigationLayout, Component: BillingPage },

	// Intuit Connection Page
	{ path: '/intuit-connections', exact: true, isPrivate: true, Layout: NavigationLayout, Component: IntuitConnectionPage },
	{ path: '/intuit-connections/id', exact: true, isPrivate: true, Layout: NavigationLayout, Component: IntuitConnectionDetailPage },

	// Subscription Receipts Page
	{ path: '/settings/subscription-receipts', exact: true, isPrivate: true, Layout: NavigationLayout, Component: SubscriptionReceiptPage },

	// Change Plan Page
	{ path: '/change-plan', exact: true, isPrivate: true, Layout: BasicLayout, Component: ChangePlan },

	// Error Page
	{ path: '/error', exact: false, isPrivate: false, Layout: BasicLayout, Component: ErrorPage }
];

function getRedirectedPath(location) {
	if (location.pathname.split('?')[0] === '/login' && location.pathname.split('?').length > 1) {
		return location.pathname + location.search + location.hash;
	}

	// skip the redirection from these routes
	if (['/login', '/logout'].indexOf(location.pathname.split('?')[0]) !== -1) {
		return '/login';
	}

	return `/login?path=${location.pathname + location.search + location.hash}`;
}

const PrivateRoute = ({ layout: Layout, component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			if (AuthHelper.isAuthenticated()) {
				if (props.location.pathname === '/') {
					return (
						<Redirect
							to={{
								pathname: '/expenses',
								state: { from: props.location }
							}}
						/>
					);
				} else {
					return (
						<Layout>
							<Component {...props} />
						</Layout>
					);
				}
			} else {
				return (
					<Redirect
						to={{
							pathname: getRedirectedPath(props.location),
							state: { from: props.location }
						}}
					/>
				);
			}
		}}
	/>
);

export class Routes extends Component {
	render() {
		const { location } = this.props;
		return (
			<Switch location={location}>
				{routes.map(({ path, exact, isPrivate, Layout, Component }) => {
					if (isPrivate) {
						return <PrivateRoute
							key={0}
							path={path}
							exact={exact}
							layout={Layout}
							component={Component}
						       />;
					} else {
						return (
							<Route
								key={0}
								path={path}
								exact={exact}
								render={props => (
									<Layout location={location}>
										<Component {...props} />
									</Layout>
								)}
							/>
						);
					}
				})}
				<Route render={props => (AuthHelper.isAuthenticated() ? <Redirect to={'/expenses'} /> : <Redirect to={getRedirectedPath(props.location)} />)} />
			</Switch>
		);
	}
}

export default withRouter(Routes);
