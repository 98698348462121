import { connect } from 'react-redux';
import { signUp, resetPage, resetMeta } from '../../actions/CreateAccountPage';
import { loginWithGoogle } from '../../actions/LoginPage';
import CreateAccountPage from './CreateAccountPage';

const mapsStateToProps = state => ({
	createAccountPageProps: state.createAccountPageReducer,
	loginPageProps: state.loginPageReducer
});

const mapsDispatchToProps = dispatch => ({
	signUp(options) {
		dispatch(signUp(options));
	},
	loginWithGoogle(options) {
		dispatch(loginWithGoogle(options));
	},
	resetPage() {
		dispatch(resetPage());
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(CreateAccountPage);
