export default {
	api: '',
	appPath: '/',
	loginTimeout: 18000000,
	googleAuth: {
		clientId: '720220931198-53ifal8jg57nn9onql1ier3crgdo1igq.apps.googleusercontent.com'
	},
	xeroAuth: {
		clientId: 'DDBA0796238A492C8F32F78FDA211B0B',
		redirectUri: '',
		scopes: 'openid profile email'
	}
};
