import local from './environment/local';
import testing from './environment/testing';
import testing2 from './environment/testing2';
import production from './environment/production';

const configs = {
	local,
	testing,
	testing2,
	production
};

export default configs[process.env.NODE_ENV] || configs.local;
