export default {
	api: 'https://api.nekoexpense.com',
	appPath: '/',
	loginTimeout: 18000000,
	googleAuth: {
		clientId: '395860011274-5qeireift38iq9sifaml3k7ur5r9aojs.apps.googleusercontent.com'
	},
	xeroAuth: {
		clientId: 'DF2B073D03B44FB289F25314D333DA91',
		redirectUri: 'https://app.nekoexpense.com/callback/xero',
		scopes: 'openid profile email'
	},
	quickbookAuth: {
		clientId: 'ABPBmzedZ1eC2JERbqHRzUTHV6DdqDOYxLxAzPPdDprq7IVZV8',
		redirectUri: 'https://app.nekoexpense.com/callback/intuit',
		scopes: 'com.intuit.quickbooks.accounting openid profile email phone address',
		connectToQuickBooksRedirectUri: 'https://app.nekoexpense.com/callback/connect-to-quickbooks',
		disconnectFromQuickBooksRedirectUri: 'https://app.nekoexpense.com/callback/disconnect-from-quickbooks'
	}
};
