import { connect } from 'react-redux';
import { xeroAuthorize } from '../../actions/CallbackXeroPage';
import CallbackXeroPage from './CallbackXeroPage';

const mapsStateToProps = state => ({
	// callbackCasPageProps: state.callbackCasPageReducer
});

const mapsDispatchToProps = dispatch => ({
	xeroAuthorize(param) {
		dispatch(xeroAuthorize(param));
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(CallbackXeroPage);
