import { push } from 'connected-react-router';

import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';

export function onSubmit(fields) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.SUBMIT_SETTING_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const body = {
			user: {
				...fields
			}
		};

		const response = await API.user.updatePassword(body);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_SETTING_ERROR,
				payload: {
					meta: response.meta,
					loadingSubmit: false
				}
			});
			return;
		}

		const { user: userData } = response.data;
		const user = LocalStorageHelper.getUser();
		const newUser = {
			...user,
			name: userData.name
		};

		// Set new user in local storage
		LocalStorageHelper.setUser(newUser);

		dispatch({
			type: ActionTypes.SUBMIT_SETTING_SUCCESS,
			payload: {
				meta: response.meta,
				loadingSubmit: false
			}
		});
	};
}

export function getPaymentOptions() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_PAYMENT_OPTIONS_REQUEST,
			payload: {
				loading: true
			}
		});

		const response = await API.user.getPaymentOptions();

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_PAYMENT_OPTIONS_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { payment_option } = response.data;

		dispatch({
			type: ActionTypes.GET_PAYMENT_OPTIONS_SUCCESS,
			payload: {
				loading: false,
				paymentOptions: payment_option
			}
		});
	};
}

export function submitPaymentOptions(fields) {
	return async (dispatch, getState) => {
		const { paymentOptions } = getState().settingPageReducer;

		dispatch({
			type: ActionTypes.SUBMIT_PAYMENT_OPTIONS_REQUEST,
			payload: {
				loadingSubmitTwo: true
			}
		});

		const body = {
			payment_option: {
				...fields
			}
		}

		let response
		if (paymentOptions) {
			response = await API.user.updatePaymentOptions(paymentOptions._id, body)
		} else {
			response = await API.user.createPaymentOptions(body);
		}

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_PAYMENT_OPTIONS_ERROR,
				payload: {
					meta: response.meta,
					loadingSubmitTwo: false
				}
			});
			return;
		}

		const { payment_option } = response.data;

		dispatch({
			type: ActionTypes.SUBMIT_PAYMENT_OPTIONS_SUCCESS,
			payload: {
				meta: response.meta,
				loadingSubmitTwo: false,
				paymentOptions: payment_option
			}
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_SETTINGS_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_SETTINGS_PAGE
		});
	};
}