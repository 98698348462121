import _ from 'lodash';
import superagent from 'superagent';
import config from '../../config';
import { history } from '../../store';
import LocalStorageHelper from '../local_storage';

const needLogout = (body) => {
	return [4031, 4032].indexOf(_.get(body, 'meta.code')) !== -1;
};

const needSubscription = (body) => {
	return [4033].indexOf(_.get(body, 'meta.code')) !== -1;
};

const handleReloginRedirect = () => {
	// clean local storage for auth
	LocalStorageHelper.removeUser();
	LocalStorageHelper.removeToken();
	history.push(`/login?path=${history.location.pathname}${history.location.search}${history.location.hash}`);
};

export default {
	get(url, query = {}) {
		return new Promise(function (resolve, reject) {
			superagent
				.get(`${config.api}${url}`)
				.set('Accept', 'application/json')
				.set('Content-Type', 'application/json')
				.set('Cache-Control', 'no-cache')
				.set('Pragma', 'no-cache')
				.set('Authorization', `Bearer ${LocalStorageHelper.getToken()}`)
				.query(query)
				.end((error, result) => {
					if (error) {
						return reject({
							error: error,
							result: result
						});
					}
					if (needLogout(result.body)) {
						handleReloginRedirect();
						return;
					}
					if (needSubscription(result.body)) {
						history.push('/pricing');
						return;
					}
					resolve(result.body);
				});
		});
	},
	post(url, body = {}) {
		return new Promise(function (resolve, reject) {
			superagent
				.post(`${config.api}${url}`)
				.set('Accept', 'application/json')
				.set('Content-Type', 'application/json')
				.set('Authorization', `Bearer ${LocalStorageHelper.getToken()}`)
				.send(body)
				.end((error, result) => {
					if (error) {
						return reject({
							error: error,
							result: result
						});
					}
					if (needLogout(result.body)) {
						handleReloginRedirect();
						return;
					}
					if (needSubscription(result.body)) {
						history.push('/pricing');
						return;
					}
					resolve(result.body);
				});
		});
	},
	put(url, body = {}) {
		return new Promise(function (resolve, reject) {
			superagent
				.put(`${config.api}${url}`)
				.set('Accept', 'application/json')
				.set('Content-Type', 'application/json')
				.set('Authorization', `Bearer ${LocalStorageHelper.getToken()}`)
				.send(body)
				.end((error, result) => {
					if (error) {
						return reject({
							error: error,
							result: result
						});
					}
					if (needLogout(result.body)) {
						handleReloginRedirect();
						return;
					}
					if (needSubscription(result.body)) {
						history.push('/pricing');
						return;
					}
					resolve(result.body);
				});
		});
	},
	patch(url, body = {}) {
		return new Promise(function (resolve, reject) {
			superagent
				.patch(`${config.api}${url}`)
				.set('Accept', 'application/json')
				.set('Content-Type', 'application/json')
				.set('Authorization', `Bearer ${LocalStorageHelper.getToken()}`)
				.send(body)
				.end((error, result) => {
					if (error) {
						return reject({
							error: error,
							result: result
						});
					}
					if (needLogout(result.body)) {
						handleReloginRedirect();
						return;
					}
					if (needSubscription(result.body)) {
						history.push('/pricing');
						return;
					}
					resolve(result.body);
				});
		});
	},
	delete(url, query, body = {}) {
		return new Promise(function (resolve, reject) {
			superagent
				.del(`${config.api}${url}`)
				.set('Accept', 'application/json')
				.set('Content-Type', 'application/json')
				.set('Authorization', `Bearer ${LocalStorageHelper.getToken()}`)
				.query(query)
				.send(body)
				.end((error, result) => {
					if (error) {
						return reject({
							error: error,
							result: result
						});
					}
					if (needLogout(result.body)) {
						handleReloginRedirect();
						return;
					}
					if (needSubscription(result.body)) {
						history.push('/pricing');
						return;
					}
					resolve(result.body);
				});
		});
	}
};
