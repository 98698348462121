import { connect } from 'react-redux';
import ChangePlan from './ChangePlan';

const mapsStateToProps = state => ({
});

const mapsDispatchToProps = dispatch => ({
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(ChangePlan);
