import _ from 'lodash';

const GeneralHelper = {
	/**
	 * Check if the value is a valid email
	 * @param {String} email 
	 * @returns {Boolean}
	 */
	isEmailValid(email) {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email);
	},

	/**
	 * Convert a query from string to javacript object
	 * @param {String} queryString 
	 * @returns {Object}
	 */
	queryStringToObject(queryString) {
		if (!queryString || !_.isString(queryString)) {
			return {};
		}
		if (queryString.indexOf('?') === 0) {
			queryString = queryString.substring(1);
		}
		try {
			return JSON.parse(
				'{"' +
					decodeURI(queryString)
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}'
			);
		} catch (e) {}
		return {};
	},

	/**
	 * 
	 * @param {object} obj 
	 * @returns {string}
	 */
	objectToQueryString(obj) {
		const keyValuePairs = [];
	  
		for (let key in obj) {
		  if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				const encodedKey = encodeURIComponent(key);
				const encodedValue = encodeURIComponent(value);
				keyValuePairs.push(`${encodedKey}=${encodedValue}`);
		  }
		}
	  
		return keyValuePairs.join('&');
	},

	/**
	 * 
	 * @returns {String}
	 */
	getImageBasePath() {
		const path = process.env.NODE_ENV === 'local' ? '' : '/admin';
		return path;
	}
};

export default GeneralHelper;
