import { push } from 'connected-react-router';
import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

export function getExpenses(options) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_EXPENSES_REQUEST,
			payload: { loading: true }
		});

		const response = await API.expense.getExpenses({ ...options });

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_EXPENSES_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { total, page, limit, count, expenses, total_receipt } = response.data;

		dispatch({
			type: ActionTypes.GET_EXPENSES_SUCCESS,
			payload: {
				total,
				page,
				limit,
				count,
				expenses,
				total_receipt,
				loading: false
			}
		});
	};
}

export function getExpenseById({ id, query }) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_EXPENSE_BASIC_REQUEST,
			payload: { loadingExpense: true }
		});

		if (query && query.id && query.id.length < 24) {
			dispatch({
				type: ActionTypes.GET_EXPENSE_BASIC_ERROR,
				payload: {
					meta: {
						code: 404,
						message: 'Expense not found'
					},
					loadingExpense: false
				}
			});
			dispatch(push('/expenses'));
			return;
		}

		const response = await API.expense.getExpenseById(id);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_EXPENSE_BASIC_ERROR,
				payload: {
					meta: response.meta,
					loadingExpense: false
				}
			});
			
			if (query && query.id) {
				dispatch(push('/expenses'));
			}
			return;
		}

		const { expense } = response.data;

		dispatch({
			type: ActionTypes.GET_EXPENSE_BASIC_SUCCESS,
			payload: {
				expense,
				loadingExpense: false
			}
		});

		if (query && query.id) {
			dispatch(push('/expenses'));
		}
	};
}

export function onSubmit(fields) {
	return async (dispatch, getState) => {
		const { expense } = getState().expensePageReducer;

		dispatch({
			type: ActionTypes.SUBMIT_EXPENSE_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const body = {
			expense: {
				...fields
			}
		};

		let response;
		if (expense) {
			body.expense.updated_at = expense.updated_at;
			response = await API.expense.updateExpenseById(expense._id, body);
		} else {
			response = await API.expense.createExpense(body);
		}

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_EXPENSE_ERROR,
				payload: {
					meta: response.meta,
					loadingSubmit: false
				}
			});
			return;
		}

		dispatch({
			type: ActionTypes.SUBMIT_EXPENSE_SUCCESS,
			payload: {
				meta: response.meta,
				loadingSubmit: false
			}
		});

		dispatch(push('/app/expenses'));
	};
}

export function exportExpenses(options) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.DOWNLOAD_EXPENSES_REQUEST,
			payload: { loadingExport: true }
		});

		const response = await API.expense.exportExpenses(options);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.DOWNLOAD_EXPENSES_ERROR,
				payload: {
					meta: response.meta,
					loadingExport: false
				}
			});
			return;
		}

		const { csv_string } = response.data;

		dispatch({
			type: ActionTypes.DOWNLOAD_EXPENSES_SUCCESS,
			payload: {
				meta: response.meta,
				csv_string,
				loadingExport: false,
				hasDownloadSuccess: true
			}
		});
	};
}

export function getPaymentOptions() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_EXPENSE_PAYMENT_OPTIONS_REQUEST,
			payload: {
				loading: true
			}
		});

		const response = await API.expense.getPaymentOptions();

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_EXPENSE_PAYMENT_OPTIONS_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { payment_option } = response.data;

		dispatch({
			type: ActionTypes.GET_EXPENSE_PAYMENT_OPTIONS_SUCCESS,
			payload: {
				paymentOptions: payment_option,
				loading: false
			}
		});
	};
}

export function resetSelectedExpense() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_SELECTED_EXPENSE,
			payload: {
				expense: null
			}
		});
	};
}

export function deleteExpense(id) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.DELETE_EXPENSE_REQUEST,
			payload: {
				loadingDelete: true
			}
		});

		const response = await API.expense.deleteExpense(id);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.DELETE_EXPENSE_ERROR,
				payload: {
					meta: response.meta,
					loadingDelete: false
				}
			});
			return;
		}

		dispatch({
			type: ActionTypes.DELETE_EXPENSE_SUCCESS,
			payload: {
				loadingDelete: false,
				meta: response.meta
			}
		});

		dispatch(push('/app/expenses'));
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_EXPENSES_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_EXPENSES_PAGE
		});
	};
}

export function resetHasDownload() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_HAS_DOWNLOAD,
			payload: {
				hasDownloadSuccess: false
			}
		});
	};
}
