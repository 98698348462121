import { push } from 'connected-react-router';
import * as ActionTypes from '../../constants/ActionTypes';
import API from '../../helpers/api';
import account_link from '../../helpers/api/account_link';

export function getAccountLinkById(id) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_ACCOUNT_LINK_BASIC_REQUEST,
			payload: { loading: true }
		});

		const response = await API.account_link.getAccountLinkById(id);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_ACCOUNT_LINK_BASIC_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { account_link } = response.data;

		dispatch({
			type: ActionTypes.GET_ACCOUNT_LINK_BASIC_SUCCESS,
			payload: {
				accountLink: account_link,
				loading: false
			}
		});
	};
}

export function checkAccountStatus(id) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_ACCOUNT_STATUS_BASIC_REQUEST,
			payload: { loading_status: true }
		});

		const response = await API.account_link.getAccountLinkById(id);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_ACCOUNT_STATUS_BASIC_ERROR,
				payload: {
					meta: response.meta
				}
			});
			return;
		}

		const { account_link } = response.data;

		dispatch({
			type: ActionTypes.GET_ACCOUNT_STATUS_BASIC_SUCCESS,
			payload: {
				accountLink: account_link
			}
		});
	};
}

export function deleteAccountLinkById(id) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.DELETE_ACCOUNT_LINK_REQUEST,
			payload: { loading: true }
		});

		const response = await API.account_link.deleteAccountLinkById(id);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.DELETE_ACCOUNT_LINK_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		dispatch({
			type: ActionTypes.DELETE_ACCOUNT_LINK_SUCCESS,
			payload: {
				meta: response.meta,
				loading: false
			}
		});
		dispatch(push('/account-links'));
	};
}

export function onSubmit(fields) {
	return async (dispatch, getState) => {
		const { accountLink } = getState().accountLinkDetailPage.accountLinkBasicPageReducer;

		dispatch({
			type: ActionTypes.SUBMIT_ACCOUNT_LINK_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const body = {
			account_link: {
				...fields
			}
		};

		let response;
		if (accountLink) {
			body.account_link.updated_at = accountLink.updated_at;
			response = await API.account_link.updateAccountLinkById(accountLink._id, body);
		} else {
			response = await API.account_link.createAccountLink(body);
		}

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_ACCOUNT_LINK_ERROR,
				payload: {
					meta: response.meta,
					loadingSubmit: false
				}
			});
			return;
		}

		const { account_link: newAccountLink } = response.data;

		dispatch({
			type: ActionTypes.SUBMIT_ACCOUNT_LINK_SUCCESS,
			payload: {
				meta: response.meta,
				accountLink: newAccountLink,
				loadingSubmit: false
			}
		});

		dispatch(push(`/account-links/id?id=${newAccountLink._id}`));
	};
}

export function resendEmailVerification(id){
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESEND_VERIFICATION_REQUEST,
			payload: {
				loadingResendEmail: true
			}
		});

		const body = {
			account_link: {
				id
			}
		};

		const response = await account_link.resendEmailVerification(body);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.RESEND_VERIFICATION_ERROR,
				payload: {
					meta: response.meta,
					loadingResendEmail: false
				}
			});
			return;
		}

		dispatch({
			type: ActionTypes.RESEND_VERIFICATION_SUCCESS,
			payload: {
				meta: response.meta,
				loadingResendEmail: false
			}
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_ACCOUNT_LINK_BASIC_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_ACCOUNT_LINK_BASIC_PAGE
		});
	};
}