import { connect } from 'react-redux';
import { connectToQuickBooks } from '../../actions/ConnectToQuickBookCallBackPage';
import ConnectToQuickBookCallBackPage from './ConnectToQuickBookCallBackPage';

const mapsStateToProps = state => ({
});

const mapsDispatchToProps = dispatch => ({
	connectToQuickBooks(param) {
		dispatch(connectToQuickBooks(param));
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(ConnectToQuickBookCallBackPage);
