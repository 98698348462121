import { connect } from 'react-redux';
import { proceedCheckout, resetPage, resetMeta } from '../../actions/PricingPage';
import PricingPage from './PricingPage';

const mapsStateToProps = state => ({
	pricingPageProps: state.pricingPageReducer
});

const mapsDispatchToProps = dispatch => ({
	proceedCheckout(options) {
		dispatch(proceedCheckout(options));
	},
	resetPage() {
		dispatch(resetPage());
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(PricingPage);
