const LabelHelper = {
	expenseChannel(channel) {
		switch (channel) {
			case 'email':
				return 'Email';
			case 'whatsapp':
				return 'WhatsApp';
			case 'web_upload':
				return 'Web Upload';
			default:
				return '';
		}
	},
	expenseCurrency(currency) {
		switch (currency) {
			case 'aud':
				return 'AUD $';
			case 'cny':
				return 'CNY ¥';
			case 'gbp':
				return 'GBP £';
			case 'hkd':
				return 'HKD $';
			case 'jpy':
				return 'JPY ¥';
			case 'usd':
				return 'USD $';
			case 'cad':
				return 'CAD $';
			default:
				return '';
		}
	},
	expenseCategory(category_selection) {
		switch (category_selection) {
			case 'benefits':
				return 'Benefits';
			case 'car':
				return 'Car';
			case 'equipment':
				return 'Equipment';
			case 'fees':
				return 'Fees';
			case 'home_office':
				return 'Home Office';
			case 'insurance':
				return 'Insurance';
			case 'interest':
				return 'Interest';
			case 'labor':
				return 'Labor';
			case 'maintenance':
				return 'Maintenance';
			case 'materials':
				return 'Materials';
			case 'meals_and_entertainment':
				return 'Meals and Entertainment';
			case 'office_supplies':
				return 'Office Supplies';
			case 'professional_services':
				return 'Professional Services';
			case 'rent':
				return 'Rent';
			case 'taxes':
				return 'Taxes';
			case 'travel':
				return 'Travel';
			case 'utilities':
				return 'Utilities';
			case 'other':
				return 'Other';
			default:
				return '';
		}
	},
	paymentMethod(payment) {
		switch (payment) {
			case 'cash':
				return 'Cash';
			case 'credit_debit_card':
				return 'Credit/Debit Card';
			case 'bank_transfer':
				return 'Bank Transfer';
			case 'octopus':
				return 'Octopus';
			case 'alipay':
				return 'Alipay';
			case 'wechat_pay':
				return 'Wechat Pay';
			case 'cheque':
				return 'Cheque';
			case 'other':
				return 'Other';
			default:
				return '';
		}
	},
	accountChannel(channel) {
		switch (channel) {
			case 'email':
				return 'Email';
			case 'whatsapp':
				return 'WhatsApp';
			default:
				return '';
		}
	},
	accountStatus(status) {
		switch (status) {
			case 'verified':
				return 'Verified';
			case 'unverified':
				return 'Pending Verification';
			default:
				return '';
		}
	},
	subscriptionPlan(plan) {
		switch (plan) {
			case 'monthly':
				return 'Monthly';
			default:
				return '';
		}
	},
	subscriptionStatus(status) {
		switch (status) {
			case 'trialing':
				return 'Free Trial';
			case 'active':
				return 'Active';
			case 'canceled':
				return 'Canceled';
			case 'paid':
				return 'Paid';
			default:
				return '';
		}
	},
	invoiceStatus(status) {
		switch (status) {
			case 'draft':
				return 'Draft';
			case 'open':
				return 'Open';
			case 'paid':
				return 'Paid';
			case 'void':
				return 'Void';
			case 'uncollectible':
				return 'Uncollectible';
			default:
				return '';
		}
	}
};

export default LabelHelper;
