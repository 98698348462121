import { useEffect, useState } from 'react';

const useUpdatePaymentMethodSuccess = (subscription_success) => {
	const subscriptionSuccess = localStorage.getItem(subscription_success) === 'true' || '';
	const [successSubscription, setPaymentSuccess] = useState(subscriptionSuccess);

	const updatePaymentSuccess = (args) => {
		setPaymentSuccess(args === 'yes');
	};

	useEffect(() => {
		localStorage.setItem(subscription_success, successSubscription);
	}, [subscription_success, successSubscription]);

	return [successSubscription, updatePaymentSuccess];
};

export default useUpdatePaymentMethodSuccess;
