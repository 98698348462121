// Import necessary modules
import React from 'react';
import { Button } from 'antd'; // Assuming you're using Ant Design
import config from '../../config';
import xeroLogo from './xero.png'; // replace with the actual path

// Create XeroButton component
const XeroButton = () => {
	const xeroAuthUrl = () => {
		return `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${config.xeroAuth.clientId}&redirect_uri=${encodeURIComponent(config.xeroAuth.redirectUri)}&scope=${encodeURIComponent(config.xeroAuth.scopes)}&state=123&prompt=login`;
	};
	return (
		<Button
			block
			size="large"
			onClick={() => {
				window.location.href = xeroAuthUrl();
			}}
			style={{ height: 56, color: '#222222', padding: 0 }}
		>
			<img
				src={xeroLogo}
				alt="xero-logo"
				style={{ width: 56, marginRight: 8 }}
			/>
			Sign in with Xero
		</Button>
	);
};

export default XeroButton;