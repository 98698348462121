import React from 'react';
import { Modal, Button } from 'antd';
import congratulations_modal_logo from '../../images/congratulations_modal_logo.svg';
import './RenewedSubscriptionModal.less';


const RenewedSubscriptionModal = ({ open, buttonText, description, onClose  }) => {
	return (
		<Modal
			closeIcon={false}
			className="custom-modal"
			style={{ width: 450, height: 'auto', borderRadius: 10 }}
			open={open}
			footer={
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
					<Button
						style={{ padding: '16px 134px', width: '100%', height: 'auto', fontSize: 16, fontWeight: 600, borderRadius: 10 }}
						type="primary"
						onClick={onClose}
					>
						{buttonText}
					</Button>
				</div>
			}
			title={
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
					<img
						src={congratulations_modal_logo}
						alt="congratulations_modal_logo"
						style={{ width: 75, height: 'auto' }}
					/>
				</div>
			}
		>
			<span style={{ fontSize: 20, fontWeight: 600, marginBottom: 16, color: '#344E41', display: 'flex', justifyContent: 'center' }}>
                Congratulations!				
			</span>
			<p style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '0 16px', whiteSpace: 'pre-line' }}>
				{description}
			</p>
		</Modal>
	);
};

export default RenewedSubscriptionModal;