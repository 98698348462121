import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	total: 0,
	page: 1,
	limit: 10,
	count: 0,
	accountLinks: [],
	loading: false
};

export default function accountLinkPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ACCOUNT_LINKS_REQUEST:
		case ActionTypes.GET_ACCOUNT_LINKS_ERROR:
		case ActionTypes.GET_ACCOUNT_LINKS_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_ACCOUNT_LINKS_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_ACCOUNT_LINKS_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
