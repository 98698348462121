import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	total: 0,
	page: 1,
	limit: 10,
	count: 0,
	expenses: [],
	expense: null,
	total_receipt: 0,
	loadingExpense: false,
	loadingSubmit: false,
	loadingDelete: false,
	loading: false,
	csv_string: [],
	loadingExport: false,
	hasDownloadSuccess: false,
	paymentOptions: null,
	subscription: null
};

export default function expensePageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_REQUEST:
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR:
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS:
		case ActionTypes.GET_EXPENSES_REQUEST:
		case ActionTypes.GET_EXPENSES_ERROR:
		case ActionTypes.GET_EXPENSES_SUCCESS:
		case ActionTypes.GET_EXPENSE_PAYMENT_OPTIONS_REQUEST:
		case ActionTypes.GET_EXPENSE_PAYMENT_OPTIONS_ERROR:
		case ActionTypes.GET_EXPENSE_PAYMENT_OPTIONS_SUCCESS:
		case ActionTypes.DOWNLOAD_EXPENSES_REQUEST:
		case ActionTypes.DOWNLOAD_EXPENSES_ERROR:
		case ActionTypes.DOWNLOAD_EXPENSES_SUCCESS:
		case ActionTypes.GET_EXPENSE_BASIC_REQUEST:
		case ActionTypes.GET_EXPENSE_BASIC_ERROR:
		case ActionTypes.GET_EXPENSE_BASIC_SUCCESS:
		case ActionTypes.RESET_SELECTED_EXPENSE:
		case ActionTypes.DELETE_EXPENSE_REQUEST:
		case ActionTypes.DELETE_EXPENSE_ERROR:
		case ActionTypes.DELETE_EXPENSE_SUCCESS:
		case ActionTypes.SUBMIT_EXPENSE_REQUEST:
		case ActionTypes.SUBMIT_EXPENSE_ERROR:
		case ActionTypes.SUBMIT_EXPENSE_SUCCESS:
		case ActionTypes.RESET_HAS_DOWNLOAD:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_EXPENSES_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_EXPENSES_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
