import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { stat } from 'fs-extra';
import Drawer from '../../components/Drawer';
import NavigationHeader from '../../components/Header';

const { Content, Footer } = Layout;

const NavigationLayout = (props)  => {
	const [collapsed, setCollapsed] = useState(false);
	const { getActiveSubscription, parentLayoutProps, children } = props;
	const { subscription, loadingSubscription } = parentLayoutProps;
 	const { location, history } = children?.props;
	useEffect(() => {
		getActiveSubscription();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------- Start of Functions --------------------------------- //
	// ---------------------------------- End of Functions ---------------------------------- //

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Drawer
				location={location}
				handleOnCollapse={setCollapsed}
				collapsed={collapsed}
			/>
			<Layout style={{ backgroundColor: '#F5F5F5' }}>
				<NavigationHeader
					history={history}
					subscription={subscription}
					loadingSubscription={loadingSubscription}
				/>
				<Content style={{ margin: '28px 28px 0px' }}>{children}</Content>
				<Footer style={{ padding: 0, margin: '20px 0px 10px', textAlign: 'center', fontSize: 16  }}>
					© 2023 NekoExpense. All rights reserved.
				</Footer>
			</Layout>
		</Layout>
	);
};

export default NavigationLayout;
