import React, { useEffect } from 'react';
import UrlParse from 'url-parse';
import { Row } from 'antd';

import useNotification from '../../../helpers/hooks/useNotification';

// Components
import Card from '../../../components/Card';
import AccountLinkBasicForm from './components/AccountLinkBasicForm';

const AccountLinkBasicPage = (props) => {
	const {
		location,
		accountLinkBasicPageProps,
		getAccountLinkById,
		checkAccountStatus,
		resetMeta
	} = props;
	const { loading, meta, accountLink } = accountLinkBasicPageProps;
	const { status } = accountLink || {};
	const { query } = UrlParse(location.search, true);

	// Handle notifications
	useNotification(meta, resetMeta);

	useEffect(() => {
		if(meta && meta.code !== 200){
			query.id && getAccountLinkById(query.id);
		}
	}, [getAccountLinkById, meta, query.id]);

	useEffect(() => {
		const intervalId = setInterval(() => {
		  if (accountLink && status) {
				if (status !== 'verified') {
			  		query.id && checkAccountStatus(query.id);
				}
		  }
		}, 5000);
	  
		return () => clearInterval(intervalId);
	  }, [accountLink, checkAccountStatus, query.id, status]);

	return (
		<Card
			title={query?.id ? 'Account Link Details' : 'New Account'}
			loading={loading}
		>
			<Row justify="center">
				<AccountLinkBasicForm 
					{...props}
					accountLink={accountLink}
				/>
			</Row>
		</Card>
	);
};

export default AccountLinkBasicPage;