import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Select, Row, Col, Typography, Divider, Modal, Spin } from 'antd';
import qr from 'qrcode';
import { CheckCircleOutlined, InfoCircleOutlined, CheckCircleFilled  } from '@ant-design/icons';
import _ from 'lodash';

import UrlParse from 'url-parse';
import CommonEnum from '../../../../constants/CommonEnum';
import LabelHelper from '../../../../helpers/label';
import countryCallingCodes from '../../../../constants/CountryCallingCodes';
import LocalStorageHelper from '../../../../helpers/local_storage';

const { Item } = Form;
const { Text } = Typography;

const AccountLinkBasicForm = (props) => {
	// Props
	const { accountLinkBasicPageProps, onSubmit, resendEmailVerification , location, deleteAccountLinkById } = props;
	const { accountLink, loadingSubmit, loadingResendEmail, meta, loading_status } = accountLinkBasicPageProps;
	const { channel, email, country_code, whatsapp_number, status, verification_token } = accountLink || '';
	const formattedCountryCode =country_code ? country_code.match(/\d+/g).join('') : '';
	const plainPhoneNumber = whatsapp_number && country_code ? whatsapp_number.replace(formattedCountryCode, '') : '';
	const [qrCodeData, setQRCodeData] = useState('');

	const formRef = useRef();

	// States
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [selectedChannel, setSelectedChannel] = useState(accountLink?.channel || null); // Initialize selectedChannel based on accountLink data
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const [timer, setTimer] = useState(LocalStorageHelper.getTime());	//	default from LocalStorage
	const { query } = UrlParse(location.search, true);
	const user = LocalStorageHelper.getUser();

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	// Use Effects
	useEffect(() => {

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// generate qrcode
	useEffect(() => {
		if (accountLink && verification_token) {
			const url = `https://api.whatsapp.com/send?phone=${accountLink.nekoexpense_whatsapp_number}&text=${verification_token}`;

			qr.toDataURL(url, (err, qrCodeDataURL) => {
		  if (err) {
					console.error(err);
					return;
		  }

		  setQRCodeData(qrCodeDataURL);
			});
		}
	}, [accountLink, verification_token]);

	// UseEffects
	useEffect(() => {
		if(accountLink && accountLink.status === 'unverified') {
			if(timer > 0 ){
				const intervalId = setInterval(() => {
					setTimer((prevTimer) => {
						const newTimer = prevTimer - 1;
						LocalStorageHelper.setTime(newTimer);
						return newTimer;
					}
					);
				}, 1000);

				if (timer === 0) {
					clearInterval(intervalId);
				}

				return () => {
					clearInterval(intervalId);
				};
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timer]);

	// Disable the "Submit" button if the previous request is success
	useEffect(() => {
		if(meta){
			if (meta.code === 200) {
				setDisabled(true);
			}

			if(meta.code === 200 && meta.message === 'Resend Verification Success') {
				setTimer(24);
			}
		}
	}, [meta]);

	// Use useEffect to set selectedChannel based on accountLink data
	useEffect(() => {
		setSelectedChannel(accountLink?.channel || '');
		// setSelectedCode(accountLink?.code || '');
	}, [accountLink]);

	// --------------------------------- Start of Functions --------------------------------- //

	const checkFormValues = () => {
		if (
			formRef.current.getFieldValue('channel') === 'email' &&
			formRef.current.getFieldValue('email')
		) {
			setDisabled(false);
		} else if (
			formRef.current.getFieldValue('channel') === 'whatsapp' &&
			!_.isEmpty(formRef.current.getFieldValue('whatsapp').country_code) &&
			!_.isEmpty(formRef.current.getFieldValue('whatsapp').number)
		) {
			setDisabled(false);
		}
		else {
			setDisabled(true);
		}
	};

	const handleOnSubmit = (values) => {
		const { channel, email, whatsapp } = values;
		const fields = {
		  channel,
		  email,
		  country_code: whatsapp?.country_code,
		  whatsapp_number: whatsapp?.number
		};
		onSubmit(fields);
		LocalStorageHelper.setTime(24);
	};

	const validateEmail = (_, value) => {
		if (value && !/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(value)) {
			setDisabled(true);
			return Promise.reject('Please enter a valid email address');
		}
		setDisabled(false);
		return Promise.resolve();
	  };

	// Format the timer as mm:ss
	const formatTimer = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

	const renderDeleteModal = () => {
		return <Modal
			title="Delete Account Link"
			open={openDeleteModal}
			onOk={()=>{
				deleteAccountLinkById(query.id);
				setOpenDeleteModal(false);
			}}
			okText= "Yes"
			cancelText="No"
			onCancel={()=>  setOpenDeleteModal(false)}
		       >
			<p>Please confirm deletion of this account. Deleting it will halt any ongoing conversations.</p>
		</Modal>;
	};


	const showMessage = (message, acc) => {
		return<div
			style={{
				display: 'flex',
				border: '1px solid',
				background: '#F6FFED',
				borderRadius: '10px',
				padding: '20px 20px',
				justifyContent: 'space-between',
				borderColor: '#B7EB8F'
			}}
		      >
			<CheckCircleFilled style={{ color: '#52c41a',  marginRight: 10 }} />
			<span>
				{message}
				<span style={{ color: 'orange' }}> {acc}</span>
			</span>
		</div>;
	};
	// ---------------------------------- End of Functions ---------------------------------- //

	return (
		<div style={{ maxWidth: 485 }}>
			{renderDeleteModal()}
			<div style={{ width: windowWidth < 700 ? '100%' : 485 }}/>
			<Form
				style={{ width: '100%' }}
				layout="vertical"
				initialValues={{
					channel,
					email,
					whatsapp: {
						country_code,
						number: plainPhoneNumber
					}
				}}
				onFinish={handleOnSubmit}
				ref={formRef}
			>
				<Row
					justify="space-between"
					gutter={8}
				>
					<Col span={24}>
						<Item
							name="channel"
							label="Channel"
							rules={[{ message: 'Channel is required', required: true }]}
						>
							<Select
								showSearch
								placeholder="Select Channel"
								options={CommonEnum.account_channel}
								filterOption={(input, option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								disabled={ status === 'verified' || (accountLink && accountLink._id)}
								onChange={value => {
									if (value === 'email') {
										formRef.current.setFieldValue('email', null);
									} else if (value === 'whatsapp') {
										formRef.current.setFieldValue('whatsapp', {
											country_code: undefined,
											number: undefined
										});
									}

									setSelectedChannel(value);
									checkFormValues();
								}}
							/>
						</Item>
					</Col>
				</Row>
				{selectedChannel === 'email' && (
					<Item
						name="email"
						label="Email"
						rules={[
							{ message: 'Email is required', required: selectedChannel === 'email' },
							{ validator: validateEmail }
						]}
						onChange={checkFormValues}
					>
						<Input
							placeholder="Enter Email"
							suffix={
								status === 'verified' ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<CheckCircleOutlined style={{ color: 'orange' }} />
										<span style={{ marginLeft: '5px', color: 'orange' }}>
											{LabelHelper.accountStatus('verified')}
										</span>
									</div>
								) : (
									<div style={{ color: '#ccc', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
										{LabelHelper.accountStatus('unverified')}
										{
											accountLink && loading_status && (
												<Spin
													size="small"
													style={{ marginLeft: 10 }}
												/>
											)
										}
									</div>
								)
							}
							disabled= { status === 'verified' || (accountLink && accountLink._id)}
						/>
					</Item>
				)}
				{selectedChannel === 'whatsapp' && (
					<Item
						label="Whatsapp Number"
						required
					>
						<Row
							justify="space-between"
							gutter={8}
						>
							<Col
								lg={6}
								md={6}
								sm={24}
								xs={24}
							>
								<Item
									name={['whatsapp', 'country_code']}
									rules={[
										{
											required: selectedChannel === 'whatsapp',
											message: 'Country Code is required'
										}
									]}
									disabled={status === 'verified' || (accountLink && accountLink._id)}
								>
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => (option?.label ?? '').includes(input)}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={_.uniqBy(countryCallingCodes, 'value')}
										style={{
											width: '100%'
										}}
										disabled={ status === 'verified' || (accountLink && accountLink._id)}
										placeholder="+00"
										onChange={checkFormValues}
										onKeyPress={(event) => {
											if (!/[0-9-+]/.test(event.key)) {
												event.preventDefault();
											}
										}}
										onPaste={(event) => {
											event.preventDefault();
										}}
									/>
								</Item>
							</Col>
							<Col
								lg={18}
								md={18}
								sm={24}
								xs={24}
							>
								<Item
									name={['whatsapp', 'number']}
									rules={[
										{
											required: selectedChannel === 'whatsapp',
											message: 'WhatsApp Number is required'
										}
									]}
									onChange={checkFormValues}
									disabled={ status === 'verified' || (accountLink && accountLink._id)}
								>
									<Input
										style={{ width: '100%' }}
										min={0}
										placeholder="WhatsApp Number"
										suffix={
											status === 'verified' ? (
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<CheckCircleOutlined style={{ color: 'orange' }} />
													<span style={{ marginLeft: '5px', color: 'orange' }}>
														{LabelHelper.accountStatus('verified')}
													</span>
												</div>
											) : (
												<div style={{ color: '#ccc', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
													{LabelHelper.accountStatus('unverified')}
													{
														accountLink && loading_status && (
															<Spin
																size="small"
																style={{ marginLeft: 10 }}
															/>
														)
													}
												</div>
											)
										}
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
												event.preventDefault();
											}
										}}
										onPaste={(event) => {
											const text = event.clipboardData.getData('text/plain');
											if (text && !/^\d+$/.test(text)) {
												event.preventDefault();
											}
										}}
										disabled={status === 'verified' || (accountLink && accountLink._id)}
									/>
								</Item>
							</Col>
						</Row>
					</Item>
				)}

				{
					query.id && channel === 'email' && email && email === user.email ? '' :
						(
							<Item style={{ marginTop: 20 }}>
								<Row justify={query.id ? 'space-between' : 'end'}>
									{query.id && (
										<Button
											style={{ width: 100 }}
											onClick={() => setOpenDeleteModal(true)}
										>
            							Delete
										</Button>
									)}
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: 100 }}
										loading={loadingSubmit}
										disabled={disabled || status === 'verified' || loadingSubmit || (accountLink && accountLink._id)}
									>
										Save
									</Button>
								</Row>
							</Item>
						)
				}
			</Form>

			{accountLink && accountLink.status === 'verified' && accountLink.channel === 'email' ?
				showMessage('You may now start sending your receipts or expenses to this email', accountLink.nekoexpense_email_address) :
				accountLink && accountLink.status === 'verified' && accountLink.channel === 'whatsapp' ?
					showMessage ('You may now start sending your receipts or expenses to this WhatsApp number', accountLink.nekoexpense_whatsapp_number):
					''
			}

			{accountLink && accountLink.channel === 'email' && accountLink.status !== 'verified' && ( // Display the message only if accountLink exists and channel is email
				<div style={{ background: '#fff7e0', borderRadius: '10px', padding: '20px 20px' }}>
					<Row justify="space-between">
						<Col span={2}>
							<InfoCircleOutlined style={{ color: 'orange' }} />
						</Col>
						<Col span={22}>
							<Text>We have sent a verification link to your email.</Text>
							<Row style={{ marginTop: 20 }}>
								<Text type="secondary">Didn't receive email?</Text>
							</Row>
							<Row justify="space-between">
								<Col span={15}>
									<Text type="secondary">Resend verification in {formatTimer(timer)}</Text>
								</Col>
							</Row>
							<Row
								style={{ marginTop: 15 }}
								justify="end"
							>
								<Button
									type="primary"
									disabled={timer === 0 ? false : true}
									loading={loadingResendEmail}
									style={{ marginLeft: 'auto' }}
									onClick={() => {
										accountLink && resendEmailVerification(accountLink._id);
									}}
								>
									Resend Verification
								</Button>
							</Row>
						</Col>
					</Row>
				</div>
			)}
			{accountLink && accountLink.channel === 'whatsapp' && accountLink.status !== 'verified' && ( // Display the message only if accountLink exists and channel is whatsapp
				<>
					<div style={{ display: 'flex', alignItems: 'center', background: '#fff7e0', borderRadius: '10px' }}>
						<InfoCircleOutlined style={{ color: 'orange', margin: '10px' }} />
						<p style={{ marginTop: '10px', marginBottom: '10px' }}>
						To verify your WhatsApp, please choose and follow the methods below.
						</p>
					</div>
					<div style={{ background: '#344E41', padding: 25, borderRadius: 20, color: '#ffffff', marginTop: 25 }}>
						<div style={{ marginBottom: 15 }}>
							<Text style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
								Method 1
							</Text>
						</div>
						<div>
							<Text style={{ color: '#FFFFFF' }}>
								<strong style={{ fontSize: 14 }}>Step 1:</strong> <small>Scan the QR Code below with Camera App</small>
							</Text>
						</div>
						<Row justify="center">
							{qrCodeData && (
								<img
									src={qrCodeData}
									alt="QR Code"
									style={{ paddingTop: 10, paddingBottom: 15 }}
								/>
							)}
						</Row>
						<div>
							<Text style={{ color: '#FFFFFF' }}>
								<strong style={{ fontSize: 14 }}>Step 2:</strong> <small>Click "Send" in Whatsapp</small>
							</Text>
						</div>
					</div>
					<Divider style={{ margin: '10px 0px', fontSize: 16 }}>or</Divider>
					<div style={{ background: '#344E41', padding: 25, borderRadius: 20, color: '#ffffff' }}>
						<div style={{ marginBottom: 15 }}>
							<Text style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
									Method 2
							</Text>
						</div>
						<div>
							<Text style={{ color: '#FFFFFF' }}>
								<strong style={{ fontSize: 14 }}>Step 1:</strong> <small>Add contact <strong style={{ color: '#FF8A00' }}>{accountLink.nekoexpense_whatsapp_number}</strong></small>
							</Text>
						</div>
						<div>
							<Text style={{ color: '#FFFFFF' }}>
								<strong style={{ fontSize: 14 }}>Step 2:</strong> <small>Create conversation with <strong style={{ color: '#FF8A00' }}>{accountLink.nekoexpense_whatsapp_number}</strong></small>
							</Text>
						</div>
						<div>
							<Text style={{ color: '#FFFFFF' }}>
								<strong style={{ fontSize: 14 }}>Step 3:</strong> <small>Send <strong>'{verification_token && verification_token}'</strong> to <strong style={{ color: '#FF8A00' }}>{accountLink.nekoexpense_whatsapp_number}</strong></small>
							</Text>
						</div>
					</div>
					<Divider style={{ margin: '10px 0px', fontSize: 16 }}>or</Divider>
					<div style={{ background: '#344E41', padding: 25, borderRadius: 20, color: '#ffffff' }}>
						<div style={{ marginBottom: 15 }}>
							<Text style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
									Method 3
							</Text>
						</div>
						<div>
							<Text style={{ color: '#FFFFFF' }}>
								<strong style={{ fontSize: 14 }}>Step 1:</strong> <small>click <a href={`https://wa.me/${accountLink.nekoexpense_whatsapp_number}?text=${verification_token}`}>here</a> to open Whatsapp</small>
							</Text>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default AccountLinkBasicForm;
