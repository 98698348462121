/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import URLParse from 'url-parse';
import { Button, Row, Col, Table, Form, DatePicker, Input, Select, InputNumber, Upload, message, Space, Typography, Modal } from 'antd';
import { DownloadOutlined, PlusSquareFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import moment from 'moment';

// Helpers
import AssetHelper from '../../helpers/asset';
import GeneralHelper from '../../helpers/general';
import LabelHelper from '../../helpers/label';
import LocalStorageHelper from '../../helpers/local_storage';
import useUpdatePaymentMethodSuccess from '../../helpers/hooks/useUpdatePaymentMethodSuccess';
import useUpdateSubscriptionSuccess from '../../helpers/hooks/useUpdateSubscriptionSuccess';

// Others
import CommonEnum from '../../constants/CommonEnum';
import Currencies from '../../constants/Currencies';
import Card from '../../components/Card';
import ConfirmModal from '../../components/ConfirmModal';
import RenewedSubscriptionModal from '../../components/RenewedSubscriptionModal';
import useNotification from '../../helpers/hooks/useNotification';
import PixelHelper from '../../helpers/pixel';

import onBoarding1 from '../../images/1-1.svg';
import onBoarding2 from '../../images/2-2.svg';
import onBoarding3 from '../../images/3-3.svg';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Text } = Typography;

const ExpensePage = (props) => {
	// Refs
	const searchFormRef = useRef();
	const formRef = useRef();

	// States
	const [openBoarding1, setOpenBoarding1] = useState(false);
	const [openBoarding2, setOpenBoarding2] = useState(false);
	const [openBoarding3, setOpenBoarding3] = useState(false);
	const [subscriptionSuccessModal, setsubscriptionSuccessModal] = useState(false);
	const [changedPaymentSuccessModal, setChangedPaymentSuccessModal] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [files, setFiles] = useState([]);
	const [isAssetUpdated, setIsAssetUpdated] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const [allowCreate, setAllowCreate] = useState(false);
	const [deleteExpenseModal, setDeleteExpenseModal] = useState(false);
	const [successPaymentMethod, updatePaymentMethodSuccess] = useUpdatePaymentMethodSuccess('payment_method_success');
	const [successSubscription, updatePaymentSuccess] = useUpdateSubscriptionSuccess('subscription_success');
	const [formState, setFormState] = useState({
		category: null,
		payment_method: null,
		startDate: null,
		endDate: null,
		receipt_id: null
	});

	// Props
	const {
		history,
		location,
		getExpenses,
		getExpenseById,
		getPaymentOptions,
		deleteExpense,
		onSubmit,
		expensePageProps,
		resetPage,
		resetMeta,
		exportExpenses,
		resetHasDownload,
		getActiveSubscription
	} = props;

	// Other Variables
	const { total, page, limit, expenses, expense, total_receipt, paymentOptions, loading, loadingExpense, loadingSubmit, loadingDelete, meta, hasDownloadSuccess, subscription } = expensePageProps;

	const { receipt_id, channel, merchant_name, category_selection, payment_method, currency, amount, expense_date, assets, description, account_link_channel, account_link_email, account_link_whatsapp_number } = expense || '';

	const { previous_status, status } = subscription || {};

	const supportedPaymentMethods = paymentOptions ? CommonEnum.payment_methods.filter(method => paymentOptions.supported_payment_methods.includes(method.value))
		.sort((a, b) => a.label.localeCompare(b.label)) : [];

	const tempSupportedCurrencies = paymentOptions ? Currencies.filter(currency => paymentOptions.supported_currencies.includes(currency.code.toLowerCase()))
		.sort((a, b) => a.code.localeCompare(b.code)) : [];

	// Transform currencies to the desired format
	const supportedCurrencies = tempSupportedCurrencies.map(currency => ({
		label: `${currency.code}  (${currency.symbol})`,
		value: `${currency.code.toLowerCase()}`
	}));

	const { query } = URLParse(location.search, true);
	const user = LocalStorageHelper.getUser();

	const uploadProps = {
		name: 'file',
		multiple: false,
		action: AssetHelper.getActionUrl(),
		headers: AssetHelper.getHeader(),
		fileList: files,
		beforeUpload: (file) => {
			// Check if the file size
			if (file.size > AssetHelper.getMaxSize()) {
				message.error(`${file.name} ${AssetHelper.getMaxSizeMsg()}`);
				file.status = 'error';
				return false;
			}
			return true;
		},
		onChange: (info) => {
			const { status } = info.file;

			if (status === 'done') {
				messageApi.success(`${info.file.name} uploaded successfully.`);
			} else if (status === 'error') {
				messageApi.error(`${info.file.name} upload failed.`);
			}

			let fileList = [...info.fileList];

			fileList = fileList.map(file => {
				if (file.response) {
					file.url = AssetHelper.getDownloadUrl(file.response.data.asset._id);
					file.asset_id = file.response.data.asset._id;
				}
				return file;
			});

			setFiles(fileList);
		},
		onDrop() {
			setFiles(null);
		}
	};

	useNotification(meta, resetMeta);

	// Use Effects
	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		getActiveSubscription();

		if (query.id) {
			getExpenseById({ query, id: query.id });
		}

		getExpenses(query);
		const onBoardedUsers = localStorage.getItem('onBoardedUsers');
		let boardedUsers = onBoardedUsers ? JSON.parse(onBoardedUsers) : [];

		if (boardedUsers.includes(user.email)) {
			setOpenBoarding1(false);
		}
		else {
			setOpenBoarding1(true);
		}

		if (!successSubscription && status === 'paid' && (previous_status === 'trialing' || previous_status === 'active' || previous_status === 'unpaid' || previous_status === 'canceled')) {
			setsubscriptionSuccessModal(true);
			updatePaymentSuccess('yes');
		}

		if (successPaymentMethod) {
			setChangedPaymentSuccessModal(true);
		}
		getPaymentOptions();
		return () => resetPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Disable the "Submit" button if the previous request is success
	useEffect(() => {
		if (meta && meta.code === 200) {
			setDisabled(true);
		}
		if (deleteExpenseModal) {
			setDeleteExpenseModal(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);

	useEffect(() => {
		if (isAssetUpdated)
			checkFormValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	useEffect(() => {
		let newData = [];
		if (!_.isEmpty(assets)) {
			assets.map((asset) => {
				if(asset){
					newData.push({
						asset_id: asset._id,
						name: asset.file_name
					});
				}
			});

			if(!_.isEmpty(newData)){
				let fileList = [...newData];

				fileList = fileList.map(file => {
					file.url = AssetHelper.getDownloadUrl(file.asset_id);
					// eslint-disable-next-line no-self-assign
					file.asset_id = file.asset_id;
					return file;
				});
				setFiles(fileList);
			}
		}
	}, [assets]);


	useEffect(() => {
		const { csv_string: downloadCsv } = expensePageProps;

		if (hasDownloadSuccess) {
			if (downloadCsv) {
				const fileName = formState.startDate && formState.endDate ?
					`Expenses - ${moment(formState.startDate).format('YYYY-MM-DD')} - ${moment(formState.endDate).format('YYYY-MM-DD')}.xlsx` :
					'All Expenses';
				downloadXlsx({
					csv: downloadCsv,
					fileName
				});
				resetHasDownload();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasDownloadSuccess]);

	// ---------------------------------- Start of Functions ---------------------------------- //

	const handleOnSearch = values => {
		const { dates, category, payment_method, receipt_id } = values;

		formRef.current.setFieldsValue({
			channel: undefined,
			merchant_name: undefined,
			category_selection: undefined,
			payment_method: undefined,
			expense_date: undefined,
			receipt_id: undefined,
			currency: undefined,
			amount: undefined,
			description: undefined
		});

		setFormState({
			startDate: dates ? dates[0].startOf('day').toISOString() : null,
			endDate: dates ? dates[1].endOf('day').toISOString() : null,
			category: category ? category : null,
			payment_method: payment_method ? payment_method : null,
			receipt_id: receipt_id ? receipt_id : null
		});

		const searchKeys = {
			...(dates && {
				start_date: dates[0].startOf('day').toISOString(),
				end_date: dates[1].endOf('day').toISOString()
			}),
			...(category && { category }),
			...(payment_method && { payment_method }),
			...(receipt_id && { receipt_id }),
			page: 1,
			limit
		};
		const queryString = GeneralHelper.objectToQueryString(searchKeys);
		getExpenses({ ...searchKeys });
		history.push(`${location.pathname}?${queryString}`);
	};

	const checkFormValues = () => {
		let newAssetsExist;

		if (files && files.length > 0) {
			newAssetsExist = !_.isEmpty(assets) ? files.some(file => !assets.some(asset => asset._id === file.asset_id)) : true;

			if (!newAssetsExist) {
				newAssetsExist = assets.some(asset => !files.some(file => asset._id === file.asset_id));
			}
		}
		else {
			newAssetsExist = true;
		}

		if (newAssetsExist === false && isAssetUpdated === false) {
			newAssetsExist = true;
		}

		if (
			formRef.current.getFieldValue('receipt_id') &&
			formRef.current.getFieldValue('channel') &&
			formRef.current.getFieldValue('merchant_name') &&
			formRef.current.getFieldValue('category_selection') &&
			formRef.current.getFieldValue('expense_date') &&
			formRef.current.getFieldValue('currency') &&
			formRef.current.getFieldValue('amount') &&
			newAssetsExist
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const handleOnSubmit = async (values) => {
		const { receipt_id, channel, merchant_name, category_selection, currency, amount, expense_date, payment_method, description } = values;

		let assets = [];
		if (files) {
			files.map(asset => assets.push(asset.asset_id));
		}

		const formattedExpenseDate = expense_date.format('YYYY-MM-DD HH:mm:ss');
		const fields = {
			receipt_id,
			channel,
			merchant_name,
			category_selection,
			payment_method,
			currency,
			amount,
			expense_date: formattedExpenseDate,
			...(!_.isEmpty(files) && { asset_id: assets }),
			description
		};

		onSubmit(fields);
		setIsAssetUpdated(false);
	};

	const renderSearchForm = () => {
		return (
			<Form
				layout="inline"
				onFinish={handleOnSearch}
				initialValues={{
					...query,
					...(query.start_date && query.end_date && { dates: [dayjs(query.start_date), dayjs(query.end_date)] })
				}}
				ref={searchFormRef}
				style={{ width: '100%', justifyContent: 'flex-end' }}
			>
				<Item
					style={{ margin: 4 }}
					name="category"
				>
					<Select
						placeholder="Category"
						options={CommonEnum.category_selection}
						filterOption={(input, option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						onChange={(value) => {
							searchFormRef.current.submit();
							//if (value === null)
							//	getExpenses(query);
						}}
						allowClear
					/>
				</Item>
				<Item
					style={{ margin: 4 }}
					name="payment_method"
				>
					<Select
						placeholder="Payment Method"
						options={supportedPaymentMethods}
						filterOption={(input, option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						onChange={(value) => {
							searchFormRef.current.submit();
							if (value === null)
								getExpenses(query);
						}}
						allowClear
					/>
				</Item>

				<Item
					style={{ margin: 4 }}
					name="dates"
				>
					<RangePicker
						allowClear
						placeholder={['Start Date', 'End Date']}
						onChange={(value) => {
							searchFormRef.current.submit();
							if (value === null)
								getExpenses(query);
						}}
						inputReadOnly={true}
						onKeyDown={(event) => event.preventDefault()}
						onCopy={false}
						onDrag={false}
						onDrop={false}
						onPaste={false}
					/>
				</Item>
				<Item
					style={{ margin: 4, width: '115px' }}
					name="receipt_id"
				>
					<Input
						placeholder="Receipt ID"
						allowClear
						onChange={() => {
							let receipt = searchFormRef.current.getFieldValue('receipt_id');
							if (receipt.length === 0) searchFormRef.current.submit();
						}}
						onPressEnter={() => searchFormRef.current.submit()}
					/>
				</Item>
				<Item style={{ margin: 4 }}>
					<Button
						type="primary"
						icon={<DownloadOutlined />}
						disabled={_.isEmpty(expenses)}
						onClick={() => exportExpenses(formState)}
					>
						{_.isEmpty(expenses) ? (
							<span>Export</span>
						) : (
							<span>
								Export
							</span>
						)}
					</Button>
				</Item>
				<Item style={{ margin: 4 }}>
					<Button
						type="primary"
						icon={<PlusSquareFilled />}
						onClick={() => {
							//	clear fields
							formRef.current.setFieldsValue({
								channel: 'web_upload',
								merchant_name: undefined,
								category_selection: undefined,
								payment_method: undefined,
								expense_date: undefined,
								receipt_id: undefined,
								currency: undefined,
								amount: undefined,
								description: undefined
							});

							setAllowCreate(true);
							setFiles([]);
							setDisabled(true);
							props.resetSelectedExpense();
						}}
					>
						{_.isEmpty(expenses) ? (
							<span>Add Expense</span>
						) : (
							<span>
								Add Expense
							</span>
						)}
					</Button>
				</Item>
			</Form>
		);
	};

	const renderDeleteExpenseModal = () => {
		return (
			<ConfirmModal
				open={deleteExpenseModal}
				width={400}
				title="Delete Expense"
				message="Are you sure you want to delete this expense?"
				loading={loadingDelete}
				onCancel={() => {
					setDeleteExpenseModal(false);
				}}
				onOk={() => {
					deleteExpense(expense._id);
				}}
			/>
		);
	};

	const downloadXlsx = ({ csv, fileName }) => {
		const ticketSummaryCsvRows = (Papa.parse(csv)).data;
		const wb = XLSX.utils.book_new();

		// booking summary ws
		const ticketWs = XLSX.utils.aoa_to_sheet(ticketSummaryCsvRows);
		XLSX.utils.book_append_sheet(wb, ticketWs, 'Coupons');

		if (navigator && navigator.msSaveOrOpenBlob) {
			const data = XLSX.write(wb, {
				type: 'array',
				bookType: 'xlsx'
			});
			const blob = new Blob([data]);
			navigator.msSaveOrOpenBlob(blob, fileName);
			return;
		}

		// write to b64
		const fileBase64 = XLSX.write(wb, {
			type: 'base64',
			bookType: 'xlsx'
		});

		const encodedUri = encodeURI(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileBase64}`);
		let a = document.createElement('a');
		document.body.appendChild(a);
		a.href = encodedUri;
		a.download = fileName;
		a.click();
	};

	const addOnBoardedUsers = () => {
		const onBoardedUsers = localStorage.getItem('onBoardedUsers');
		let data = onBoardedUsers ? JSON.parse(onBoardedUsers) : [];
		data.push(user.email);
		localStorage.setItem('onBoardedUsers', JSON.stringify(data));
	};

	const handleAmountPaste = (e) => {
		e.preventDefault(); // Prevent default paste behavior

		// Get the pasted text
		const pastedText = e.clipboardData.getData('text/plain');

		// Check if the pasted text adheres to the desired format
		if (/^\d+(\.\d+)?$/.test(pastedText)) {
			// Apply the pasted text to the input
			document.execCommand('insertText', false, pastedText);
		}
	};

	const handleAmountKeyDown = (e) => {
		if (e.key === '.' && formRef.current.getFieldValue('amount') === null) {
			e.preventDefault();
		}

		['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
	};

	const renderOnBoardingModal1 = () => {
		return (
			<Modal
				closeIcon={false}
				title={
					<div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
						<img
							src={onBoarding1}
							alt="onBoarding1"
							style={{ width: '80%' }}
						/>
					</div>
				}
				open={openBoarding1}
				footer={
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						<Button
							style={{ width: '374px', height: '56px', marginBottom: '6px', fontSize: '19px' }}
							type="primary"
							onClick={() => {
								setOpenBoarding1(false);
								setOpenBoarding2(true);
							}}
						>Start
						</Button>
					</div>
				}
			>
				<span style={{ fontSize: '20px', color: '#344E41', display: 'flex', justifyContent: 'center' }}> The best way to keep track of your expenses!</span>
				<span style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '36px' }}> Take the Tour to make your life easier </span>
			</Modal>
		);
	};

	const renderOnBoardingModal2 = () => {
		return (
			<Modal
				closeIcon={false}
				title={
					<div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
						<img
							src={onBoarding2}
							alt="onBoarding2"
							style={{ width: '70%' }}
						/>
					</div>
				}
				open={openBoarding2}
				footer={
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						<Button
							style={{ width: '374px', height: '56px', marginBottom: '6px', fontSize: '19px' }}
							type="primary"
							onClick={() => {
								setOpenBoarding2(false);
								setOpenBoarding3(true);
							}}
						> Next
						</Button>
					</div>
				}
			>
				<span style={{ fontSize: '20px', color: '#344E41', display: 'flex', justifyContent: 'center' }}>Link your WhatsApp or your email to get started</span>
				<span style={{ fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '36px' }}> Then follow the steps to verify your account </span>
			</Modal>
		);
	};

	const renderOnBoardingModal3 = () => {
		return (
			<Modal
				closeIcon={false}
				title={
					<div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
						<img
							src={onBoarding3}
							alt="onBoarding3"
							style={{ width: '70%' }}
						/>
					</div>
				}
				open={openBoarding3}
				footer={
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						<Button
							style={{ width: '374px', height: '56px', marginBottom: '16px', fontSize: '19px' }}
							type="primary"
							onClick={() => {
								setOpenBoarding3(false);
								addOnBoardedUsers();
							}}
						> Done
						</Button>
					</div>
				}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<span style={{ fontSize: '20px', color: '#344E41', textAlign: 'center', width: '60%' }}>After connecting your account, you can now add your new expenses through WhatsApp or Email message</span>
				</div>
			</Modal>
		);
	};

	// ---------------------------------- End of Functions ---------------------------------- //


	const columns = [
		{
			title: 'Merchant Name',
			dataIndex: 'merchant_name',
			key: 'merchant_name'
		},
		{
			title: 'Receipt ID',
			dataIndex: 'receipt_id',
			key: 'receipt_id'
		},
		{
			title: 'Channel',
			key: 'channel',
			render: data => data.account_link_channel === 'email' ? `${LabelHelper.expenseChannel(data.channel)}\n ${data.account_link_email}` : data.account_link_channel === 'whatsapp' ? `${LabelHelper.expenseChannel(data.channel)}\n +${data.account_link_whatsapp_number}` : LabelHelper.expenseChannel(data.channel)
		},
		{
			title: 'Category',
			dataIndex: 'category_selection',
			key: 'category_selection',
			render: data => LabelHelper.expenseCategory(data)
		},
		{
			title: 'Payment Method',
			dataIndex: 'payment_method',
			key: 'payment_method',
			render: data => LabelHelper.paymentMethod(data)
		},
		{
			title: 'Amount',
			key: 'amount',
			render: (_, record) => (
				<>
					{LabelHelper.expenseCurrency(record.currency)}{record.amount}
				</>
			)
		},
		{
			title: 'Date & Time',
			dataIndex: 'expense_date',
			key: 'expense_date',
			render: data => data ? dayjs(data).format('MMMM D, YYYY HH:mm:ss') : '–'
		}
	];


	return (
		<>
			{renderOnBoardingModal1()}
			{renderOnBoardingModal2()}
			{renderOnBoardingModal3()}
			{contextHolder}
			<Row gutter={[20, 20]}>
				<Col
					xs={24}
					sm={24}
					md={16}
					lg={16}
					xl={16}
				>
					<Card
						title="Expenses List"
						loading={loading}
						style={{ width: '100%' }}
					>
						<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
							<Row style={{ marginBottom: 20 }}>
								{renderSearchForm()}
							</Row>
						</div>
						<Row>
							<Col flex="auto">
								<Table
									scroll={{ x: true }}
									columns={columns}
									dataSource={!_.isEmpty(expenses) ? expenses.map(expense => (
										{
											...expense,
											key: expense._id
										}
									)) : []}
									pagination={{
										current: page,
										position: ['bottomCenter'],
										onChange: (current, pageSize) => {
											const queryObj = { ...query, page: current, limit: pageSize };
											const queryString = GeneralHelper.objectToQueryString(queryObj);
											getExpenses(queryObj);
											history.push(`${location.pathname}?${queryString}`);
										},
										total,
										pageSize: limit
									}}
									onRow={(record) => {
										return {
											onClick: () => {
												setFiles([]);
												setIsAssetUpdated(false);
												setAllowCreate(false);
												getExpenseById({ id: record.key });
											},
											className: 'clickable-row'
										};
									}}
									locale={{ emptyText: (
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<span style={{ width: '65%' }}>
												Receipts are processed by our agents during office hours (9:00-18:00 Mon-Fri GMT+8). You will be notified through email for each processed receipt.
											</span>
										</div>
									) }}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={8}
					lg={8}
					xl={8}
				>
					<Card
						title="Expenses Information"
						loading={loadingExpense}
						style={{ width: '100%' }}
					>
						<Form
							style={{ width: '100%' }}
							layout="vertical"
							initialValues={{
								receipt_id,
								channel,
								source: account_link_channel === 'email' ? `${account_link_email}` : account_link_channel === 'whatsapp' ? `+${account_link_whatsapp_number}` : '',
								merchant_name,
								category_selection,
								payment_method,
								currency,
								amount,
								expense_date: expense_date ? dayjs(expense_date) : null,
								asset_id: files ? files.asset_id : null,
								description
							}}
							onFinish={handleOnSubmit}
							ref={formRef}
						>
							{
								account_link_channel &&
								<Item
									name="source"
									label="Source"
								>
									<Input
										placeholder="Source"
										disabled={true}
									/>
								</Item>
							}
							<Item
								name="channel"
								label="Channel"
								rules={[{ message: 'Channel is required', required: true }]}
							>
								<Select
									placeholder="Select Channel"
									options={CommonEnum.channel}
									filterOption={(input, option) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={() => {
										checkFormValues();
									}}
									disabled={_.isNil(expense) && allowCreate === false}
								/>
							</Item>
							<Item
								name="merchant_name"
								label="Merchant Name"
								rules={[{ message: 'Merchant name is required', required: true }]}
								onChange={() => {
									checkFormValues();
								}}
							>
								<Input
									placeholder="Enter Merchant Name"
									disabled={_.isNil(expense) && allowCreate === false}
								/>
							</Item>
							<Item
								name="category_selection"
								label="Category Selection"
								rules={[{ message: 'Category Selection is required', required: true }]}
							>
								<Select
									placeholder="Select Category"
									options={CommonEnum.category_selection}
									filterOption={(input, option) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={() => {
										checkFormValues();
									}}
									disabled={_.isNil(expense) && allowCreate === false}
								/>
							</Item>
							<Item
								name="payment_method"
								label="Payment Method"
								rules={[{ message: 'Payment Method is required', required: true }]}
							>
								<Select
									placeholder="Select Payment Method"
									options={supportedPaymentMethods}
									filterOption={(input, option) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onChange={() => {
										checkFormValues();
									}}
									disabled={(_.isNil(expense)) && allowCreate === false}
								/>
							</Item>
							<Item
								name="expense_date"
								label="Date and Time"
								rules={[{ message: 'Date and Time is required', required: true }]}
							>
								<DatePicker
									style={{ width: '100%' }}
									placeholder="Select Date and Time"
									onChange={() => {
										checkFormValues();
									}}
									showTime
									disabled={_.isNil(expense) && allowCreate === false}
									onKeyDown={(event) => event.preventDefault()}
									onCopy={false}
									onDrag={false}
									onDrop={false}
									onPaste={false}
								/>
							</Item>
							<Item
								name="receipt_id"
								label="Receipt ID"
								rules={[{ message: 'Receipt ID is required', required: true }]}
								onChange={() => {
									checkFormValues();
								}}
							>
								<Input
									placeholder="Enter Receipt ID"
									didisabled={(_.isNil(expense)) && allowCreate === false}
								/>
							</Item>
							<Row gutter={16}>
								<Col span={8}>
									<Item
										name="currency"
										label="Currency"
										rules={[{ message: 'Currency is required', required: true }]}
									>
										<Select
											placeholder="Currency"
											options={supportedCurrencies}
											filterOption={(input, option) =>
												option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onChange={() => {
												checkFormValues();
											}}
											disabled={_.isNil(expense) && allowCreate === false}
										/>
									</Item>
								</Col>
								<Col span={16}>
									<Item
										name="amount"
										label="Amount"
										rules={[{ message: 'Amount is required', required: true }]}
										onChange={() => {
											checkFormValues();
										}}
									>
										<InputNumber
											type="number"
											style={{ width: '100%' }}
											// defaultValue="0.00"
											min="0.00"
											step="0.01"
											onChange={() => {
												checkFormValues();
											}}
											placeholder="Enter Amount"
											onPaste={handleAmountPaste}
											onKeyDown={handleAmountKeyDown}
											disabled={_.isNil(expense) && allowCreate === false}
										/>
									</Item>
								</Col>
							</Row>

							<div style={{ border: '1px solid #D9D9D9', padding: 17, borderRadius: 10 }}>
								<p style={{ fontSize: '15px', fontWeight: 590 }}>Attachment</p>
								<Dragger
									{...uploadProps}
									disabled={_.isEmpty(expense) && allowCreate === false}
									fileList={files}
									onChange={(info) => {
										const { status } = info.file;

										if (status === 'done') {
											messageApi.success(`${info.file.name} uploaded successfully.`);
										} else if (status === 'error') {
											messageApi.error(`${info.file.name} upload failed.`);
										}

										let fileList = [...info.fileList];

										fileList = fileList.map(file => {
											if (file.response) {
												file.url = AssetHelper.getDownloadUrl(file.response.data.asset._id);
												file.asset_id = file.response.data.asset._id;
											}
											else {
												file.url = AssetHelper.getDownloadUrl(file.asset_id);
												// eslint-disable-next-line no-self-assign
												file.asset_id = file.asset_id;
											}
											return file;
										});

										// Update State
										setFiles(fileList);
										setIsAssetUpdated(true);
									}}
									onRemove={(value) => {
										const updatedFiles = files.filter(file => file.asset_id !== value.asset_id);
										setFiles(updatedFiles);
									}}
									onDrop={() => { }}
									multiple={true}
								>
									<p style={{ margin: 0 }}>
										<FontAwesomeIcon
											icon={faFileUpload}
											style={{ color: '#FF8A00', fontSize: '2em' }}
										/>
									</p>
									<p style={{ margin: 0, fontSize: 14 }}>Click or drag file to this area to upload</p>
									<p style={{ margin: 0, fontSize: 10, color: '#6B6B6B' }}>
										Support for a multiple upload.
									</p>
								</Dragger>
							</div>
							<Item
								name="description"
								label="Description"
								style={{ fontWeight: 590, marginTop: 15 }}
								onChange={() => {
									checkFormValues();
								}}
							>
								<TextArea
									placeholder="Enter Description"
									rows={4}
									disabled={_.isNil(expense) && allowCreate === false}
								/>
							</Item>
							<Item style={{ marginTop: 20 }}>
								<Row>
									<Col span={8}>
										{
											expense && <Row>
												<Text
													type="secondary"
													style={{ fontSize: 12 }}
												>
													Last updated by {expense.updated_by ? expense.user.name : 'NekoExpense'} at {moment(expense.updated_at).format('YYYY-MM-DD HH:mm')}
												</Text>
											</Row>
										}
									</Col>
									<Col
										span={12}
										offset={4}
									>
										<Row justify="end">
											<Space>
												{
													expense && <Button
														type="text"
														style={{ width: 100 }}
														loading={loadingSubmit}
														disabled={_.isNil(expense)}
														onClick={() => setDeleteExpenseModal(true)}
													           >
														Delete
													</Button>
												}

												<Button
													type="primary"
													htmlType="submit"
													style={{ width: 100 }}
													loading={loadingSubmit}
													disabled={disabled}
												>
													Save
												</Button>
											</Space>
										</Row>
									</Col>
								</Row>
							</Item>
						</Form>
					</Card>
				</Col>
			</Row>
			{renderDeleteExpenseModal()}
			<RenewedSubscriptionModal
				open={subscriptionSuccessModal}
				onClose={() => {
					setsubscriptionSuccessModal(false);
				}}
				buttonText="Get Started"
				description={`Your subscription has been renewed.
				You can now expense with ease again!`}
			/>
			<RenewedSubscriptionModal
				open={changedPaymentSuccessModal}
				onClose={() => {
					setChangedPaymentSuccessModal(false);
					updatePaymentMethodSuccess('no');
				}}
				buttonText="Okay"
				description={'You have successfully changed your payment method.'}
			/>
		</>
	);
};

export default ExpensePage;
