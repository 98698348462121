import { push } from 'connected-react-router';

import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';


export function signUp(fields) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.SUBMIT_CREATE_ACCOUNT_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const body = {
			...fields
		};

		const response = await API.user.createUser(body);

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_CREATE_ACCOUNT_ERROR,
				payload: {
					loadingSubmit: false,
					meta: response.meta
				}
			});
			return;
		}

		const { user } = response.data;

		dispatch({
			type: ActionTypes.SUBMIT_CREATE_ACCOUNT_SUCCESS,
			payload: {
				loadingSubmit: false,
				user
			}
		});

		// store response
		// LocalStorageHelper.setUser(user);

		// dispatch(push('/connect-whatsapp'));
		// dispatch(push('/how-it-works'));
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_CREATE_ACCOUNT_PAGE
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_CREATE_ACCOUNT_META
		});
	};
}
