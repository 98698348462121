import React from 'react';

import Loading from '../Loading';

const Card = ({ title=null, loading=false, style={}, children }) => {
	return(
		<div
			className="card"
			style={ loading ? { height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } : style}
		>
			{
				title ?
					<div style={{ padding: '20px 15px', borderBottom: 1, borderBlockStyle: 'solid', borderColor: '#F0F0F0' }}>
						<p style={{ margin: 0, fontSize: 16, fontWeight: 510 }}>{title}</p>
					</div> :
					<></>
			}
			<div
				style={ loading ? { padding: '2% 0' } : 
					{ padding: '15px 15px' }
				}
			>
				{loading ? <Loading/> : children}
			</div>
		</div>
	);
};
export default Card;