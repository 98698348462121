import { push } from 'connected-react-router';

import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';

export function logout() {
	return async dispatch => {
		await API.auth.logout();

		LocalStorageHelper.removeToken();
		LocalStorageHelper.removeUser();

		// Redirect to Login Page
		dispatch(push('/login'));
	};
}
