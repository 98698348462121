import { connect } from 'react-redux';
import { intuitAuthorize } from '../../actions/CallbackIntuitPage';
import CallbackIntuitPage from './CallbackIntuitPage';

const mapsStateToProps = state => ({
	// callbackCasPageProps: state.callbackCasPageReducer
});

const mapsDispatchToProps = dispatch => ({
	intuitAuthorize(param) {
		dispatch(intuitAuthorize(param));
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(CallbackIntuitPage);
