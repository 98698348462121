import { push } from 'connected-react-router';
import API from '../helpers/api';
import LocalStorageHelper from '../helpers/local_storage';

export function connectToQuickBooks() {
	return async dispatch => {
		const url = window.location.href;

		const response = await API.intuit_connection.connectToQuickBooks({ url });

		if (response.meta.code !== 200) {
			window.alert(response.meta.message);
			dispatch(push('/login'));
			return;
		}

		const { auth, user } = response.data;

		// store response
		LocalStorageHelper.setUser(user);
		LocalStorageHelper.setToken(auth.token);

		if (!user.subscription) {
			// Navigate to link page
			dispatch(push('/how-it-works'));
			return;
		}

		// Navigate to next page
		dispatch(push('/intuit-connections'));
	};
}
