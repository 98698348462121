import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	user: null,
	loadingVerify: false
};

export default function emailConfirmPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.VERIFY_EMAIL_REQUEST:
		case ActionTypes.VERIFY_EMAIL_ERROR:
		case ActionTypes.VERIFY_EMAIL_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_VERIFY_EMAIL_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_VERIFY_EMAIL_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
