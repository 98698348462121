/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, ConfigProvider, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import LocalStorageHelper from '../../helpers/local_storage';
import nekoLogo from '../../images/logo.svg';

const { Title, Text } = Typography;

const ChangePlan = (props) => {
	// States
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	// Other Variable
	const pricing = [
		{
			label: 'Basic Plan',
			description: 'Experience a 14 days free trial at no cost.',
			price: 2.99
		}
	];
	const user = LocalStorageHelper.getUser();

	// Props
	const { history } = props;

	// Functions
	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	// Use Effects
	useEffect(() => {
		if (!user) {
			history.push('/create');
			return;
		}

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ConfigProvider
			theme={{
				token: {
					fontSize: 19,
					fontSizeHeading1: 30,
					colorTextHeading: '#344E41'
				},
				components: {
					Form: {
						labelFontSize: 19
					},
					Modal: {
						// borderRadiusLG: 50
					}
				}
			}}
		>
			<Row style={{ backgroundColor: '#F5F5F5' }}>
				<Col
					flex="515px"
					style={{ display: windowWidth < 980 ? 'none' : '', height: '100vh', backgroundColor: '#FFFFFF', position: 'sticky', top: 0, boxShadow: '0px 4px 10px 0px #0000001A', borderRadius: '0px 50px 50px 0px' }}
				>
					<Row justify="center">
						<div style={{ padding: '78px 74px 0px 100px' }}>
							<img
								src={nekoLogo}
								alt="nekoexpense-logo"
								style={{ width: '100%', marginBottom: windowWidth < 510 ? 30 : '19vh' }}
							/>
							<Title
								level={1}
								style={{ margin: 0, marginBottom: 19 }}
							>
								Change Plan
							</Title>
							<Text style={{ fontSize: 16 }}>
								Take your desired plan to get access to our content easily,
								we like to offer special license offer to our users.
							</Text>
						</div>
						<Button
							type="primary"
							style={{ marginTop: 30, height: 56, borderRadius: 10, width: '62%' }}
							size="large"
							onClick={() => history.push('/billing')}
							ghost
						>
							Cancel
						</Button>
					</Row>
				</Col>
				<Col
					flex="auto"
					style={windowWidth < 980 ? { padding: windowWidth < 350 ? 20 : 40, height: '100%' } : { padding: '40px 0px' }}
				>
					{
						windowWidth < 980 ?
							<Row justify="center">
								<div style={{ width: '100%', maxWidth: 400, marginBottom: 40 }}>
									<img
										src={nekoLogo}
										alt="nekoexpense-logo"
										style={{ width: '100%', marginBottom: 30 }}
									/>
									<Title
										level={1}
										style={{ margin: 0, marginBottom: 19 }}
									>
										Change Plan
									</Title>
									<Text style={{ fontSize: 16 }}>
										Take your desired plan to get access to our content easily,
										we like to offer special license offer to our users.
									</Text>
									<Button
										type="primary"
										style={{ marginTop: 30, height: 56, borderRadius: 10, width: '100%' }}
										size="large"
										onClick={() => history.push('/billing')}
										ghost
									>
										Cancel
									</Button>
								</div>
							</Row> : <></>
					}
					<div style={{  height: '100%', gap: 51, display: 'flex', flexDirection: windowWidth < 1500 ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
						{
							pricing.map((data, index) => {
								return (
									<div>
										<div
											key={index}
											style={{
												position: 'relative',
												maxWidth: 400,
												width: '100%',
												height: 345,
												background: '#FFFFFF',
												borderRadius: 50,
												boxShadow: '0px 4px 10px 0px #0000001A',
												padding: windowWidth < 460 ? 30 : '51px 49px 48px 50px',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												border: '4px solid #53826A',
												zIndex: 1
											}}
										>
											<div style={{ zIndex: 2, position: 'absolute', top: -28, alignSelf: 'center' }}>
												<CheckCircleFilled style={{ fontSize: 60, color: '#53826A' }}/>
												<div style={{ zIndex: -1, position: 'absolute', top: '25%', left: '25%', backgroundColor: '#ffffff', height: 30, width: 30 }}></div>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Text style={{ color: '#344E41' , fontSize: 19, marginBottom: 20, marginTop: 8 }}>
													CURRENT SUBSCRIPTION
												</Text>
												<Text style={{ color: '#344E41' , fontSize: 30, fontWeight: 590, marginBottom: 20 }}>
													{data.label}
												</Text>
												<Text style={{ color: '#344E41', fontSize: 16, fontWeight: 400, marginBottom: 30 }}>
													{data.description}
												</Text>
												<Text style={{ color: '#344E41', fontSize: 38, fontWeight: 700 }}>
												$ {data.price}
												</Text>
											</div>
											{/* <div>
												<Text style={{ color: '#344E41', fontSize: 38, fontWeight: 700, marginBottom: 26 }}>
												$ {data.price}
												</Text>
											</div> */}
										</div>
									</div>
								);
							})
						}
					</div>
				</Col>
			</Row>
		</ConfigProvider>
	);
};

export default ChangePlan;
