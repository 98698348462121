import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Button, ConfigProvider, Card, Layout } from 'antd';

import nekoLogo from '../../images/logo.svg';
import cartoonGirl from '../../images/how-it-works-girl-image.png';
import step_1 from '../../images/step_1.png';
import step_2 from '../../images/step_2.png';
import step_3 from '../../images/step_3.png';

import LocalStorageHelper from '../../helpers/local_storage';

const { Title, Text } = Typography;
const { Content, Footer } = Layout;

const HowItWorksPage = props => {

	// states
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	// props
	const { history } = props;

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	// Use Effects
	useEffect(() => {
		// Check if there is a user data in local storage
		const user = LocalStorageHelper.getUser();
		if (user.subscription) {
			history.push('/expenses');
			return;
		}

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Layout style={{ backgroundColor: '#F5F5F5' }}>
				<Content style={{ margin: '28px 28px 0px' }}>
					<div>
						<Row
							justify={windowWidth > 1200 ? 'start' : 'center'}
							style={{ marginLeft: windowWidth > 1200 ? 50 : 0, marginBottom: windowWidth <= 1200 ? 50 : 0 }}
						>
							<Col>
								<img
									src={nekoLogo}
									alt="Neko Logo"
									style={{ width: windowWidth > 1200 ? 341 : '100%' }}
								/>
							</Col>
						</Row>
						<Row
							justify="center"
							style={{ marginTop: 15 }}
						>
							<Title style={{ fontWeight: 'bolder', color: '#344E41', fontSize: 40, padding: 0, margin: 0, textAlign: 'center' }}>
                                How it works
							</Title>
						</Row>
						<Row
							justify="center"
							style={{ marginBottom: 20 }}
						>
							<Text style={{  color: '#344E41', fontSize: 19, padding: 0, margin: 0, textAlign: 'center' }}>
                                Easy Receipt tracking and expense management for all.
							</Text>
						</Row>
						<Row justify="center">
							<Button
								onClick={() => history.push('/pricing')}
								style={{ backgroundColor: '#FF8A00', color: 'white', border: '1px solid #FF8A00', width: 200, height: 50 }}
							>
					            Get Started
							</Button>
						</Row>
						<Row
							justify="center"
							// style={{ padding: 50 }}
							style={{ marginTop: 50, marginBottom: 50 }}
						>
							<Col
								md={windowWidth > 1200 ? 12 : 24}
							>
								<Row
									justify={windowWidth > 1200 ? 'end' : 'center'}
									style={{ paddingRight: windowWidth > 1200 ? 25 : null }}
								>
									<Col span={windowWidth > 1200 ? 16 : 24}>
										<img
											src={cartoonGirl}
											alt="Neko Logo"
											width="100%"
										/>
									</Col>
								</Row>
							</Col>
							<Col
								md={windowWidth > 1200 ? 12 : 24}
								style={{ marginTop: windowWidth <= 1200 ? 25 : 0 }}
							>
								<Row
									justify={windowWidth > 1200 ? 'start' : 'center'}
									style={{ paddingLeft: windowWidth > 1200 ? 15 : null }}
								>
									<Col
										span={windowWidth > 1200 ? 18 : 24}
										style={{ marginBottom: windowWidth <= 1200 ? 10 : 0 }}
									>
										<Row>
											<img
												src={step_1}
												alt="Step 1"
												width="100%"
											/>
										</Row>
									</Col>
									<Col
										span={windowWidth > 1200 ? 18 : 24}
										style={{ marginBottom: windowWidth <= 1200 ? 10 : 0 }}
									>
										<Row>
											<img
												src={step_2}
												alt="Step 2"
												width="100%"
											/>
										</Row>
									</Col>
									<Col
										span={windowWidth > 1200 ? 18 : 24}
										style={{ marginBottom: windowWidth <= 1200 ? 10 : 0 }}
									>
										<Row>
											<img
												src={step_3}
												alt="Step 3"
												width="100%"
											/>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Content>
				<Footer style={{ padding: 0, margin: '20px 0px 10px', textAlign: 'center', fontSize: 16, color: '#344E41'  }}>
					© 2023 NekoExpense. All rights reserved.
				</Footer>
			</Layout>
		</Layout>
	);
};

export default HowItWorksPage;
