import request from './request';

export default {
	async getUser() {
		const response = await request.get('/app/users');
		return response;
	},
	async updatePassword(body) {
		const response = await request.patch('/app/users/change-password', body);
		return response;
	},


	// Special Endpoint
	async getUserByResetPasswordToken(t) {
		const response = await request.get(`/app/users/${t}`);
		return response;
	},
	async createResetPasswordLink(body) {
		const response = await request.post('/app/users/request-reset-password', body);
		return response;
	},
	async resetPassword(t, body) {
		const response = await request.patch(`/app/users/reset-password/${t}`, body);
		return response;
	},
	async createUser(body) {
		const response = await request.post('/app/users', body);
		return response;
	},
	async verifyUserEmail(t) {
		const response = await request.post(`/app/users/verify/${t}`);
		return response;
	},
	async getPaymentOptions() {
		const response = await request.get('/app/users/payments/options');
		return response;
	},
	async createPaymentOptions(body) {
		const response = await request.post('/app/users/payments/options', body);
		return response;
	},
	async updatePaymentOptions(id, body) {
		const response = await request.patch(`/app/users/payments/change-options/${id}`, body);
		return response;
	}
};
