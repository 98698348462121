import request from './request';

export default {
	async getActiveSubscription(query) {
		const response = await request.get('/app/subscriptions/active', query);
		return response;
	},
	async cancelSubscription(id) {
		const response = await request.patch(`/app/subscriptions/cancel/${id}`);
		return response;
	},

	// Special Endpoint
	async getCheckoutSessionLink(query) {
		const response = await request.get('/app/subscriptions/checkout-session', query);
		return response;
	}
};
