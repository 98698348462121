import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import LoginPage from './LoginPage';
import resetPasswordPageReducer from './ResetPasswordPage';
import createAccountPageReducer from './CreateAccountPage';
import connectWhatsappPageReducer from './ConnectWhatsappPage';
import pricingPageReducer from './PricingPage';
import parentLayoutReducer from './ParentLayout';

// Expense Page
import expensePageReducer from './ExpensePage';

// Account Links
import accountLinkPageReducer from './AccountLInkPage';
import accountLinkBasicPageReducer from './AccountLinkDetailPage/AccountLinkBasicPage';
import settingPageReducer from './SettingPage';
import billingPageReducer from './BillingPage';
import subscriptionReceiptPageReducer from './SubscriptionReceiptPage';

//Account Email Confirmation Page
import emailConfirmPageReducer from './EmailConfirmationPage';

//Account Link Email Confirmation Page
import channelConfirmPageReducer from './ChannelConfirmationPage';

// Intuit Connection Page
import intuitConnectionPageReducer from './IntuitConnectionPage';
import intuitConnectionBasicPageReducer from './IntuitConnectionDetailPage/IntuitConnectionBasicPage';



export default history =>
	combineReducers({
		router: connectRouter(history),
		loginPageReducer: LoginPage,

		// Reset Password Page
		resetPasswordPageReducer,

		// Create Account Page
		createAccountPageReducer,

		// Connect Whatsapp Page
		connectWhatsappPageReducer,

		// Pricing Page
		pricingPageReducer,

		// ExpensePage
		expensePageReducer,

		// Account Link Page
		accountLinkPageReducer,
		accountLinkDetailPage: combineReducers({
			accountLinkBasicPageReducer
		}),

		//Settings Page
		settingPageReducer,

		//Billing Page
		billingPageReducer,

		//Subscription Receipt Page
		subscriptionReceiptPageReducer,

		//Account Email Confirmation Page
		emailConfirmPageReducer,

		//Account Link Email Confirmation Page
		channelConfirmPageReducer,

		// Parent Layout
		parentLayoutReducer,

		// Intuit Connection Page
		intuitConnectionPageReducer,
		intuitConnectionDetailPage: combineReducers({
			intuitConnectionBasicPageReducer
		})
	});
