import { connect } from 'react-redux';
import { getAccountLinkById, deleteAccountLinkById, resetPage, checkAccountStatus } from '../../actions/AccountLinkDetailPage/AccountLinkBasicPage';

import AccountLinkDetailPage from './AccountLinkDetailPage';

const mapsStateToProps = state => ({
	accountLinkDetailPageProps: state.accountLinkDetailPage.accountLinkBasicPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getAccountLinkById(id) {
		dispatch(getAccountLinkById(id));
	},
	checkAccountStatus(id) {
		dispatch(checkAccountStatus(id));
	},
	deleteAccountLinkById(id) {
		dispatch(deleteAccountLinkById(id));
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(AccountLinkDetailPage);