import React, { useState, useEffect, useRef } from 'react';
import _, { isEmpty } from 'lodash';
import { Row, Col, Typography, Input, Button, ConfigProvider, Divider, Space, Modal } from 'antd';
import UrlParse from 'url-parse';
import { CloseOutlined } from '@ant-design/icons';

import config from '../../config';

// Helpers
import AuthHelper from '../../helpers/auth';
import GeneralHelper from '../../helpers/general';
import PasswordHelper from '../../helpers/password';

// Components
import Loading from '../../components/Loading';

// Assets
import nekoLogo from '../../images/logo.svg';
import nekoWhiteLogo from '../../images/logo-white.svg';
import googleLogo from '../../images/google-logo.svg';
import PixelHelper from '../../helpers/pixel';
import small_logo_face from '../../images/small_logo_face.png';
import XeroButton from '../../components/XeroButton';
import QuickbookButton from '../../components/QuickbookButton';

const { Title, Text } = Typography;

const CreateAccountPage = (props) => {
	// Refs
	const googleBtnRef = useRef(null);

	// States
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [signUpClicked, setSignUpClicked] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [emailStatus, setEmailStatus] = useState('');
	const [disabled, setDisabled] = useState(true);
	const [passValidationMsg, setPassValidationMsg] = useState(null);
	const [modalState, setModalState] = useState(false);

	// Props
	const { signUp, loginWithGoogle, location, history, resetPage, createAccountPageProps, loginPageProps } = props;
	const { user, loadingSubmit, meta } = createAccountPageProps || {};
	const { loading } = loginPageProps;
	const { code = 200, message } = meta || {};
	const { query } = UrlParse(location.search, true);

	useEffect(() => {
		if (user && user.status === 'unverified') {
			setModalState(true);
		}
	}, [user]);

	// Functions
	const handleSignUp = () => {
		signUp({ name, email, password });
	};

	const handleGoogleAuthCallback = response => {
		loginWithGoogle({ credential: response.credential, query });
	};

	const handleLogInClick = () => {
		setSignUpClicked(true);
		setTimeout(() => {
			setSignUpClicked(false);
		}, 100);
		history.push('/login');
	};

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	const handleEmailChange = e => {
		const email = e.target.value;
		setEmail(email);

		// Check if email is valid
		if (!GeneralHelper.isEmailValid(email)) {
			setEmailStatus('error');
			return;
		}

		setEmailStatus('');
	};

	const handleGoogleAuth = () => {
		googleBtnRef.current.click();
	};

	// Use Effects
	useEffect(() => {
		if (AuthHelper.isAuthenticated()) {
			history.push('/expenses');
		}

		PixelHelper.pageView(window.location.pathname);

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/platform.js';
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			// Google Authentication
			if (window.google) {
				window.google.accounts.id.initialize({
					client_id: config.googleAuth.clientId,
					callback: handleGoogleAuthCallback
				});

				window.google.accounts.id.renderButton(googleBtnRef.current, {
					theme: 'outline',
					size: 'large',
					text: 'continue_with',
					logo_alignment: 'center'
				});
			}

			googleBtnRef.current = document.querySelector('#google-btn div[role=button]');
		};

		return () => {
			window.removeEventListener('resize', handleResize);
			resetPage();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			_.isEmpty(name) ||
			_.isEmpty(email) ||
			isEmpty(password) ||
			!GeneralHelper.isEmailValid(email)
		) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, email, password]);

	useEffect(() => {
		if (password && password.length < 8) {
			setPassValidationMsg('Password must be atleast 8 characters.');
		} else if (password && !PasswordHelper.validatePassword(password)) {
			setPassValidationMsg('The password must contain at least one uppercase letter, one lowercase letter, one number, and one special character from the following set: _!@#$%^&*(),.?":{}|<>.');
		} else {
			setPassValidationMsg(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [password]);

	const confirmationModal = () => {
		return (
			<Modal
				open={modalState}
				footer={null}
				closable={false}
				centered
				onCancel={() => {
					setModalState(false);
				}}
				width={370}
			>
				<Row
					justify="center"
					style={{ marginBottom: '10px', marginTop: 15 }}
				>
					<img
						src={small_logo_face}
						alt="logo"
					/>
				</Row>
				<Row
					justify="center"
					style={{ marginBottom: 10, marginTop: 25 }}
				>
					<Text style={{ fontSize: 16, fontWeight: 'bold', color: '#344E41', padding: 0, margin: '0px 0px 5px 0px', textAlign: 'center' }}>Success!</Text>
					<Text style={{ fontSize: 14, padding: 0, margin: 0, textAlign: 'center' }}>Your account has been created. <br /> A confirmation link has been sent to your email.</Text>
				</Row>
				<Row>
					<Button
						type="primary"
						block
						style={{ fontSize: 14, height: 40, marginBottom: 10 }}
						onClick={handleLogInClick}
					>
						Get Started
					</Button>
				</Row>
				<CloseOutlined
					onClick={() => {
						setModalState(false);
					}}
					style={{
						position: 'absolute',
						top: '15px',
						right: '15px',
						fontSize: '12px',
						cursor: 'pointer',
						opacity: .5
					}}
				/>
			</Modal>
		);
	};

	if (loading) {
		return (
			<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Loading />
			</div>
		);
	}

	return (
		<ConfigProvider
			theme={{
				token: {
					fontSize: 19,
					fontSizeHeading1: 30,
					colorTextHeading: '#344E41'
				},
				components: {
					Form: {
						labelFontSize: 19
					},
					Modal: {
						// borderRadiusLG: 50
					}
				}
			}}
		>
			{confirmationModal()}
			<Row>
				<Col
					span={windowWidth < 1340 ? 24 : 9}
					style={{ minHeight: '100vh' }}
				>
					<Row justify="center">
						<div
							style={{
								width: windowWidth < 510 ? '100%' : 456,
								padding: windowWidth < 510 ? '30px 20px 0px' : '78px 0px'
							}}
						>
							<div>
								<a
									href="https://nekoexpense.com"
									target="_self"
								>
									<img
										src={nekoLogo}
										alt="nekoexpense-logo"
										style={{ width: windowWidth < 370 ? '100%' : 341, marginBottom: windowWidth < 510 ? 30 : 30 }}
									/>
								</a>
								<Title
									level={1}
									style={{ margin: 0 }}
								>
									Create an account
								</Title>
								<Text style={{ fontSize: 16 }}>
								By signing up, I agree to the NekoExpense
									<a
										href={process.env.NODE_ENV === 'production' ? 'https://www.nekoexpense.com/terms' : 'http://localhost:8500/terms'}
										target="_blank"
										rel="noopener noreferrer"
									>&nbsp; Subscriber Agreement  &nbsp;</a>
								and the NekoExpense &nbsp;
									<a
										href={process.env.NODE_ENV === 'production' ? 'https://www.nekoexpense.com/privacy' : 'http://localhost:8500/privacy'}
										target="_blank"
										rel="noopener noreferrer"
									>Privacy Policy</a>
								</Text>
							</div>
							<div style={{ marginTop: 20 }}>
								<div
									ref={googleBtnRef}
									id="google-btn"
								></div>
								<Button
									block
									size="large"
									onClick={handleGoogleAuth}
									style={{ height: 56 }}
								>
									<img
										src={googleLogo}
										alt="nekoexpense-logo"
										style={{ width: 30, marginRight: 20 }}
									/>
									{windowWidth < 325 ? '' : 'Continue with Google'}
								</Button>
							</div>

							<div style={{ marginTop: 20 }}>
								<XeroButton />
							</div>

							<div style={{ marginTop: 20 }}>
								<QuickbookButton />
							</div>

							<Divider style={{ margin: '25px 0px', fontSize: 16, borderColor: '#d9d9d9' }}>or</Divider>
							<div style={{ marginTop: 15 }}>
								<Space
									block
									direction="vertical"
									style={{ width: '100%' }}
								>
									<Text strong>Name</Text>
									<Input
										value={name}
										placeholder="Enter Name"
										disabled={loadingSubmit}
										onChange={e => setName(e.target.value)}
										style={{ height: 48 }}
									/>
								</Space>
							</div>
							<div style={{ marginTop: 23 }}>
								<Space
									block
									direction="vertical"
									style={{ width: '100%' }}
								>
									<Text strong>Email Address</Text>
									<Input
										value={email}
										placeholder="Enter Email"
										status={emailStatus}
										disabled={loadingSubmit}
										onChange={handleEmailChange}
										style={{ height: 48 }}
									/>
								</Space>
								{
									code !== 200 ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											{message}
										</Text> :
										''
								}
								{
									emailStatus === 'error' ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											Not a valid email
										</Text> :
										''
								}
							</div>
							<div style={{ marginTop: 23, minHeight: 110, marginBottom: 5 }}>
								<Space
									block
									direction="vertical"
									style={{ width: '100%' }}
								>
									<Text strong>Password</Text>
									<Input.Password
										placeholder="Enter Password"
										disabled={loadingSubmit}
										onChange={e => setPassword(e.target.value)}
										style={{ height: 48 }}
									/>
								</Space>
								{
									code !== 200 && code !== 409 ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											{message}
										</Text> :
										''
								}
								{
									passValidationMsg ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											{passValidationMsg}
										</Text> :
										''
								}
							</div>
							<Button
								block
								type="primary"
								size="large"
								disabled={disabled || loadingSubmit || passValidationMsg || (user && user.status && user.status === 'unverified')}
								onClick={handleSignUp}
								style={{ height: 56 }}
							>
								{
									loadingSubmit ?
										<Row justify="center">
											<Loading />
										</Row> :
										'Create Account'
								}
							</Button>
							<div style={{ marginTop: 25 }}>
								<Text style={{ fontSize: 16, marginRight: 10 }}>
									Already have an account?
								</Text>
								<Text
									onClick={handleLogInClick}
									style={{
										fontSize: 16,
										color: signUpClicked ? '#FFCC90' : '#FF8A00',
										cursor: 'pointer'
									}}
								>
									Login
								</Text>
							</div>
							{/* {
								user && user.status === 'unverified' && (
									<div style={{ marginTop: 25 }}>
										<Text
											style={{
												fontSize: 16,
												color: '#FF8A00'
											}}
										>
											A confirmation link has been sent to your email.
										</Text>
									</div>
								)
							} */}
						</div>
					</Row>
				</Col>
				{
					windowWidth < 1340 ?
						<></> :
						<Col span={15}>
							<div className="banner">
								<img
									src={nekoWhiteLogo}
									alt="nekoexpense-logo-white"
									style={{ width: 454 }}
								/>
								<Text style={{ color: '#FFFFFF', fontSize: 30 }}>Receipt tracking and expense management for all.</Text>
							</div>
						</Col>
				}
			</Row>
		</ConfigProvider>
	);
};

export default CreateAccountPage;
