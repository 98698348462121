import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	accountLink: null,
	loadingVerify: false
};

export default function channelConfirmPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.VERIFY_CHANNEL_REQUEST:
		case ActionTypes.VERIFY_CHANNEL_ERROR:
		case ActionTypes.VERIFY_CHANNEL_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_VERIFY_CHANNEL_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_VERIFY_CHANNEL_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
