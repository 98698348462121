import React, { useEffect } from 'react';
import UrlParse from 'url-parse';

import LocalStorageHelper from '../../helpers/local_storage';
import PixelHelper from '../../helpers/pixel';
import IntuitConnectionBasicPage from './IntuitConnectionBasicPage';

const IntuitConnectionDetailPage = (props) => {
	const {
		location,
		getIntuitConnectionById,
		resetPage
	} = props;
	const { query } = UrlParse(location.search, true);

	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		query.id && getIntuitConnectionById(query.id);

		return () => {
			resetPage();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<IntuitConnectionBasicPage {...props} />
	);
};

export default IntuitConnectionDetailPage;
