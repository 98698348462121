import request from './request';

export default {
	async getAccountLinks(query) {
		const response = await request.get('/app/account-links', query);
		return response;
	},
	async getAccountLinkById(id) {
		const response = await request.get(`/app/account-links/${id}`);
		return response;
	},
	async deleteAccountLinkById(id) {
		const response = await request.delete(`/app/account-links/${id}`);
		return response;
	},
	async createAccountLink(body) {
		const response = await request.post('/app/account-links', body);
		return response;
	},
	async updateAccountLinkById(id, body) {
		const response = await request.patch(`/app/account-links/${id}`, body);
		return response;
	},
	//Special Endpoints
	async verifyAccountLinkEmail(t) {
		const response = await request.post(`/app/account-links/verify/${t}`);
		return response;
	},
	async createWhatsappAccountLink(body) {
		const response = await request.post('/app/account-links/whatsapp/create', body);
		return response;
	},
	async resendEmailVerification(body) {
		const response = await request.post('/app/account-links/resend-email-verification', body);
		return response;
	}
};
