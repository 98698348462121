import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';


export function proceedCheckout({ trial, email, isUpdatePaymentMethod }) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_CHECKOUT_SESSION_LINK_REQUEST,
			payload: {
				loading: true
			}
		});

		const response = await API.subscription.getCheckoutSessionLink({ trial, email, isUpdatePaymentMethod });
	
		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_CHECKOUT_SESSION_LINK_ERROR,
				payload: {
					loading: false,
					meta: response.meta
				}
			});
			return;
		}

		const { payment_url } = response.data;

		dispatch({
			type: ActionTypes.GET_CHECKOUT_SESSION_LINK_SUCCESS,
			payload: {
				loading: false
			}
		});

		// Navigate to checkout page
		window.location.href = payment_url;
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_CHECKOUT_SESSION_LINK_PAGE
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_CHECKOUT_SESSION_LINK_META
		});
	};
}
