/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Row, Col, Form, Typography, Empty, notification, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import dayjs from 'dayjs';

// Helpers
import LabelHelper from '../../helpers/label';
import LocalStorageHelper from '../../helpers/local_storage';
import useUpdatePaymentMethodSuccess from '../../helpers/hooks/useUpdatePaymentMethodSuccess';
import useUpdateSubscriptionSuccess from '../../helpers/hooks/useUpdateSubscriptionSuccess';

import ConfirmModal from '../../components/ConfirmModal';
import Card from '../../components/Card';


// Logo
import masterCardLogo from '../../images/mastercard-logo.png';
import visaLogo from '../../images/visa-logo.png';
import dinersLogo from '../../images/diners-logo.png';
import discoverLogo from '../../images/discover-logo.png';
import eftposAuLogo from '../../images/eftpos-au-logo.png';
import jcbLogo from '../../images/jcb-logo.png';
import unionPayLogo from '../../images/unionpay-logo.png';
import amexLogo from '../../images/amex-logo.png';

const { Item } = Form;
const { Text, Title } = Typography;

const BillingPage = (props) => {
	// States
	const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
	const [changePlanModal, setChangePlanModal] = useState(false);
	const [changePaymentMethodModal, setChangePaymentMethodModal] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [success, updateSuccess] = useUpdatePaymentMethodSuccess('payment_method_success');
	const [successSubscription, updatePaymentSuccess] = useUpdateSubscriptionSuccess('subscription_success');
	// Props
	const {
		history,
		getActiveSubscription,
		cancelSubscription,
		billingPageProps,
		getInvoices,
		proceedCheckout,
		resetPage,
		resetMeta
	} = props;

	// Other Variables
	const { meta, subscription, loading, loadingCancel, total, page, limit, invoices, loadingReceipts } = billingPageProps;
	const { _id: subscriptionId, payment_method, status, previous_status, plan, start_date, current_period_end } = subscription || {};
	const { brand, last_four_digit } = payment_method || {};
	const user = LocalStorageHelper.getUser();
	const paymentLogo = {
		amex: amexLogo,
		diners: dinersLogo,
		discover: discoverLogo,
		eftpos_au: eftposAuLogo,
		jcb: jcbLogo,
		mastercard: masterCardLogo,
		unionpay: unionPayLogo,
		visa: visaLogo
	};
	useEffect(() => {
		// Redirect if user already subscribed
		if (user.type !== 'standard') {
			history.push('/expenses');
			return;
		}

		getActiveSubscription();
		getInvoices();
		return () => resetPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (meta) {
			const type = meta.code === 200 ? 'success' : 'error';
			if (!meta?.message?.toLowerCase().includes('password')) {
				notification[type]({
					message: meta.message,
					placement: 'bottomRight'
				});
				// Reset page meta
				resetMeta();
			}

			if (type === 'success') {
				getActiveSubscription();
			}
			setCancelSubscriptionModal(false);
			setChangePlanModal(false);
			setChangePaymentMethodModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);

	useEffect(() => {
		if (subscription) {
			LocalStorageHelper.setUser({ ...user, subscription_status: status });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscription]);

	// ---------------------------------- Start of Functions ---------------------------------- //

	const renderCancelSubscriptionModal = () => {
		return (
			<ConfirmModal
				open={cancelSubscriptionModal}
				width={400}
				title="Cancel Subscription"
				message="Are you sure you want to cancel your subscription?"
				subscriptionPlan={plan}
				subscriptionPeriod={current_period_end}
				loading={loadingCancel}
				onCancel={() => {
					setCancelSubscriptionModal(false);
				}}
				onOk={() => {
					updatePaymentSuccess('no');
					cancelSubscription(subscriptionId);
				}}
			>
				<>
					<span>Are you sure you want to cancel your subscription?</span>
					<p style={{ marginTop: 16, marginBottom: 0  }}>Just to let you know, you can still use your account with the Basic Plan until </p>
					<span style={{ fontWeight: 700, color: '#344E41' }}>{moment(current_period_end).format('MMMM DD, YYYY')}</span>
				</>
			</ConfirmModal>
		);
	};

	const renderChangePlanModal = () => {
		return (
			<ConfirmModal
				open={changePlanModal}
				width={400}
				title="Change Plan"
				message="Are you sure you want to change your plan?"
				onCancel={() => {
					setChangePlanModal(false);
				}}
				onOk={() => history.push('/change-plan')}
			/>
		);
	};

	const renderChangePaymentMethodModal = () => {
		return (
			<ConfirmModal
				open={changePaymentMethodModal}
				width={400}
				title="Change Payment Method"
				message="Are you sure you want to change your payment method?"
				onCancel={() => {
					setChangePaymentMethodModal(false);
				}}
				onOk={() => {
					proceedCheckout({ trial: 'no', email: user.email, isUpdatePaymentMethod: 'yes' });
					updateSuccess('yes');
				}}
			/>
		);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys, _) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		hideSelectAll: false
	};

	const handleDownload = (fileUrls) => {
		var interval = setInterval(download, 300, fileUrls);

		function download(urls) {
			var url = urls.pop();

			var a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', '');
			a.setAttribute('target', '_blank');
			a.click();

			if (urls.length === 0) {
				clearInterval(interval);
			}
		}
	};

	const renderPlanStatus = () => {
		if (status === 'canceled' && previous_status === 'trialing') {
			return 'Free Trial - Cancelled';
		} else if (status === 'canceled' && previous_status === 'paid') {
			return 'Basic Plan - Cancelled';
		} else {
			return LabelHelper.subscriptionStatus(status);
		}
	};

	// ---------------------------------- End of Functions ---------------------------------- //
	const columns = [
		{
			title: 'Billing Date',
			dataIndex: 'purchase_date',
			key: 'purchase_date',
			render: (text) => dayjs(text).format('MM/DD/YYYY')
		},
		{
			title: 'Invoice ID',
			dataIndex: 'invoice_number',
			key: 'invoice_number'
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text) => {
				return (
					<div>
						<p style={{ fontSize: 14, fontWeight: 400 }}>
							<span style={{ backgroundColor: '#53826A', color: '#FFFFFF', borderRadius: 5, padding: '2px 6px' }}>{LabelHelper.invoiceStatus(text)}</span>
						</p>
					</div>
				);
			}
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: (_, record) => {
				const { amount, currency } = record;
				return <p style={{ fontSize: 16, fontWeight: 700 }}>{`${currency.toUpperCase()} ${amount}`}</p>;
			}
		},
		{
			title: 'Action',
			dataIndex: 'action',
			width: '5%',
			render: (_, record) => {
				const { invoice_pdf } = record;
				return (
					<DownloadOutlined
						onClick={e => {
							e.preventDefault();
							handleDownload([invoice_pdf]);
						}}
						style={{ color: '#FF8A00', cursor: 'pointer', fontSize: 20 }}
					/>
				);
			}
		}
	];

	return (
		<div>
			<Row>
				<Col
					span={24}
					style={{ marginBottom: 15 }}
				>
					<Card
						loading={loading}
						style={{ width: '100%', paddingLeft: 15 }}
					>
						{subscription ?
							<>
								<Row style={{ marginTop: 15, marginBottom: 15 }}>
									<Col
										xl={8}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<Row>
											<Col span={24}>
												<Title
													level={3}
													style={{ padding: 0, margin: 0 }}
												>
													Payment Method
												</Title>
											</Col>
											<Col span={24}>
												<Text style={{ fontSize: 16, fontWeight: 400 }}>
													Subscribed since {moment(start_date).format('MMMM DD, YYYY')}
												</Text></Col>
											<Col
												span={24}
												style={{ marginTop: 10 }}
											>
												<Button
													className="custom-button"
													type="primary"
													onClick={() => {
														if (payment_method) {
															setChangePaymentMethodModal(true)
														} else {
															proceedCheckout({ trial: 'no', email: user.email, isUpdatePaymentMethod: 'yes' });
															updateSuccess('yes');
														}
													}}
												>{payment_method ? 'Change' : 'Add'} Payment Method</Button>
											</Col>
										</Row>
									</Col>
									<Col
										xl={13}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<Row>
											<Text style={{ fontSize: 20, fontWeight: 510, marginBottom: 16 }}>Card Details</Text>
										</Row>
										<Row
											justify="space-between"
											style={{ margin: 0, padding: 0 }}
										>
											<div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 20 }}>
												{
													payment_method ? (
														<>
															<img
																style={{ border: '1px solid #D9D9D9', padding: 5, height: 34, fontWeight: 'bold' }}
																src={paymentLogo[brand]}
																alt="payment-logo"
															/>
															<Text style={{ fontSize: 20, fontWeight: 510 }}>
																•••• •••• •••• {last_four_digit}
															</Text>
														</>
													) : (
														<Text style={{ fontSize: 15, fontWeight: 510, color: '#FF8A00' }}>
															Not available
														</Text>
													)
												}
											</div>
										</Row>
										<Row
											style={{ marginTop: 10 }}
										>
											{
												status === 'canceled' && previous_status === 'trialing' ?
													<Text style={{ fontSize: 16, fontWeight: 400 }}>{`Your Free Trial will remain active until ${moment(current_period_end).format('MMMM DD, YYYY')}`}</Text> :
													<Text style={{ fontSize: 16, fontWeight: 400 }}>{['active', 'trialing'].indexOf(status) !== -1 ? `Your next billing date is on ${moment(current_period_end).format('MMMM DD, YYYY')}` : `Your subscription will end on ${moment(current_period_end).format('MMMM DD, YYYY')}`}</Text>
											}
										</Row>
									</Col>
								</Row>
							</> : <>
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</>
						}
					</Card>
				</Col>
				<Col
					span={24}
					style={{ marginTop: 15, marginBottom: 15 }}
				>
					<Card
						loading={loading}
						style={{ width: '100%', paddingLeft: 15 }}
					>
						{subscription ?
							<>
								<Row
									justify="center"
									style={{ marginTop: 15, marginBottom: 15 }}
								>
									<Col
										xl={8}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<Row>
											<Col span={24}>
												<Title
													level={3}
													style={{ padding: 0, margin: 0, fontSize: 24, fontWeight: 700 }}
												>
													My Plan
												</Title>
											</Col>
											<Col
												span={24}
												style={{ marginTop: 10 }}
											>
												{
													(status === 'canceled' || status === 'active') ?
														<Button
															className="custom-button"
															type="primary"
															onClick={() => {
																history.push('/pricing');
															}}
														>
															Subscribe to NekoExpense
														</Button> :
														<Button
															className="custom-button"
															type="primary"
															onClick={() => setCancelSubscriptionModal(true)}
														>
															Cancel Subscription
														</Button>

												}
											</Col>
										</Row>
									</Col>
									<Col
										xl={13}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<Row>
											<Col span={24}>
												<Text style={{ fontSize: 20, fontWeight: 510, marginBottom: 16 }}>{LabelHelper.subscriptionPlan(plan)} Plan</Text>
											</Col>
											<Col
												span={24}
												style={{ marginTop: 8 }}
											>
												<Text
													style={{ fontSize: 16, marginBottom: 16 }}
												>
													{renderPlanStatus()}
												</Text>
											</Col>
										</Row>
									</Col>
									<Col
										xl={3}
										lg={24}
										md={24}
										sm={24}
										xs={24}
									>
										<div style={{ float: 'right', marginRight: 29 }}>
											<Text
												onClick={() => {
													setChangePlanModal(true);
												}}
												style={{ width: '100%', color: '#FF8A00', fontSize: 20, fontWeight: 510, cursor: 'pointer' }}
											>
												Change Plan
											</Text>
										</div>
									</Col>
								</Row>
							</> : <>
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</>
						}
					</Card>
				</Col>
				<Col
					span={24}
					style={{ marginTop: 15 }}
				>
					<Card
						loading={loading}
						style={{ width: '100%', paddingLeft: 15 }}
					>
						<Row style={{ marginBottom: 12 }}>
							<Col span={24}>
								<Title
									level={3}
									style={{ padding: 0, margin: 0, fontSize: 24, fontWeight: 700 }}
								>
									Invoices / Receipts
								</Title>
							</Col>
						</Row>
						<Row style={{ marginBottom: 20 }}>
							<Item style={{ margin: 4 }}>
								<Button
									type="primary"
									icon={<DownloadOutlined />}
									disabled={selectedRowKeys.length < 2}
									onClick={() => {
										const fileUrls = selectedRowKeys.map(rowKey => {
											const invoice = invoices.find(invoice => invoice._id === rowKey);
											return invoice.invoice_pdf;
										});

										handleDownload(fileUrls);
									}}
								>
									Export
								</Button>
							</Item>
						</Row>
						<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>

						</div>
						<Row>
							<Col flex="auto">
								<Table
									scroll={{ x: true }}
									columns={columns}
									dataSource={invoices.map(invoice => ({
										...invoice,
										key: invoice._id
									}))}
									pagination={{
										current: page,
										position: ['bottomRight'],
										onChange: (current, pageSize) => {
											getInvoices({ page: current, limit: pageSize });
										},
										total,
										pageSize: limit
									}}
									rowSelection={rowSelection}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			{renderCancelSubscriptionModal()}
			{renderChangePlanModal()}
			{renderChangePaymentMethodModal()}
		</div >
	);
};

export default BillingPage;
