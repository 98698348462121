import { connect } from 'react-redux';
import { getUserByResetPasswordToken, resetPassword, resetPage, resetMeta } from '../../actions/ResetPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';

const mapsStateToProps = state => ({
	resetPasswordPageProps: state.resetPasswordPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getUserByResetPasswordToken(token) {
		dispatch(getUserByResetPasswordToken(token));
	},
	resetPassword(token, fields) {
		dispatch(resetPassword(token, fields));
	},
	resetPage() {
		dispatch(resetPage());
	},
	resetMeta() {
		dispatch(resetMeta());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(ResetPasswordPage);
