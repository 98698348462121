module.exports = {
	channel: [
		{
			value: 'email',
			label: 'Email'
		},
		{
			value: 'whatsapp',
			label: 'WhatsApp'
		},
		{
			value: 'web_upload',
			label: 'Web Upload'
		}
	],
	category_selection: [
		{
			value: 'benefits',
			label: 'Benefits'
		},
		{
			value: 'car',
			label: 'Car'
		},
		{
			value: 'equipment',
			label: 'Equipment'
		},
		{
			value: 'fees',
			label: 'Fees'
		},
		{
			value: 'home_office',
			label: 'Home Office'
		},
		{
			value: 'insurance',
			label: 'Insurance'
		},
		{
			value: 'interest',
			label: 'Interest'
		},
		{
			value: 'labor',
			label: 'Labor'
		},
		{
			value: 'maintenance',
			label: 'Maintenance'
		},
		{
			value: 'materials',
			label: 'Materials'
		},
		{
			value: 'meals_and_entertainment',
			label: 'Meals and Entertainment'
		},
		{
			value: 'office_supplies',
			label: 'Office Supplies'
		},
		{
			value: 'professional_services',
			label: 'Professional Services'
		},
		{
			value: 'rent',
			label: 'Rent'
		},
		{
			value: 'taxes',
			label: 'Taxes'
		},
		{
			value: 'travel',
			label: 'Travel'
		},
		{
			value: 'utilities',
			label: 'Utilities'
		},
		{
			value: 'other',
			label: 'Other'
		}
	],
	currency: [
		{
			value: 'usd',
			label: 'USD'
		},
		{
			value: 'hkd',
			label: 'HKD'
		}
	],
	payment_methods: [
		{
			label: 'Cash',
			value: 'cash'
		},
		{
			label: 'Credit/Debit Card',
			value: 'credit_debit_card'
		},
		{
			label: 'Bank Transfer',
			value: 'bank_transfer'
		},
		{
			label: 'Octopus',
			value: 'octopus'
		},
		{
			label: 'Alipay',
			value: 'alipay'
		},
		{
			label: 'Wechat Pay',
			value: 'wechat_pay'
		},
		{
			label: 'Cheque',
			value: 'cheque'
		},
		{
			label: 'Other',
			value: 'other'
		}
	],
	account_channel: [
		{
			value: 'email',
			label: 'Email'
		},
		{
			value: 'whatsapp',
			label: 'WhatsApp'
		}
	],
	country_codes: [
		{
			value: '+86',
			label: '+86 (China)'
		},
		{
			value: '+852',
			label: '+852 (Hong Kong)'
		},
		{
			value: '+63',
			label: '+63 (Philippines)'
		},
		{
			value: '+1',
			label: '+1 (United States)'
		}
	],
	account_status: [
		{
			value: 'verified',
			label: 'Verified'
		},
		{
			value: 'unverified',
			label: 'Unverified'
		}
	]
};
