import { connect } from 'react-redux';
import { getAccountLinks, resetPage } from '../../actions/AccountLinkPage';
import AccountLinkPage from './AccountLinkPage';

const mapsStateToProps = state => ({
	accountLinkPageProps: state.accountLinkPageReducer
});

const mapsDispatchToProps = dispatch => ({
	getAccountLinks(options) {
		dispatch(getAccountLinks(options));
	},
	resetPage() {
		dispatch(resetPage());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(AccountLinkPage);
