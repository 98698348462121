import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Row, Col, Typography, Input, Button, ConfigProvider, Select, Space } from 'antd';

// Components
import Loading from '../../components/Loading';

// Helpers
import LocalStorageHelper from '../../helpers/local_storage';

// Assets
import nekoLogo from '../../images/logo.svg';
import nekoWhiteLogo from '../../images/logo-white.svg';
import countryCallingCodes from '../../constants/CountryCallingCodes';
import PixelHelper from '../../helpers/pixel';

const { Title, Text } = Typography;

const ConnectWhatsappPage = (props) => {
	// States
	const [countryCode, setCountryCode] = useState(null);
	const [whatsappNumber, setWhatsappNumber] = useState(null);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [disabled, setDisabled] = useState(true);

	// Props
	const { createWhatsappAccountLink, history, connectWhatsappPageProps } = props;
	const { loadingSubmit, meta } = connectWhatsappPageProps;
	const { code=200, message } = meta || {};

	// Functions
	const handleConnect = () => {
		const user = LocalStorageHelper.getUser();
		createWhatsappAccountLink({ country_code: countryCode, whatsapp_number: whatsappNumber, email: user.email });
	};

	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	const handleInputChange = (e) => {
		const { value } = e.target;

		// Remove non-numeric characters using a regular expression
		const numericValue = value.replace(/[^0-9]/g, '');

		setWhatsappNumber(numericValue);
	  };

	// Use Effects
	useEffect(() => {
		// Check if there is a user data in local storage
		const user = LocalStorageHelper.getUser();
		if (!user) {
			history.push('/login');
			return;
		}

		PixelHelper.pageView(window.location.pathname);

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			// resetPage();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (_.isEmpty(countryCode) || _.isEmpty(whatsappNumber)) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryCode, whatsappNumber]);

	return(
		<ConfigProvider
			theme={{
				token: {
					fontSize: 19,
					fontSizeHeading1: 30,
					colorTextHeading: '#344E41'
				},
				components: {
					Form: {
						labelFontSize: 19
					},
					Modal: {
						borderRadiusLG: 50
					}
				}
			}}
		>
			<Row>
				<Col
					span={windowWidth < 1340 ? 24 : 9}
					style={{ minHeight: '100vh' }}
				>
					<Row justify="center">
						<div
							style={{
								width: windowWidth < 510 ? '100%' : 456,
								padding: windowWidth < 510 ? '30px 20px 0px' : '78px 0px' }}
						>
							<div>
								<img
									src={nekoLogo}
									alt="nekoexpense-logo"
									style={{ width: windowWidth < 370 ? '100%' : 341, marginBottom: windowWidth < 510 ? 30: '22vh' }}
								/>
								<Title
									level={1}
									style={{ margin: 0 }}
								>
									Connect your WhatsApp
								</Title>
								<Text style={{ fontSize: 16 }}>Add your WhatsApp Number to get started.</Text>

							</div>
							<div style={{ marginTop: 45 }}>
								<Space
									block
									direction="vertical"
									style={{ width: '100%' }}
								>
									<Text strong>Phone Number</Text>
									<Row
										justify="center"
										gutter={8}
									>
										<Col span={6}>
											<Select
												showSearch
												value={countryCode}
												placeholder="+00"
												onChange={value => setCountryCode(value)}
												optionFilterProp="children"
												filterOption={(input, option) => (option?.label ?? '').includes(input)}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
												}
												options={_.uniqBy(countryCallingCodes, 'value')}
												style={{
													width: '100%',
													height: 48
												}}
											/>
										</Col>
										<Col span={18}>
											<Input
												value={whatsappNumber}
												placeholder="Enter Phone No."
												disabled={loadingSubmit}
												onChange={handleInputChange}
												style={{ height: 48 }}
											/>
										</Col>
									</Row>
								</Space>
								{
									code !== 200 ?
										<Text
											type="danger"
											style={{ fontSize: 16 }}
										>
											{message}
										</Text> :
										''
								}
							</div>
							<Button
								block
								type="primary"
								size="large"
								disabled={disabled || loadingSubmit}
								onClick={handleConnect}
								style={{ height: 56, marginTop: 23 }}
							>
								{
									loadingSubmit ?
										<Row justify="center">
											<Loading/>
										</Row> :
										'Connect WhatsApp'
								}
							</Button>
							<Button
								block
								size="large"
								onClick={() => history.push('/pricing')}
								style={{ height: 56, marginTop: 23 }}
							>
								Skip this Step
							</Button>
						</div>
					</Row>
				</Col>
				{
					windowWidth < 1340 ?
						<></> :
						<Col span={15}>
							<div className="banner">
								<img
									src={nekoWhiteLogo}
									alt="nekoexpense-logo-white"
									style={{ width: 454 }}
								/>
								<Text style={{ color: '#FFFFFF', fontSize: 30 }}>Receipt tracking and expense management for all.</Text>
							</div>
						</Col>
				}
			</Row>
		</ConfigProvider>
	);
};

export default ConnectWhatsappPage;

