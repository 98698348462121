import React, { Component } from 'react';
import PixelHelper from '../../helpers/pixel';

class UnauthorizedPage extends Component {
	componentDidMount() {
		PixelHelper.pageView(window.location.pathname);
	}
	render() {
		return (
			<div>Unauthorized</div>
		);
	}
}

export default UnauthorizedPage;
