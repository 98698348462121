// Import necessary modules
import React from 'react';
import { Button } from 'antd'; // Assuming you're using Ant Design
import config from '../../config';

// Create QuickbookButton component
const QuickbookButton = () => {
	const quickbookAuthUrl = () => {
		return `https://appcenter.intuit.com/app/connect/oauth2?response_type=code&client_id=${config.quickbookAuth.clientId}&redirect_uri=${encodeURIComponent(config.quickbookAuth.redirectUri)}&scope=${encodeURIComponent(config.quickbookAuth.scopes)}&state=123&prompt=login`;
	};
	return (
		<Button
			block
			size="large"
			onClick={() => {
				window.location.href = quickbookAuthUrl();
			}}
			style={{ height: 56, color: '#2977C5', padding: 0, borderColor: '#2977C5' }}
		>
			Sign in with Intuit
		</Button>
	);
};

export default QuickbookButton;
