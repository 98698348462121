import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faReceipt, faLink, faDollar, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import LocalStorageHelper from '../../helpers/local_storage';

import siderMobileLogo from '../../images/sider-mobile-logo.svg';
import nekoLogo from '../../images/logo.svg';

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type
	};
}

const getItems = (location, user) => [
	getItem('Expenses', 'expenses',
		<FontAwesomeIcon
			icon={faReceipt}
			fixedWidth
		/>),
	getItem('Channels', 'account-links',
		<FontAwesomeIcon
			icon={faLink}
			fixedWidth
		/>),
	getItem('Account Settings', 'account-settings',
		<FontAwesomeIcon
			icon={faGear}
			fixedWidth
		/>),
	user && user.type !== 'enterprise' && getItem('Billing', 'billing',
		<FontAwesomeIcon
			icon={faDollar}
			fixedWidth
			style={location.pathname !== '/billing' ? { color: '#FFFFFF', backgroundColor: '#344E41', borderRadius: '50%', padding: '4px 2px', fontSize: '13px' }
				: { color: '#344E41', backgroundColor: '#FFFFFF', borderRadius: '50%', padding: '4px 2px', fontSize: '13px' }}
		/>),
	getItem('Intuit Connections', 'intuit-connections',
		<FontAwesomeIcon
			icon={faNetworkWired}
			fixedWidth
		/>)
];


const rootSubmenuKeys = ['sub-tabs'];

const Drawer = ({ location, handleOnCollapse, collapsed }) => {
	const [openKeys, setOpenKeys] = useState([]);
	const history = useHistory();
	const user = LocalStorageHelper.getUser();

	useEffect(() => {
		getActiveSubmenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// --------------------------------- Start of Functions --------------------------------- //
	const getActiveItem = ({ pathname, search }) => {
		switch (true) {
			case /\/expenses/.test(pathname):
				return ['expenses'];
			case /\/account-links/.test(pathname):
				return ['account-links'];
			case /\/account-settings/.test(pathname):
				return ['account-settings'];
			case /\/billing/.test(pathname):
				return ['billing'];
			case /\/intuit-connections/.test(pathname):
				return ['intuit-connections'];
			default:
				return;
		}
	};

	const getActiveSubmenu = () => {
		// const pathname = location.pathname;
		switch (true) {
			default:
				break;
		}
	};

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};
	// ---------------------------------- End of Functions ---------------------------------- //

	const activeItem = getActiveItem({
		pathname: location.pathname,
		search: location.search
	});

	const items = getItems(location, user);

	return (
		<Sider
			theme="light"
			breakpoint="lg"
			collapsedWidth={60}
			trigger={null}
			collapsible
			collapsed={collapsed}
			onCollapse={handleOnCollapse}
			width={321}
			className="nav-sider"
		>
			<div
				style={{ height: 108, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				{
					collapsed ?
						<img
							src={siderMobileLogo}
							alt="sider-logo"
						/> :
						<img
							src={nekoLogo}
							alt="nekoexpense-logo"
							style={{ width: '80%' }}
						/>
				}
			</div>
			<Menu
				mode="inline"
				openKeys={openKeys}
				onOpenChange={onOpenChange}
				selectedKeys={activeItem}
				onClick={({ key }) => {
					history.push(`/${key}`);
				}}
				items={items}
			/>
		</Sider>
	);
};

export default Drawer;
