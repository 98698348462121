import React from 'react';

import siderMobileLogo from '../../images/sider-mobile-logo.svg';

const Loading = () => {
	return (
		<div className="loading-spinner">
			<img
				src={siderMobileLogo}
				alt="spinner"
			/>
		</div>
	);
};

export default Loading;
