import React, { useEffect } from 'react';
import Loading from '../../components/Loading';
import PixelHelper from '../../helpers/pixel';

const LogoutPage = (props) => {
	useEffect(() => {
		PixelHelper.pageView(window.location.pathname);

		props.logout();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return(
		<div style={{ display: 'flex', height: '100vh', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
			<Loading/>
		</div>
	);
};

export default LogoutPage;
