import React, { Component } from 'react';

class CallbackXeroPage extends Component {
	componentDidMount() {
		this.props.xeroAuthorize();
	}
	render() {
		return (
			<div>Redirecting...</div>
		);
	}
}

// Use redux's compose to use multiple
// HOC(Higher Order Component) wrappers
export default CallbackXeroPage;
