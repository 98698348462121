import { useEffect } from 'react';
import { notification } from 'antd';

const useNotification = (meta, resetMeta) => {
	useEffect(() => {
		if (meta) {
			const type = meta.code === 200 ? 'success' : 'error';

			notification[type]({
				message: meta.message,
				placement: 'bottomRight'
			});
		
			// Reset page meta
			resetMeta();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta]);
};

export default useNotification;