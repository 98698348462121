import { connect } from 'react-redux';
import { getExpenses, getExpenseById, onSubmit, resetPage, resetMeta, exportExpenses, resetHasDownload, getPaymentOptions, resetSelectedExpense, deleteExpense } from '../../actions/ExpensePage';
import { getActiveSubscription } from '../../actions/BillingPage';
import ExpensePage from './ExpensePage';

const mapsStateToProps = state => ({
	expensePageProps: state.expensePageReducer
});

const mapsDispatchToProps = dispatch => ({
	getActiveSubscription() {
		dispatch(getActiveSubscription());
	},
	getExpenses(options) {
		dispatch(getExpenses(options));
	},
	getPaymentOptions() {
		dispatch(getPaymentOptions());
	},
	exportExpenses(options) {
		dispatch(exportExpenses(options));
	},
	getExpenseById(options) {
		dispatch(getExpenseById(options));
	},
	onSubmit(options) {
		dispatch(onSubmit(options));
	},
	resetSelectedExpense() {
		dispatch(resetSelectedExpense());
	},
	deleteExpense(id) {
		dispatch(deleteExpense(id));
	},
	resetMeta() {
		dispatch(resetMeta());
	},
	resetPage() {
		dispatch(resetPage());
	},
	resetHasDownload() {
		dispatch(resetHasDownload());
	}
});

export default connect(
	mapsStateToProps,
	mapsDispatchToProps
)(ExpensePage);
