import { push } from 'connected-react-router';
import * as ActionTypes from '../constants/ActionTypes';
import API from '../helpers/api';

export function getIntuitConnections(options) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_INTUIT_CONNECTIONS_REQUEST,
			payload: { loading: true }
		});

		const response = await API.intuit_connection.getIntuitConnections({ ...options });

		if(response.meta.code !== 200){
			dispatch({
				type: ActionTypes.GET_INTUIT_CONNECTIONS_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { total, page, limit, count, intuit_connections } = response.data;

		dispatch({
			type: ActionTypes.GET_INTUIT_CONNECTIONS_SUCCESS,
			payload: {
				total,
				page,
				limit,
				count,
				intuitConnections: intuit_connections,
				loading: false
			}
		});
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_INTUIT_CONNECTIONS_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_INTUIT_CONNECTIONS_PAGE
		});
	};
}
