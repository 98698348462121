import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	subscription: null,
	loadingSubscription: false
};

export default function parentLayoutReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_REQUEST:
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR:
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}