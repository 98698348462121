import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	loading: false
};

export default function pricingPageReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_CHECKOUT_SESSION_LINK_REQUEST:
		case ActionTypes.GET_CHECKOUT_SESSION_LINK_ERROR:
		case ActionTypes.GET_CHECKOUT_SESSION_LINK_SUCCESS:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.RESET_CHECKOUT_SESSION_LINK_META:
			return {
				...state,
				meta: null
			};
		case ActionTypes.RESET_CHECKOUT_SESSION_LINK_PAGE:
			return {
				...initialState
			};
		default:
			return state;
	}
}
