import { push } from 'connected-react-router';
import * as ActionTypes from '../../constants/ActionTypes';
import API from '../../helpers/api';

export function getIntuitConnectionById(id) {
	return async dispatch => {
		dispatch({
			type: ActionTypes.GET_INTUIT_CONNECTION_BASIC_REQUEST,
			payload: { loading: true }
		});

		const response = await API.intuit_connection.getIntuitConnectionById(id);

		if(response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.GET_INTUIT_CONNECTION_BASIC_ERROR,
				payload: {
					meta: response.meta,
					loading: false
				}
			});
			return;
		}

		const { intuit_connection } = response.data;

		dispatch({
			type: ActionTypes.GET_INTUIT_CONNECTION_BASIC_SUCCESS,
			payload: {
				intuitConnection: intuit_connection,
				loading: false
			}
		});
	};
}

export function onSubmit(fields) {
	return async (dispatch, getState) => {
		const { intuitConnection } = getState().intuitConnectionDetailPage.intuitConnectionBasicPageReducer;

		dispatch({
			type: ActionTypes.SUBMIT_INTUIT_CONNECTION_REQUEST,
			payload: {
				loadingSubmit: true
			}
		});

		const body = {
			intuit_connection: {
				...fields
			}
		};

		let response;
		if (intuitConnection) {
			body.intuit_connection.updated_at = intuitConnection.updated_at;
			response = await API.intuit_connection.updateIntuitConnectionById(intuitConnection._id, body);
		}

		if (response.meta.code !== 200) {
			dispatch({
				type: ActionTypes.SUBMIT_INTUIT_CONNECTION_ERROR,
				payload: {
					meta: response.meta,
					loadingSubmit: false
				}
			});
			return;
		}

		const { intuit_connection: newIntuitConnection } = response.data;

		dispatch({
			type: ActionTypes.SUBMIT_INTUIT_CONNECTION_SUCCESS,
			payload: {
				meta: response.meta,
				intuitConnection: newIntuitConnection,
				loadingSubmit: false
			}
		});

		dispatch(push(`/intuit-connections/id?id=${newIntuitConnection._id}`));
	};
}

export function resetMeta() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_INTUIT_CONNECTION_BASIC_META
		});
	};
}

export function resetPage() {
	return async dispatch => {
		dispatch({
			type: ActionTypes.RESET_INTUIT_CONNECTION_BASIC_PAGE
		});
	};
}
