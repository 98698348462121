import React, { useEffect, useState } from 'react';
import { Layout, Avatar, Popover, Row, Col, Typography, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import LocalStorageHelper from '../../helpers/local_storage';

import premium_badge from '../../images/premium_badge.png';
import enterprise_badge from '../../images/enterprise_badge.png';

const { Header } = Layout;
const { Text } = Typography;

const NavigationHeader  = (props) => {
	const { history, subscription, loadingSubscription } = props;
	const { status } = subscription || {};
	const user = LocalStorageHelper.getUser();

	const avatar = (
		user.picture ?
			<Avatar src={user.picture}/> :
			<Avatar
				style={{ backgroundColor: '#344E41' }}
				size="large"
			>
				{user.email.charAt(0).toUpperCase()}
			</Avatar>
	);

	return (
		<Header className="nav-header">
			<div style={{ marginRight: 15 }}>
				{
					((status === 'trialing' || status === 'canceled') && user.type && user.type === 'standard') && (
						<Button
							onClick={() => history.push('/billing')}
							style={{ backgroundColor: '#FF8A00', color: 'white', border: '1px solid #FF8A00' }}
						>
							Upgrade
						</Button>
					)
				}
				{
					user.type === 'standard' ? (
						status === 'paid' && (
							<img
								src={premium_badge}
								alt="Premium Badge"
							/>
						)
					) : (
						<img
							src={enterprise_badge}
							alt="Enterprise Badge"
						/>
					)
				}
			</div>
			<Popover
				placement="bottomRight"
				content={
					<>
						<Row>
							<Col>
								{avatar}
							</Col>
							<Col>
								<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
									<Text strong>{user.name}</Text>
									<Text style={{ marginTop: -5 }}>{user.email}</Text>
								</div>
							</Col>
						</Row>
						<div
							className="divider"
							style={{ margin: '15px 0px' }}
						/>
						<Row
							justify="space-between"
							style={{ cursor: 'pointer' }}
							onClick={() => history.push('/logout')}
						>
							<Text>Sign Out</Text>
							<FontAwesomeIcon
								icon={faArrowRightFromBracket}
								fixedWidth
							/>
						</Row>
					</>
				}
			>
				<Space direction="horizontal">
					{avatar}
					<p
						className="hide-on-mobile"
						style={{ margin: 0 }}
					>
						{user.email}
					</p>
					<FontAwesomeIcon
						icon={faAngleDown}
						fixedWidth
						style={{ marginLeft: -5, marginBottom: 1, height: 10 }}
					/>
				</Space>
			</Popover>
		</Header>
	);
};

export default NavigationHeader;
