import React from 'react';

const BasicLayout = ({ children }) => {
	return (
		<React.Fragment>
			<div>
				{children}
			</div>
		</React.Fragment>
	);
};

export default BasicLayout;
